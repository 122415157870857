/* eslint-disable */
export const ToastUtils = {

    showToast(ctx, type, summary, detail, life) {
        ctx.$toast.add({
            severity: type,
            summary: summary,
            detail: detail,
            life: life,
        });
    },

    showSuccess(ctx, detail, summary = "Sukces", life = 3000) {
        ToastUtils.showToast(ctx,"success", summary, detail, life);
    },

    showError(ctx, detail = "Nastąpił nieoczekiwany błąd!", summary = "Błąd", life = 3000) {
        ToastUtils.showToast(ctx,"error", summary, detail, life);
    },

}
