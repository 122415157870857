<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-shield"></i> Lista Sprawności</h1>

        <div class="search-criteria" @keyup.enter="search">
            <MeritBadgeSearchCriteria ref="meritBadgeSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <div class="flex gap-3 flex-wrap">
                        <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                        <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj sprawność" icon="pi pi-plus" @click="createMeritBadge"/>
            </template>
        </Toolbar>

        <MeritBadgeTable ref="meritBadgeTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import MeritBadgeSearchCriteria from "./components/MeritBadgeSearchCriteria";
    import MeritBadgeTable from "./components/MeritBadgeTable";

    export default {
        name: "MeritBadgeListView",
        components: {
            MeritBadgeTable, MeritBadgeSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.meritBadgeTable.search();
            },

            createMeritBadge() {
                this.$router.push({name: "meritBadgeCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
