/* eslint-disable */
import {configure, defineRule} from "vee-validate";
import {confirmed, is, max, max_value, min, min_value, regex, required,} from "@vee-validate/rules";
import {localize} from "@vee-validate/i18n";
import {messagesPL} from "./messages/messages_pl";

defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("min_value", min_value);
defineRule("max_value", max_value);
defineRule("confirmed", confirmed);
defineRule("is", is);
defineRule("regex", regex);

/*
 * Sprawdza czy hasło spełnia warunki:
 * Minimum jedna duża litera
 * Minimum jedna mała litera
 * Minimum jedna cyfra
 * Minimum jeden znak specjalny
 * Minimum 8 znaków
 */
defineRule("validPassword", (value) => {
    if (!value || !value.length) {
        return false;
    }

    let regexExp = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\[\\]{};:+=|,<.>\\`~£§?/'\\-\\_\"])(?=.*[a-zA-Z]).{8,}$");

    if (!regexExp.test(value)) {
        return false;
    }

    return true;
});

defineRule("confirmed", (value, [other, label]) => {
    if (value !== other) {
        return `${label} ` + messagesPL.validations.notTheSame;
    }

    return true;
});

defineRule("email", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if email
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return false;
    }

    return true;
});

defineRule("url", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if url
    if (!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        .test(value)) {
        return false;
    }

    return true;
});

defineRule("requiredFile", (value) => {
    if (!value) {
        return "Plik jest wymagany";
    }
    return true;
});


configure({
    generateMessage: localize("pl", {
        messages: messagesPL.validations,
    }),
});
