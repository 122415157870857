<template>
    <div class="list-view">
        <h1>{{ $t("message.headers.meritBadge") }}</h1>
        <div class="search-criteria public-view" @keyup.enter="search">
            <MeritBadgePublicSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
            <Toolbar>
                <template #start>
                    <PrimeButton icon="pi pi-search" class="p-button-rounded mr-3" @click="search" :disabled="loading"/>
                    <PrimeButton icon="pi pi-times" class="p-button-rounded p-button-outlined" @click="clear"/>
                </template>
            </Toolbar>
        </div>
        <BasicCardList :instruments="instruments" :loading="loading"/>
    </div>
</template>

<script>

    import BasicCardList from "@/components/BasicCard/BasicCardList";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {searchPublicMeritBadgeUsingPOST as search} from "@/swagger/vue-api-client";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import MeritBadgePublicSearchCriteria from "./components/MeritBadgePublicSearchCriteria";

    export default {
        name: "MeritBadgePublicListView",
        components: {
            MeritBadgePublicSearchCriteria,
            BasicCardList,
            PrimeButton,
            Toolbar,
        },

        props: {
        },

        computed: {
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                instruments: [],
                loading: true,
            };
        },
        beforeMount() {
            SearchCriteria.loadCriteria(this);
            this.searchMeritBadge();
        },

        mounted() {
        },

        methods: {
            search() {
                this.searchMeritBadge();
                SearchCriteria.updateUrl(this);
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },
            searchMeritBadge() {
                search({searchCriteria: this.searchCriteria}).then((response) => {
                    this.instruments = response.data;
                    // eslint-disable-next-line no-return-assign
                    this.instruments.forEach((val) => val.instrumentType = "meritBadge");
                    this.loading = false;
                });
            },
            isCubMeritBadge() {
                return this.$route.params.groupName === "cub-scouts";
            },
            getClearSearchCriteria() {
                const criteria = {
                    phrase: "",
                    categoryId: null,
                    labelIds: [],
                    cubsRank: this.$route.params.groupName === "cub-scouts",
                    ageGroupName: this.$route.params.groupName,
                    page: {
                        limit: 300,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
                if (!this.isCubMeritBadge()) {
                    criteria.specialtyId = null;
                    criteria.levelIds = [];
                }
                return criteria;
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
