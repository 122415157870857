<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-tag"></i> Lista etykiet </h1>

        <div class="search-criteria" @keyup.enter="search">
            <LabelSearchCriteria ref="labelSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj nową etykietę" icon="pi pi-plus" @click="createLabel"/>
            </template>
        </Toolbar>

        <LabelTable ref="labelTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import LabelSearchCriteria from "./components/LabelSearchCriteria";
    import LabelTable from "./components/LabelTable";

    export default {
        name: "LabelListView",
        components: {
            LabelTable, LabelSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.labelTable.search();
            },

            createLabel() {
                this.$router.push({name: "labelCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
