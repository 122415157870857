<template>
    <div>
        <div class="remind-password-panel-header">
            <img src="@/assets/logo.png" alt="logo">
        </div>
        <div class="remind-password-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h2>Przypomnienie Hasła</h2>
                </div>

                <div class="p-col-12" v-if="sending">
                    Na wskazany adres e-mail została wysłana wiadomość z linkiem do zmiany hasła.
                </div>
                <div class="p-col-12" v-else @keyup.enter="remind">
                    <CustomInputText name="email" v-model="passwordRemindForm.email" label="Adres e-mail"/>
                    <PrimeButton label="Przypomnij hasło" @click="remind"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import {remindPasswordUsingPOST as remindPassword} from "@/swagger/vue-api-client";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "PasswordRemindView",
        components: {CustomInputText, PrimeButton},

        data() {
            return {
                sending: false,
                passwordRemindForm: {
                    email: "",
                },
            };
        },

        methods: {
            remind() {
                this.sending = true;
                remindPassword({passwordRemindRequest: this.passwordRemindForm});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/remind-password.less";
</style>
