<template>
    <div class="scout-merit-badge-card-view">
        <div class="flex">
            <div class="flex-1 pt-4">
                <h1 class="text-center">
                    {{ meritBadgeName }}
                    <span v-for="star in level" :key="star" class="star">*</span>
                </h1>
                <div class="field-to-complete name">
                    <span class="dots"></span>
                    <span>imię i nazwisko</span>
                </div>
            </div>
            <div class="scout-merit-badge-card-img ml-4">
                <img :src="meritBadgeCardData.coverImageUrl" alt="">
            </div>
        </div>
        <div class="flex gap-3">
            <div class="field-to-complete start-date flex-1">
                <span class="dots"></span>
                <span>data rozpoczęcia</span>
            </div>
            <div class="field-to-complete end-date flex-1">
                <span class="dots"></span>
                <span>data zakończenia</span>
            </div>
        </div>
        <div class="flex align-items-start my-4 gap-5">
            <div class="level-idea"><span>Idea<br>poziomu</span></div>
            <div class="level-desc"> {{ meritBadgeCardData.levelConcept }}
            </div>
        </div>
        <table>
            <tr>
                <th class="merit-badge-tasks">
                    Zadania
                </th>
                <th colspan="2" class="merit-badge-confirmation">
                    Potwierdzenie
                </th>
            </tr>
            <tr v-for="(task, index) in meritBadgeCardData.tasks" :key="task">
                <td>
                    <div class="cell-inner">
                        <span class="order-item">{{ index + 1 }}.</span>
                        {{ task.text }}
                    </div>
                </td>
                <template v-if="task.rank">
                    <td>
                    </td>
                    <td class="extra-label-outer">
                        <div class="extra-label">{{ task.rank.femaleName }} {{ task.rank.maleName }}</div>
                    </td>
                </template>
                <template v-else>
                    <td colspan="2"></td>
                </template>
            </tr>
        </table>
    </div>
</template>

<script>
    import {
        getMeritBadgeScoutBadgeCardDataUsingGET as getMeritBadgeScoutBadgeCardData,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ScoutMeritBadgeCardView",
        data() {
            return {
                meritBadgeCardData: "",
            };
        },
        beforeMount() {
            getMeritBadgeScoutBadgeCardData({id: this.$route.params.id, level: this.$route.params.level})
                .then((response) => {
                    this.meritBadgeCardData = response.data;
                }).catch(() => {
                    ToastUtils.showError(this);
                });
        },
        mounted() {
        },
        computed: {
            meritBadgeName() {
                if (!this.meritBadgeCardData.femaleName) {
                    return this.meritBadgeCardData.maleName;
                }
                return (this.meritBadgeCardData.maleName !== this.meritBadgeCardData.femaleName)
                    ? (this.meritBadgeCardData.maleName + " / " + this.meritBadgeCardData.femaleName)
                    : this.meritBadgeCardData.maleName;
            },
            level() {
                return parseInt(this.$route.params.level, 10);
            },
        },
    };
</script>

<style lang="less">
    @import "@/assets/theme/variable";

    @media print {
        .scout-merit-badge-card-view {
            margin: 0 10mm;
            overflow: hidden;
            font-family: "Museo Sans";
            .scout-merit-badge-card-img {
                width: 130px;
            }
            h1 {
                color: @color-blue;
                font-weight: 900;
                font-family: "Museo Sans";
            }
            .level-idea {
                background-color: @color-blue;
                print-color-adjust: exact;
                width: 64px;
                height: 115px;
                span {
                    color: @color-white;
                    transform: rotate(-90deg);
                    display: block;
                    text-align: center;
                    height: 77px;
                    padding: 10px 12px;
                    font-weight: 900;
                    font-family: "Museo Sans";
                    font-size: 18px;
                    margin-top: 22px;
                    width: 104px;
                    margin-left: -15px;
                }
            }
            .level-desc {
                font-size: 18px;
            }
            table {
                width: 100%;
                font-size: 16px;
                border-collapse: collapse;
                .merit-badge-confirmation {
                    width: 200px;
                }
                .extra-label-outer {
                    print-color-adjust: exact;
                    background-color: @color-purple-scout;
                    width: 60px;
                }
                .extra-label {
                    writing-mode: vertical-rl;
                    color: @color-white;
                    text-align: center;
                    height: 115px;
                    font-weight: 700;
                    height: min-content;
                }
                th {
                    background-color: @color-blue;
                    print-color-adjust: exact;
                    color: @color-white;
                    text-align: center;
                    font-size: 20px;
                    &.merit-badge-confirmation {
                        font-size: 18px;
                    }
                }
                th, td {
                    padding: 10px;
                    border: 2px solid @color-black;
                    break-inside: avoid;
                }
                td {
                    font-weight: 400;
                }
                .order-item {
                    font-size: 18px;
                    color: @color-blue;
                    margin-right: 4px;
                    display: block;
                    font-weight: bold;
                }
                .cell-inner {
                    display: flex;
                    line-height: 20px;
                }
            }
            .date {
                margin-left: auto;
            }
            .field-to-complete  {
                span {
                    font-size: 14px;
                    width: 100%;
                    display: block;
                    text-align: center;
                    &.dots {
                        height: 36px;
                        border-bottom-style: dotted;
                        border-color: @color-blue;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

</style>
