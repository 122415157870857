<template>
    <div>
        <Toast/>
        <DataTable ref="sustainableDevelopmentGoalTable" :value="sustainableDevelopmentGoals"
                   :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="pointNumber" header="numer" :sortable="true" headerStyle="width: 7rem;"></Column>
            <Column field="name" header="nazwa" :sortable="true"></Column>
            <Column headerStyle="width: 160px">
                <template #body="slotProps">
                    <div class="flex gap-1">
                        <router-link :to="{name: 'sustainableDevelopmentGoalEdit', params: {'id': slotProps.data.id}}">
                            <PrimeButton type="button" icon="pi pi-pencil" class="p-button-success"></PrimeButton>
                        </router-link>
                        <PrimeButton type="button" icon="pi pi-times"
                                     class="p-button-danger"
                                     @click="deleteSustainableDevelopmentGoal(slotProps.data.id)">
                        </PrimeButton>
                    </div>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        deleteSustainableDevelopmentGoalUsingDELETE as deleteSustainableDevelopmentGoal,
        searchSustainableDevelopmentGoalCountUsingPOST as searchSustainableDevelopmentGoalCount,
        searchSustainableDevelopmentGoalUsingPOST as searchSustainableDevelopmentGoal,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Toast from "primevue/toast";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {SearchCriteria} from "../../../utils/SearchCriteria";

    export default {
        name: "SustainableDevelopmentGoalTable",
        components: {
            Toast, PrimeButton, Column, DataTable,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                sustainableDevelopmentGoals: [],
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            async onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;
                this.searchParams = await this.searchCriteria;

                searchSustainableDevelopmentGoal({searchCriteria: this.searchParams}).then((response) => {
                    this.sustainableDevelopmentGoals = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchSustainableDevelopmentGoalCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                    sortOrder: this.searchCriteria.page.sortOrder,
                };
            },

            deleteSustainableDevelopmentGoal(sustainableDevelopmentGoalId) {
                this.$confirm.require({
                    message: "Ta operacja jest nieodwracalna",
                    header: "Czy na pewno chcesz usunąć CZR?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "tak",
                    rejectLabel: "nie",
                    accept: () => {
                        deleteSustainableDevelopmentGoal({id: sustainableDevelopmentGoalId}).then(() => {
                            this.search();
                            this.search();
                            ToastUtils.showSuccess(
                                this,
                                "Udało się usunąć CZR",
                                "Cel zrównoważonego rozwoju został usunięty",
                            );
                        }).catch(() => {
                            ToastUtils.showError(this);
                        });
                    },
                });
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
