import * as msal from "@azure/msal-browser";
import store from "./store";

const config = {
    auth: {
        tenantId: process.env.VUE_APP_TENANT_ID,
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
        authority: process.env.VUE_APP_AUTHORITY,
        clientId: process.env.VUE_APP_CLIENT_ID,
        postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage",
    },
    // Only uncomment when you *really* need to debug what is going on in MSAL
    system: {
        logger: new msal.Logger(
            (logLevel, msg) => { window.console.log(msg); },
            {
                level: msal.LogLevel.Verbose,
            },
        ),
    },
};

// MSAL object used for signing in users with MS identity platform
let msalApp;

export default {
    waitingOnAccessToken: false,
    accessToken: null,
    loggedUserFullName: undefined,
    loggedUserFullNameRoles: [],
    accessTokenCallbacks: [], // functions to call back when accessToken is set

    /**
     * Configure with clientId or empty string/null to set in "demo" mode
     */
    async configure() {
        // Can only call configure once
        if (msalApp) {
            return;
        }

        // Create our shared/static MSAL app object
        msalApp = new msal.PublicClientApplication(config);

        msalApp.handleRedirectPromise()
            .then((tokenResponse) => {
                window.console.debug("token Response: ", tokenResponse);
                store.dispatch("loginSuccess");
                // Handle redirect response
            })
            .catch((error) => {
                window.console.debug("token Response: ", error);
                store.dispatch("loginFailure");
            });
    },

    /**
     * Return the configured client id
     */
    clientId() {
        if (!msalApp) {
            return null;
        }
        return msalApp.clientId;
    },

    /**
     * Login a user with a popup
     */
    async login(scopes = ["user.read", "openid", "profile"]) {
        if (!msalApp) {
            return;
        }

        // const LOGIN_SCOPES = ['user.read', 'openid', 'profile', 'email']
        await msalApp.loginRedirect({
            scopes,
            prompt: "select_account",
        });
    },

    /**
     * Logout any stored user
     */
    logout() {
        if (!msalApp) {
            return;
        }
        // msalApp.logoutPopup()
        msalApp.logoutRedirect();
    },

    // Call to get user, probably cached and stored locally by MSAL
    // click
    user() {
        if (!msalApp) {
            return null;
        }

        const currentAccounts = msalApp.getAllAccounts();

        if (!currentAccounts || currentAccounts.length === 0) {
            // No user signed in
            return null;
        } if (currentAccounts.length > 1) {
            return currentAccounts[0];
        }
        return currentAccounts[0];
    },

    getUserRoles() {
        if (!this?.user()?.idTokenClaims.roles) {
            return [];
        }
        return this?.user()?.idTokenClaims.roles.map((role) => role?.toLowerCase());
    },

    getUserName() {
        return this?.user()?.name;
    },

    hasRole(role) {
        return this.isLoggedIn() && this?.getUserRoles()?.includes(role);
    },

    isLoggedIn() {
        return this.user() != null;
    },

    defaultScope() {
        return [config.auth.clientId + "/.default"];
    },

    /**
     * Call through to acquireTokenSilent or acquireTokenPopup
     */
    async acquireToken(/* scopes = ['user.read'] */) {
        this.waitingOnAccessToken = true;
        // Override any scope
        if (!msalApp) {
            return null;
        }
        const accessTokenRequest = this.getAccessTokenRequest();

        let tokenResp;
        try {
            // 1. Try to acquire token silently
            tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest);
            window.console.debug("### MSAL acquireTokenSilent was successful");
        } catch (err) {
            // 2. Silent process might have failed so try via popup
            tokenResp = await msalApp.acquireTokenRedirect(accessTokenRequest);
            window.console.debug("### MSAL acquireTokenRedirect was successful");
        } finally {
            this.waitingOnAccessToken = false;
        }

        // Just in case check, probably never triggers
        if (!tokenResp.accessToken) {
            throw new Error("### accessToken not found in response, that's bad");
        }

        this.accessToken = tokenResp.accessToken;

        // Execute waiting call backs
        if (this.accessTokenCallbacks.length > 0) {
            this.accessTokenCallbacks.forEach((callback) => {
                callback();
            });
            this.accessTokenCallbacks = []; // reset
        }

        return tokenResp.accessToken;
    },

    /**
     * Clear any stored/cached user
     */
    clearLocal() {
        if (msalApp) {
            // eslint-disable-next-line no-restricted-syntax
            for (const entry of Object.entries(localStorage)) {
                const key = entry[0];
                if (key.includes("login.windows")) {
                    localStorage.removeItem(key);
                }
            }
        }
    },

    /**
     * Initializes app authentication state. Attempt to acquire an access token if already logged in.
     */
    async init() {
        await this.configure();
        window.console.debug(this.user());

        if (this.user()) {
            // Check if the current ID token is still valid based on expiration date
            if (this.checkIdToken()) {
                // set accessToken
                await this.acquireToken().then((accessToken) => {
                    this.accessToken = accessToken;
                    store.dispatch("loginSuccess");
                });
            }
        } else {
            window.console.debug("not signed in");
        }
    },

    /**
     * Check if the cached ID token is still valid. If not, then clear the old token.
     * User will be asked to reauthenticate.
     */
    checkIdToken() {
        window.console.debug("check Id token expiration");
        const user = this.user();
        if (user) {
            const expirationDateSecs = user.idTokenClaims.exp;
            const expDate = new Date(expirationDateSecs * 1000);
            window.console.debug("expDateSecs: " + expDate);

            if ((new Date()).getTime() >= expirationDateSecs * 1000) {
                window.console.debug("IdToken expired. Clearing internal cache");
                this.clearLocal();
                return false;
            }
            window.console.debug("ID token is still valid");
            return true;
        }
        return false;
    },

    /**
     * Sign in and store the accessToken
     */
    // eslint-disable-next-line consistent-return
    appSignIn() {
        if (!msalApp) {
            return null;
        }

        this.login().then(() => {
            if (this.user()) {
                // Automaticaly assign accessToken
                this.acquireToken().then((accessToken) => {
                    this.accessToken = accessToken;
                    store.dispatch("loginSuccess");
                });
            } else {
                store.dispatch("loginFailure");
            }
        });
    },

    async getTokenIfExistsOrFetchFromCache() {
        if (this.accessToken) {
            return this.accessToken;
        }
        try {
            const tokenResp = await msalApp.acquireTokenSilent(this.getAccessTokenRequest());
            return tokenResp.accessToken;
        } catch {
            return null;
        }
    },

    getAccessTokenRequest() {
        const scopes = this.defaultScope();

        // Set scopes for token request
        const accessTokenRequest = {
            scopes,
            account: this.user(),
        };
        return accessTokenRequest;
    },

};
