<template>
    <div class="p-fluid custom-select-one">
        <label v-if="showLabel" class="select-one-label" :for="name">{{ label }}</label>
        <Dropdown :id="name" :options="internalItems" :optionLabel="itemLabel"
                  :optionValue="itemValue" :disabled="disabled"
                  @change="onChange" :rules="rules" :loading="loading"
                  v-model="internalValue"
                  :class="computedClasses"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";

    export default {
        name: "CustomSelectOne",
        props: {
            showLabel: {
                type: Boolean,
                default: true,
            },
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
            loading: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
                selectedValue: null,
            };
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
            }
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.originalEvent);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        components: {Dropdown},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less" scoped>
@import "../../../assets/theme/variable";
    .custom-select-one {
        margin-bottom: 10px;
        text-align: left;
            label {
                margin-left: 0.5em;
                font-weight: 300;
                color: @color-black-trans-70;
                font-size: @label-font-size;
            }
            .p-dropdown {
                margin-top: 0.5em;
                width: 100%;
                .p-dropdown-label {
                    margin-top: 0;
                }
            }
    }
</style>
