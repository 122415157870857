<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath"><i class="pi pi-star"></i>
            {{ this.$route.params.id ? 'Edytuj specjalność' : 'Dodaj specjalność' }}
        </h1>
        <VeeForm v-if="loaded" @submit="saveSpecialty" v-slot="{ isSubmitting }">
            <div class="p-grid specialty-form">
                <div class="col-6">
                    <CustomInputText name="name" label="nazwa" v-model="specialty.name" rules="required"/>
                </div>
                <div class="col-6">
                    <Toolbar class="actions">
                        <template #end>
                            <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateSpecialtyUsingPOST as createOrUpdateSpecialty,
        getSpecialtyUsingGET as getSpecialty,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import GoBack from "@/components/GoBack";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "SpecialtyFormView",

        components: {
            PrimeButton, Toolbar, CustomInputText, Toast, VeeForm, GoBack,
        },

        props: {
            value: {},
        },

        data() {
            return {
                specialty: {
                    id: null,
                    name: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getSpecialty({id: this.$route.params.id}).then((response) => {
                    this.specialty = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveSpecialty() {
                createOrUpdateSpecialty({specialtyDto: this.specialty}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane",
                        life: 3000,
                    });
                    this.$router.push({name: "specialtyList"});
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .specialty-form {
        width: 80%;
        max-width: 800px;
    }
</style>
