<template>
    <div v-if="meritBadge">
        <GoBack />
        <h1 class="md:hidden mt-3">
            {{ 'Sprawność ' + (meritBadge.cubsRank === true ?  'Zuchowa' : 'Harcerska') }} {{ meritBadgeName }}
        </h1>
        <div>
            <div class="grid flex-column md:block gap-5 md:gap-0 justify-content-between
                        align-items-center md:align-items-start pt-5">
                <div class="col-12 md:col-4 grid flex-column align-items-center gap-4"
                     style="float: right">
                    <PrimeButton :label="$t('message.buttons.downloadSampleCard')" @click="printCard"
                                 class="p-button-rounded download-card-button" :loading="downloadingFile"/>
                    <img :src="meritBadge.coverUrl" alt="" class="col-10 sm:col-6 md:col-12 merit-badge-img">
                </div>
                <div class="col-12">
                    <h1 class="md:block hidden">
                        {{ 'Sprawność ' + (meritBadge.cubsRank === true ?  'Zuchowa' : 'Harcerska') }}
                        {{ meritBadgeName }}
                    </h1>
                    <div class="rank-description" v-if="meritBadge.description">
                        <div class="mb-6" v-html="meritBadge.description"></div>
                    </div>
                    <div class="rank-description g" v-if="getDescriptionForLevel">
                        <div class="mb-6" v-html="getDescriptionForLevel"/>
                    </div>
                    <div class="flex md:gap-3 gap-2 flex-wrap" v-if="meritBadge.specialtyName">
                        <div class="font-bold">{{ $t("message.headers.specialty") }}:</div>
                        <div>{{ meritBadge.specialtyName }}</div>
                    </div>
                    <div class="mt-6">
                        <div class="font-bold mb-3">{{ $t("message.headers.meritBadgeLevel") }}</div>
                        <MeritBadgeLevels :merit-badge="meritBadge" :active-index="activeIndex"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import {
        getPublicMeritBadgeUsingGET as getMeritBadge,
        printMeritBadgeCubScoutBadgeUsingGETURL as getCubScoutUrl,
        printMeritBadgeScoutBadgeUsingGETURL as getScoutUrl,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import GoBack from "@/components/GoBack";
    import MeritBadgeLevels from "./components/MeritBadgeLevels";

    export default {
        name: "MeritBadgeSingleView",
        components: {
            MeritBadgeLevels, PrimeButton, GoBack,
        },

        data() {
            return {
                meritBadge: null,
                downloadingFile: false,
            };
        },

        props: {
        },

        computed: {
            activeIndex() {
                if (this.$route.query.level) {
                    return Number.parseInt(this.$route.query.level, 10);
                }
                return 0;
            },
            meritBadgeName() {
                return this.meritBadge.name === this.meritBadge.femaleName
                    ? this.meritBadge.name : (this.meritBadge.name + " / " + this.meritBadge.femaleName);
            },
            getDescriptionForLevel() {
                if (!this.meritBadge?.levelDescriptions) {
                    return null;
                }
                const levelObj = this.meritBadge?.levelDescriptions
                    .find((el) => el.level === (this.activeIndex + 1));
                if (levelObj) {
                    return levelObj.description;
                }
                return null;
            },
        },

        beforeMount() {
            getMeritBadge({id: this.$route.params.instrumentId}).then((response) => {
                this.meritBadge = response.data;
                this.addHeadersToLevels(this.meritBadge.cubsRank);
            });
        },

        mounted() {
        },

        methods: {
            async printCard() {
                this.downloadingFile = true;
                let url;
                if (this.meritBadge.cubsRank) {
                    url = getCubScoutUrl({meritBadgeId: this.meritBadge.id, level: this.activeIndex + 1});
                } else {
                    url = getScoutUrl({meritBadgeId: this.meritBadge.id, level: this.activeIndex + 1});
                }
                await FileUtils.downloadFileAsBlob(this, url, this.meritBadge.name + ".pdf");
                this.downloadingFile = false;
            },
            addHeadersToLevels(isCubsRank) {
                if (!isCubsRank) {
                    this.meritBadge.levels.forEach((val, index) => {
                        index += 1;
                        val.label = "*".repeat(index);
                    });
                } else {
                    this.meritBadge.levels.forEach((val, index) => {
                        index += 1;
                        // eslint-disable-next-line
                        val.label = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 260 245'><path d='m56,237 74-228 74,228L10,96h240'/></svg>".repeat(index);
                    });
                }
            },
        },

    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";
    .rank-description {
        color: @color-purple-scout;
        font-weight: 700;
    }
</style>
<style lang="less">
    @import "../../assets/theme/variable";
    .levels {
        &.p-tabview:not(.cubsTabView) .p-tabview-nav li .p-tabview-nav-link,
        &.p-tabview:not(.cubsTabView) .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link,
        &.p-tabview:not(.cubsTabView) .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            background: @color-green-dark3;
            color: @color-white;
        }
        &.p-tabview .p-tabview-nav li .p-tabview-nav-link {
            border-radius: 50%;
            width: 60px;
            height: 60px;
            padding: 0;
            .p-tabview-title {
                width: 100%;
                text-align: center;
                font-weight: 700;
                font-size: 35px;
                height: 27px;
            }
        }
        &.p-tabview .p-tabview-nav .p-tabview-header.p-highlight {
            margin-left: -5px;
            .p-tabview-nav-link {
                border: 5px solid @secondary-color;
                width: 70px;
                height: 70px;
            }
        }
        &.p-tabview .p-tabview-nav .p-tabview-ink-bar {
            display: none;
        }
        &.p-tabview .p-tabview-nav {
            border: none;
            align-items: center;
            margin-left: 5px;
            .p-tabview-header {
                &:not(:first-of-type) {
                    margin-left: 20px;
                }
            }
        }
        .merit-badge-tab-view-custom-header {
            width: 100%;
            font-size: 34px;
            &.header-no-cubs {
                & > div {
                    height: 24px;
                }
            }
        }
        &:not(.cubsTabView) {
            &.p-tabview .p-tabview-nav .p-tabview-header[data-index='2'],
            &.p-tabview .p-tabview-nav .p-tabview-header[data-index='3'] {
                .header-no-cubs {
                    font-size: 28px;
                    & > div {
                        height: 21px;
                    }
                }
            }
        }
        //Gwiazdki dla zuchów
        &.cubsTabView {
            &.p-tabview .p-tabview-nav li .p-tabview-nav-link,
            &.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover
            .p-tabview-nav-link,
            &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
                background: @color-yellow;
            }
            &.p-tabview .p-tabview-nav .p-tabview-header.p-highlight {
                .p-tabview-nav-link {
                    border-color: @color-yellow-dark;
                }
            }
            &.p-tabview .p-tabview-nav .p-tabview-header[data-index='2'],
            &.p-tabview .p-tabview-nav .p-tabview-header[data-index='3'] {
                svg {
                    width: 17px;
                }
            }
        }
    }
</style>
