import Vuex from "vuex";
import app from "./App";

export default Vuex.createStore({
    state() {
        return {
            loggedIn: false,
        };
    },
    mutations: {
        loginSuccess(state) {
            window.console.log("mutation loginSuccess");
            state.loggedIn = true;
        },
        loginFailure(state) {
            state.loggedIn = false;
        },
        logout(state) {
            window.console.log("mutation logoutSuccess");
            state.loggedIn = false;
        },
    },
    actions: {
        loginSuccess({commit}) {
            commit("loginSuccess");

            // Redirect user if a page was loaded directly in the browser
            const redirectPath = sessionStorage.getItem("redirectPath");
            if (redirectPath) {
                sessionStorage.removeItem("redirectPath");
                app.$router.push(redirectPath);
            }
        },
        logout({commit}) {
            commit("logout");
            app.$router.push({name: "homeView"});
        },
        loginFailure({commit}) {
            commit("loginFailure");
        },
    },
});
