<template>
    <div v-if="loaded">
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="name" item-value="id" :rules="rules" :label="label" :show-label="showLabel"/>
    </div>
</template>

<script>
    import CustomSelectOne from "./inner/CustomSelectOne";
    import {
        getAllActiveRanksUsingGET as getAllActiveRanks,
    } from "../../swagger/vue-api-client";

    export default {
        name: "CustomSelectOneRank",
        props: {
            showLabel: {
                type: Boolean,
                default: true,
            },
            name: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                label: "stopień",
                loaded: false,
                items: [],
            };
        },

        components: {CustomSelectOne},

        beforeMount() {
            getAllActiveRanks({}).then((response) => {
                this.items = response.data;
                this.loaded = true;
            });
        },
    };
</script>

<style scoped>

</style>
