<template>
    <div class="footer pt-2">
        <div class="flex flex-column md:flex-row footer-top">
            <div class="flex-1" v-html="footerContent.value"></div>
            <div class="flex flex-column md:flex-row flex-1 justify-content-end align-items-center img">
                <img src="@/assets/wagggs-blue.png" alt="logo World Association of Girl Guides and Girl Scouts">
                <img src="@/assets/wosm-blue.png" alt="logo Scouts">
            </div>
        </div>
        <div class="link-outer mt-2 footer-bottom">
            <div class="links-inner w-full">
                <div class="links pt-3 flex sm:flex-row flex-column gap-2 sm:gap-6">
                    <div class="flex gap-2 flex-wrap flex-column">
                        <a target="_blank" href="//zhp.pl/">Copyright © 1997-{{ getCurrentYear }}
                            Związek Harcerstwa Polskiego</a>
                        <a target="_blank" href="//zhp.pl/informacje-i-uwagi-prawne">Informacje i uwagi prawne </a>
                        <a target="_blank" href="//zhp.pl/rodo">Polityka prywatności</a>
                    </div>
                    <div class="flex gap-2 flex-wrap flex-column">
                        <a target="_blank" href="//bip.zhp.pl/">Biuletyn Informacji Publicznej</a>
                        <a target="_blank" href="//zhp.pl/deklaracja-dostepnosci">Deklaracja dostępności</a>
                    </div>
                </div>
                <a class="created_by" target="_blank" href="http://www.vavatech.pl/">Wykonanie: Vavatech</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getConfigByKeyUsingGET as getConfigByKey,
    } from "@/swagger/vue-api-client";

    export default {
        name: "AppFooter",

        components: {},
        beforeMount() {
            this.getFooterContent();
        },

        computed: {
            getCurrentYear() {
                return new Date().getFullYear();
            },
        },

        data() {
            return {
                footerContent: "",
            };
        },
        methods: {
            getFooterContent() {
                getConfigByKey({key: "footerContent"}).then((response) => {
                    this.footerContent = response.data;
                    // eslint-disable-next-line no-return-assign
                });
            },
        },
    };
</script>

<style lang="less">
@import "../assets/theme/variable";
    footer {
        margin: 40px 0 0;
        border-top: 2px solid gray;
        .footer {
            font-size: initial;
            img {
                max-width: 240px;
            }
            .link-outer {
                position: relative;
            }
            .links {
                line-height: 1.6;
                border-top: 1px solid @color-gray;
                a {
                    color: @color-blue;
                }
            }
            .created_by {
                position: absolute;
                right: 0;
                bottom: 0;
            }
            p {
                margin: 10px 0;
            }
            .footer-top {
                a {
                    color: @color-blue;
                    text-decoration: underline;
                }
            }
            @media only screen and (max-width: @media-breakpoint-down-lg) {
                .created_by {
                    position: static;
                    display: block;
                    margin-top: 16px;
                    padding-top: 16px;
                    border-top: 1px solid @color-gray;
                }
            }
            @media only screen and (max-width: @media-breakpoint-down-sm) {
                .link-outer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .created_by {
                    position: static;
                    margin-top: 20px;
                    display: block;
                }
            }
        }
        .login-page {
            footer {
                .footer {
                    border-top: 1px solid @color-gray;
                    .footer-top,
                    .footer-bottom {
                        .container-content;
                        .links-inner {
                            position: relative;
                            .created_by {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
