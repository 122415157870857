<template>
    <div class="p-fluid custom-select-one-file">
        <label class="select-one-label" :for="name">{{ label }}</label>
        <Dropdown :id="name" :options="items"
                  :disabled="disabled" :filter="true"
                  :filterFields="['name']" filterMatchMode="contains"
                  @change="onChange" :rules="rules" :loading="loading" :showClear="true"
                  v-model="internalValue" :class="computedClasses"
                  panelClass="custom-select-one-file">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="select-file-value flex align-items-center gap-2">
                    <img :src="slotProps.value.fileUrl"
                         :alt="slotProps.value.name"/>
                    <span>{{slotProps.value.name}}</span>
                </div>
            </template>
            <template #option="slotProps">
                <div class="select-file-option flex align-items-center gap-3">
                    <img :src="slotProps.option.fileUrl"
                         :alt="slotProps.option.name"/>
                    <span >
                        {{slotProps.option.name}}
                    </span>
                </div>
            </template>
        </Dropdown>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import {searchExternalFileUsingPOST as searchFiles} from "@/swagger/vue-api-client";
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CustomSelectOneFile",
        props: {
            name: {
                type: String,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                items: [],
                loading: true,
                selectedValue: null,
            };
        },

        mounted() {
            searchFiles({searchCriteria: {page: {limit: 1000}}})
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch(() => ToastUtils.showError(this, "Nie udało się pobrać grafik"));
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            onChange(event) {
                if (event && event.value) {
                    this.handleChange(event.value);
                    this.$emit("change", event.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        components: {Dropdown},

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";
    .custom-select-one-file {
        margin-bottom: 10px;
        text-align: left;
        font-size: @label-font-size;
        label {
            margin-left: 0.5em;
            font-weight: 300;
            color: @color-black-trans-70;
        }
        .p-dropdown {
            margin-top: 0.5em;
            width: 100%;
            .p-dropdown-label {
                margin-top: 0;
            }
        }
        .select-file-value {
            max-width: 60px;
            min-width: 20px;
            img {
                max-width: 30px;
            }
        }
        .select-file-option {
            img {
                max-width: 60px;
            }
        }
    }

</style>
