<template>
    <div v-if="hasErrors" class="row mr-3">
        <span class="p-invalid">Formularz zawiera błędy</span>
    </div>
</template>

<script>
    export default {
        name: "FormErrorMessage",
        props: {
            hasErrors: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped lang="less">
</style>
