/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * createAdvancementLevel
 * request: createAdvancementLevelUsingPOST
 * url: createAdvancementLevelUsingPOSTURL
 * method: createAdvancementLevelUsingPOST_TYPE
 * raw_url: createAdvancementLevelUsingPOST_RAW_URL
 * @param advancementLevelRequest - advancementLevelRequest
 */
export const createAdvancementLevelUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['advancementLevelRequest'] !== undefined) {
    body = parameters['advancementLevelRequest']
  }
  if (parameters['advancementLevelRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: advancementLevelRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createAdvancementLevelUsingPOST_RAW_URL = function() {
  return '/advancement-level'
}
export const createAdvancementLevelUsingPOST_TYPE = function() {
  return 'post'
}
export const createAdvancementLevelUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editAdvancementLevel
 * request: editAdvancementLevelUsingPUT
 * url: editAdvancementLevelUsingPUTURL
 * method: editAdvancementLevelUsingPUT_TYPE
 * raw_url: editAdvancementLevelUsingPUT_RAW_URL
 * @param advancementLevelRequest - advancementLevelRequest
 */
export const editAdvancementLevelUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['advancementLevelRequest'] !== undefined) {
    body = parameters['advancementLevelRequest']
  }
  if (parameters['advancementLevelRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: advancementLevelRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editAdvancementLevelUsingPUT_RAW_URL = function() {
  return '/advancement-level'
}
export const editAdvancementLevelUsingPUT_TYPE = function() {
  return 'put'
}
export const editAdvancementLevelUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchAdvancementLevel
 * request: searchAdvancementLevelUsingPOST
 * url: searchAdvancementLevelUsingPOSTURL
 * method: searchAdvancementLevelUsingPOST_TYPE
 * raw_url: searchAdvancementLevelUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchAdvancementLevelUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchAdvancementLevelUsingPOST_RAW_URL = function() {
  return '/advancement-level/search'
}
export const searchAdvancementLevelUsingPOST_TYPE = function() {
  return 'post'
}
export const searchAdvancementLevelUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchAdvancementLevelCount
 * request: searchAdvancementLevelCountUsingPOST
 * url: searchAdvancementLevelCountUsingPOSTURL
 * method: searchAdvancementLevelCountUsingPOST_TYPE
 * raw_url: searchAdvancementLevelCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchAdvancementLevelCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchAdvancementLevelCountUsingPOST_RAW_URL = function() {
  return '/advancement-level/search/count'
}
export const searchAdvancementLevelCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchAdvancementLevelCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAdvancementLevel
 * request: getAdvancementLevelUsingGET
 * url: getAdvancementLevelUsingGETURL
 * method: getAdvancementLevelUsingGET_TYPE
 * raw_url: getAdvancementLevelUsingGET_RAW_URL
 * @param id - id
 */
export const getAdvancementLevelUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAdvancementLevelUsingGET_RAW_URL = function() {
  return '/advancement-level/{id}'
}
export const getAdvancementLevelUsingGET_TYPE = function() {
  return 'get'
}
export const getAdvancementLevelUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteAdvancementLevel
 * request: deleteAdvancementLevelUsingDELETE
 * url: deleteAdvancementLevelUsingDELETEURL
 * method: deleteAdvancementLevelUsingDELETE_TYPE
 * raw_url: deleteAdvancementLevelUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteAdvancementLevelUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/advancement-level/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteAdvancementLevelUsingDELETE_RAW_URL = function() {
  return '/advancement-level/{id}'
}
export const deleteAdvancementLevelUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteAdvancementLevelUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/advancement-level/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllAgeGroups
 * request: getAllAgeGroupsUsingGET
 * url: getAllAgeGroupsUsingGETURL
 * method: getAllAgeGroupsUsingGET_TYPE
 * raw_url: getAllAgeGroupsUsingGET_RAW_URL
 */
export const getAllAgeGroupsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/age-group/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllAgeGroupsUsingGET_RAW_URL = function() {
  return '/age-group/all'
}
export const getAllAgeGroupsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllAgeGroupsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/age-group/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchBadges
 * request: searchBadgesUsingPOST
 * url: searchBadgesUsingPOSTURL
 * method: searchBadgesUsingPOST_TYPE
 * raw_url: searchBadgesUsingPOST_RAW_URL
 * @param badgesSearchRequest - badgesSearchRequest
 */
export const searchBadgesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/badge/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['badgesSearchRequest'] !== undefined) {
    body = parameters['badgesSearchRequest']
  }
  if (parameters['badgesSearchRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: badgesSearchRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchBadgesUsingPOST_RAW_URL = function() {
  return '/badge/search'
}
export const searchBadgesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchBadgesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/badge/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * printMeritBadgeCubScoutBadge
 * request: printMeritBadgeCubScoutBadgeUsingGET
 * url: printMeritBadgeCubScoutBadgeUsingGETURL
 * method: printMeritBadgeCubScoutBadgeUsingGET_TYPE
 * raw_url: printMeritBadgeCubScoutBadgeUsingGET_RAW_URL
 * @param level - level
 * @param meritBadgeId - meritBadgeId
 */
export const printMeritBadgeCubScoutBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/card/merit-badge/cub-scouts/{meritBadgeId}/level/{level}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters['level'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: level'))
  }
  path = path.replace('{meritBadgeId}', `${parameters['meritBadgeId']}`)
  if (parameters['meritBadgeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: meritBadgeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const printMeritBadgeCubScoutBadgeUsingGET_RAW_URL = function() {
  return '/card/merit-badge/cub-scouts/{meritBadgeId}/level/{level}'
}
export const printMeritBadgeCubScoutBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const printMeritBadgeCubScoutBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/card/merit-badge/cub-scouts/{meritBadgeId}/level/{level}'
  path = path.replace('{level}', `${parameters['level']}`)
  path = path.replace('{meritBadgeId}', `${parameters['meritBadgeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * printMeritBadgeScoutBadge
 * request: printMeritBadgeScoutBadgeUsingGET
 * url: printMeritBadgeScoutBadgeUsingGETURL
 * method: printMeritBadgeScoutBadgeUsingGET_TYPE
 * raw_url: printMeritBadgeScoutBadgeUsingGET_RAW_URL
 * @param level - level
 * @param meritBadgeId - meritBadgeId
 */
export const printMeritBadgeScoutBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/card/merit-badge/scouts/{meritBadgeId}/level/{level}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters['level'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: level'))
  }
  path = path.replace('{meritBadgeId}', `${parameters['meritBadgeId']}`)
  if (parameters['meritBadgeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: meritBadgeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const printMeritBadgeScoutBadgeUsingGET_RAW_URL = function() {
  return '/card/merit-badge/scouts/{meritBadgeId}/level/{level}'
}
export const printMeritBadgeScoutBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const printMeritBadgeScoutBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/card/merit-badge/scouts/{meritBadgeId}/level/{level}'
  path = path.replace('{level}', `${parameters['level']}`)
  path = path.replace('{meritBadgeId}', `${parameters['meritBadgeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * printTrailBadgeCard
 * request: printTrailBadgeCardUsingGET
 * url: printTrailBadgeCardUsingGETURL
 * method: printTrailBadgeCardUsingGET_TYPE
 * raw_url: printTrailBadgeCardUsingGET_RAW_URL
 * @param trailBadgeId - trailBadgeId
 */
export const printTrailBadgeCardUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/card/trail-badge/{trailBadgeId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{trailBadgeId}', `${parameters['trailBadgeId']}`)
  if (parameters['trailBadgeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trailBadgeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const printTrailBadgeCardUsingGET_RAW_URL = function() {
  return '/card/trail-badge/{trailBadgeId}'
}
export const printTrailBadgeCardUsingGET_TYPE = function() {
  return 'get'
}
export const printTrailBadgeCardUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/card/trail-badge/{trailBadgeId}'
  path = path.replace('{trailBadgeId}', `${parameters['trailBadgeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createCategory
 * request: createCategoryUsingPOST
 * url: createCategoryUsingPOSTURL
 * method: createCategoryUsingPOST_TYPE
 * raw_url: createCategoryUsingPOST_RAW_URL
 * @param categoryRequest - categoryRequest
 */
export const createCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryRequest'] !== undefined) {
    body = parameters['categoryRequest']
  }
  if (parameters['categoryRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createCategoryUsingPOST_RAW_URL = function() {
  return '/category'
}
export const createCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const createCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editCategory
 * request: editCategoryUsingPUT
 * url: editCategoryUsingPUTURL
 * method: editCategoryUsingPUT_TYPE
 * raw_url: editCategoryUsingPUT_RAW_URL
 * @param categoryRequest - categoryRequest
 */
export const editCategoryUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryRequest'] !== undefined) {
    body = parameters['categoryRequest']
  }
  if (parameters['categoryRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editCategoryUsingPUT_RAW_URL = function() {
  return '/category'
}
export const editCategoryUsingPUT_TYPE = function() {
  return 'put'
}
export const editCategoryUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllCategories
 * request: getAllCategoriesUsingGET
 * url: getAllCategoriesUsingGETURL
 * method: getAllCategoriesUsingGET_TYPE
 * raw_url: getAllCategoriesUsingGET_RAW_URL
 */
export const getAllCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/public/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllCategoriesUsingGET_RAW_URL = function() {
  return '/category/public/all'
}
export const getAllCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/public/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllCategoriesForType
 * request: getAllCategoriesForTypeUsingGET
 * url: getAllCategoriesForTypeUsingGETURL
 * method: getAllCategoriesForTypeUsingGET_TYPE
 * raw_url: getAllCategoriesForTypeUsingGET_RAW_URL
 * @param categoryType - categoryType
 */
export const getAllCategoriesForTypeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/public/all/{categoryType}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{categoryType}', `${parameters['categoryType']}`)
  if (parameters['categoryType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllCategoriesForTypeUsingGET_RAW_URL = function() {
  return '/category/public/all/{categoryType}'
}
export const getAllCategoriesForTypeUsingGET_TYPE = function() {
  return 'get'
}
export const getAllCategoriesForTypeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/public/all/{categoryType}'
  path = path.replace('{categoryType}', `${parameters['categoryType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCategory
 * request: searchCategoryUsingPOST
 * url: searchCategoryUsingPOSTURL
 * method: searchCategoryUsingPOST_TYPE
 * raw_url: searchCategoryUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCategoryUsingPOST_RAW_URL = function() {
  return '/category/search'
}
export const searchCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCategoryCount
 * request: searchCategoryCountUsingPOST
 * url: searchCategoryCountUsingPOSTURL
 * method: searchCategoryCountUsingPOST_TYPE
 * raw_url: searchCategoryCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCategoryCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCategoryCountUsingPOST_RAW_URL = function() {
  return '/category/search/count'
}
export const searchCategoryCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCategoryCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCategoryById
 * request: getCategoryByIdUsingGET
 * url: getCategoryByIdUsingGETURL
 * method: getCategoryByIdUsingGET_TYPE
 * raw_url: getCategoryByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getCategoryByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCategoryByIdUsingGET_RAW_URL = function() {
  return '/category/{id}'
}
export const getCategoryByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getCategoryByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCategory
 * request: deleteCategoryUsingDELETE
 * url: deleteCategoryUsingDELETEURL
 * method: deleteCategoryUsingDELETE_TYPE
 * raw_url: deleteCategoryUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteCategoryUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/category/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCategoryUsingDELETE_RAW_URL = function() {
  return '/category/{id}'
}
export const deleteCategoryUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCategoryUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/category/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateChallenge
 * request: createOrUpdateChallengeUsingPOST
 * url: createOrUpdateChallengeUsingPOSTURL
 * method: createOrUpdateChallengeUsingPOST_TYPE
 * raw_url: createOrUpdateChallengeUsingPOST_RAW_URL
 * @param challengeDto - challengeDTO
 */
export const createOrUpdateChallengeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['challengeDto'] !== undefined) {
    body = parameters['challengeDto']
  }
  if (parameters['challengeDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: challengeDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateChallengeUsingPOST_RAW_URL = function() {
  return '/challenge/'
}
export const createOrUpdateChallengeUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateChallengeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicChallenge
 * request: searchPublicChallengeUsingPOST
 * url: searchPublicChallengeUsingPOSTURL
 * method: searchPublicChallengeUsingPOST_TYPE
 * raw_url: searchPublicChallengeUsingPOST_RAW_URL
 * @param challengePublicSearchCriteria - challengePublicSearchCriteria
 */
export const searchPublicChallengeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/public/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['challengePublicSearchCriteria'] !== undefined) {
    body = parameters['challengePublicSearchCriteria']
  }
  if (parameters['challengePublicSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: challengePublicSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicChallengeUsingPOST_RAW_URL = function() {
  return '/challenge/public/search'
}
export const searchPublicChallengeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicChallengeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/public/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicChallengeCount
 * request: searchPublicChallengeCountUsingPOST
 * url: searchPublicChallengeCountUsingPOSTURL
 * method: searchPublicChallengeCountUsingPOST_TYPE
 * raw_url: searchPublicChallengeCountUsingPOST_RAW_URL
 * @param challengePublicSearchCriteria - challengePublicSearchCriteria
 */
export const searchPublicChallengeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/public/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['challengePublicSearchCriteria'] !== undefined) {
    body = parameters['challengePublicSearchCriteria']
  }
  if (parameters['challengePublicSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: challengePublicSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicChallengeCountUsingPOST_RAW_URL = function() {
  return '/challenge/public/search/count'
}
export const searchPublicChallengeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicChallengeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/public/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPublicChallengeDTO
 * request: getPublicChallengeDTOUsingGET
 * url: getPublicChallengeDTOUsingGETURL
 * method: getPublicChallengeDTOUsingGET_TYPE
 * raw_url: getPublicChallengeDTOUsingGET_RAW_URL
 * @param id - id
 */
export const getPublicChallengeDTOUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/public/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPublicChallengeDTOUsingGET_RAW_URL = function() {
  return '/challenge/public/{id}'
}
export const getPublicChallengeDTOUsingGET_TYPE = function() {
  return 'get'
}
export const getPublicChallengeDTOUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/public/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchChallenge
 * request: searchChallengeUsingPOST
 * url: searchChallengeUsingPOSTURL
 * method: searchChallengeUsingPOST_TYPE
 * raw_url: searchChallengeUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchChallengeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchChallengeUsingPOST_RAW_URL = function() {
  return '/challenge/search'
}
export const searchChallengeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchChallengeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchChallengeCount
 * request: searchChallengeCountUsingPOST
 * url: searchChallengeCountUsingPOSTURL
 * method: searchChallengeCountUsingPOST_TYPE
 * raw_url: searchChallengeCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchChallengeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchChallengeCountUsingPOST_RAW_URL = function() {
  return '/challenge/search/count'
}
export const searchChallengeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchChallengeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getChallenge
 * request: getChallengeUsingGET
 * url: getChallengeUsingGETURL
 * method: getChallengeUsingGET_TYPE
 * raw_url: getChallengeUsingGET_RAW_URL
 * @param id - id
 */
export const getChallengeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getChallengeUsingGET_RAW_URL = function() {
  return '/challenge/{id}'
}
export const getChallengeUsingGET_TYPE = function() {
  return 'get'
}
export const getChallengeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteChallenge
 * request: deleteChallengeUsingDELETE
 * url: deleteChallengeUsingDELETEURL
 * method: deleteChallengeUsingDELETE_TYPE
 * raw_url: deleteChallengeUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteChallengeUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/challenge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteChallengeUsingDELETE_RAW_URL = function() {
  return '/challenge/{id}'
}
export const deleteChallengeUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteChallengeUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/challenge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateConfig
 * request: createOrUpdateConfigUsingPOST
 * url: createOrUpdateConfigUsingPOSTURL
 * method: createOrUpdateConfigUsingPOST_TYPE
 * raw_url: createOrUpdateConfigUsingPOST_RAW_URL
 * @param configDto - configDTO
 */
export const createOrUpdateConfigUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['configDto'] !== undefined) {
    body = parameters['configDto']
  }
  if (parameters['configDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: configDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateConfigUsingPOST_RAW_URL = function() {
  return '/config/'
}
export const createOrUpdateConfigUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateConfigUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getConfigByKey
 * request: getConfigByKeyUsingGET
 * url: getConfigByKeyUsingGETURL
 * method: getConfigByKeyUsingGET_TYPE
 * raw_url: getConfigByKeyUsingGET_RAW_URL
 * @param key - key
 */
export const getConfigByKeyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/key/{key}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters['key'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: key'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getConfigByKeyUsingGET_RAW_URL = function() {
  return '/config/key/{key}'
}
export const getConfigByKeyUsingGET_TYPE = function() {
  return 'get'
}
export const getConfigByKeyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/key/{key}'
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteConfig
 * request: deleteConfigUsingDELETE_1
 * url: deleteConfigUsingDELETE_1URL
 * method: deleteConfigUsingDELETE_1_TYPE
 * raw_url: deleteConfigUsingDELETE_1_RAW_URL
 * @param key - key
 */
export const deleteConfigUsingDELETE_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/key/{key}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters['key'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: key'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteConfigUsingDELETE_1_RAW_URL = function() {
  return '/config/key/{key}'
}
export const deleteConfigUsingDELETE_1_TYPE = function() {
  return 'delete'
}
export const deleteConfigUsingDELETE_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/key/{key}'
  path = path.replace('{key}', `${parameters['key']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * refreshBadgeSearch
 * request: refreshBadgeSearchUsingPOST
 * url: refreshBadgeSearchUsingPOSTURL
 * method: refreshBadgeSearchUsingPOST_TYPE
 * raw_url: refreshBadgeSearchUsingPOST_RAW_URL
 */
export const refreshBadgeSearchUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/refresh-badge-search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const refreshBadgeSearchUsingPOST_RAW_URL = function() {
  return '/config/refresh-badge-search'
}
export const refreshBadgeSearchUsingPOST_TYPE = function() {
  return 'post'
}
export const refreshBadgeSearchUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/refresh-badge-search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchConfig
 * request: searchConfigUsingPOST
 * url: searchConfigUsingPOSTURL
 * method: searchConfigUsingPOST_TYPE
 * raw_url: searchConfigUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchConfigUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchConfigUsingPOST_RAW_URL = function() {
  return '/config/search'
}
export const searchConfigUsingPOST_TYPE = function() {
  return 'post'
}
export const searchConfigUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchConfigCount
 * request: searchConfigCountUsingPOST
 * url: searchConfigCountUsingPOSTURL
 * method: searchConfigCountUsingPOST_TYPE
 * raw_url: searchConfigCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchConfigCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchConfigCountUsingPOST_RAW_URL = function() {
  return '/config/search/count'
}
export const searchConfigCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchConfigCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * synchronizeFiles
 * request: synchronizeFilesUsingPOST
 * url: synchronizeFilesUsingPOSTURL
 * method: synchronizeFilesUsingPOST_TYPE
 * raw_url: synchronizeFilesUsingPOST_RAW_URL
 */
export const synchronizeFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/synchronize-files'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const synchronizeFilesUsingPOST_RAW_URL = function() {
  return '/config/synchronize-files'
}
export const synchronizeFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const synchronizeFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/synchronize-files'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getConfig
 * request: getConfigUsingGET
 * url: getConfigUsingGETURL
 * method: getConfigUsingGET_TYPE
 * raw_url: getConfigUsingGET_RAW_URL
 * @param id - id
 */
export const getConfigUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getConfigUsingGET_RAW_URL = function() {
  return '/config/{id}'
}
export const getConfigUsingGET_TYPE = function() {
  return 'get'
}
export const getConfigUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteConfig
 * request: deleteConfigUsingDELETE
 * url: deleteConfigUsingDELETEURL
 * method: deleteConfigUsingDELETE_TYPE
 * raw_url: deleteConfigUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteConfigUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/config/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteConfigUsingDELETE_RAW_URL = function() {
  return '/config/{id}'
}
export const deleteConfigUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteConfigUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/config/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createDevelopmentArea
 * request: createDevelopmentAreaUsingPOST
 * url: createDevelopmentAreaUsingPOSTURL
 * method: createDevelopmentAreaUsingPOST_TYPE
 * raw_url: createDevelopmentAreaUsingPOST_RAW_URL
 * @param developmentAreaDto - developmentAreaDto
 */
export const createDevelopmentAreaUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['developmentAreaDto'] !== undefined) {
    body = parameters['developmentAreaDto']
  }
  if (parameters['developmentAreaDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: developmentAreaDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createDevelopmentAreaUsingPOST_RAW_URL = function() {
  return '/development-area'
}
export const createDevelopmentAreaUsingPOST_TYPE = function() {
  return 'post'
}
export const createDevelopmentAreaUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editDevelopmentArea
 * request: editDevelopmentAreaUsingPUT
 * url: editDevelopmentAreaUsingPUTURL
 * method: editDevelopmentAreaUsingPUT_TYPE
 * raw_url: editDevelopmentAreaUsingPUT_RAW_URL
 * @param developmentAreaDto - developmentAreaDto
 */
export const editDevelopmentAreaUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['developmentAreaDto'] !== undefined) {
    body = parameters['developmentAreaDto']
  }
  if (parameters['developmentAreaDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: developmentAreaDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editDevelopmentAreaUsingPUT_RAW_URL = function() {
  return '/development-area'
}
export const editDevelopmentAreaUsingPUT_TYPE = function() {
  return 'put'
}
export const editDevelopmentAreaUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllDevelopmentAreas
 * request: getAllDevelopmentAreasUsingGET
 * url: getAllDevelopmentAreasUsingGETURL
 * method: getAllDevelopmentAreasUsingGET_TYPE
 * raw_url: getAllDevelopmentAreasUsingGET_RAW_URL
 */
export const getAllDevelopmentAreasUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area/public/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllDevelopmentAreasUsingGET_RAW_URL = function() {
  return '/development-area/public/all'
}
export const getAllDevelopmentAreasUsingGET_TYPE = function() {
  return 'get'
}
export const getAllDevelopmentAreasUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area/public/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDevelopmentArea
 * request: searchDevelopmentAreaUsingPOST
 * url: searchDevelopmentAreaUsingPOSTURL
 * method: searchDevelopmentAreaUsingPOST_TYPE
 * raw_url: searchDevelopmentAreaUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDevelopmentAreaUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDevelopmentAreaUsingPOST_RAW_URL = function() {
  return '/development-area/search'
}
export const searchDevelopmentAreaUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDevelopmentAreaUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDevelopmentAreaCount
 * request: searchDevelopmentAreaCountUsingPOST
 * url: searchDevelopmentAreaCountUsingPOSTURL
 * method: searchDevelopmentAreaCountUsingPOST_TYPE
 * raw_url: searchDevelopmentAreaCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDevelopmentAreaCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDevelopmentAreaCountUsingPOST_RAW_URL = function() {
  return '/development-area/search/count'
}
export const searchDevelopmentAreaCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDevelopmentAreaCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDevelopmentArea
 * request: getDevelopmentAreaUsingGET
 * url: getDevelopmentAreaUsingGETURL
 * method: getDevelopmentAreaUsingGET_TYPE
 * raw_url: getDevelopmentAreaUsingGET_RAW_URL
 * @param id - id
 */
export const getDevelopmentAreaUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDevelopmentAreaUsingGET_RAW_URL = function() {
  return '/development-area/{id}'
}
export const getDevelopmentAreaUsingGET_TYPE = function() {
  return 'get'
}
export const getDevelopmentAreaUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteDevelopmentArea
 * request: deleteDevelopmentAreaUsingDELETE
 * url: deleteDevelopmentAreaUsingDELETEURL
 * method: deleteDevelopmentAreaUsingDELETE_TYPE
 * raw_url: deleteDevelopmentAreaUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteDevelopmentAreaUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/development-area/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDevelopmentAreaUsingDELETE_RAW_URL = function() {
  return '/development-area/{id}'
}
export const deleteDevelopmentAreaUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteDevelopmentAreaUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/development-area/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchExternalFile
 * request: searchExternalFileUsingPOST
 * url: searchExternalFileUsingPOSTURL
 * method: searchExternalFileUsingPOST_TYPE
 * raw_url: searchExternalFileUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchExternalFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/externalFile/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchExternalFileUsingPOST_RAW_URL = function() {
  return '/externalFile/search'
}
export const searchExternalFileUsingPOST_TYPE = function() {
  return 'post'
}
export const searchExternalFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/externalFile/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchExternalFileCount
 * request: searchExternalFileCountUsingPOST
 * url: searchExternalFileCountUsingPOSTURL
 * method: searchExternalFileCountUsingPOST_TYPE
 * raw_url: searchExternalFileCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchExternalFileCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/externalFile/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchExternalFileCountUsingPOST_RAW_URL = function() {
  return '/externalFile/search/count'
}
export const searchExternalFileCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchExternalFileCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/externalFile/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getExternalFile
 * request: getExternalFileUsingGET
 * url: getExternalFileUsingGETURL
 * method: getExternalFileUsingGET_TYPE
 * raw_url: getExternalFileUsingGET_RAW_URL
 * @param id - id
 */
export const getExternalFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/externalFile/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getExternalFileUsingGET_RAW_URL = function() {
  return '/externalFile/{id}'
}
export const getExternalFileUsingGET_TYPE = function() {
  return 'get'
}
export const getExternalFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/externalFile/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteExternalFile
 * request: deleteExternalFileUsingDELETE
 * url: deleteExternalFileUsingDELETEURL
 * method: deleteExternalFileUsingDELETE_TYPE
 * raw_url: deleteExternalFileUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteExternalFileUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/externalFile/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteExternalFileUsingDELETE_RAW_URL = function() {
  return '/externalFile/{id}'
}
export const deleteExternalFileUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteExternalFileUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/externalFile/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createLabel
 * request: createLabelUsingPOST
 * url: createLabelUsingPOSTURL
 * method: createLabelUsingPOST_TYPE
 * raw_url: createLabelUsingPOST_RAW_URL
 * @param labelRequest - labelRequest
 */
export const createLabelUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['labelRequest'] !== undefined) {
    body = parameters['labelRequest']
  }
  if (parameters['labelRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: labelRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createLabelUsingPOST_RAW_URL = function() {
  return '/label'
}
export const createLabelUsingPOST_TYPE = function() {
  return 'post'
}
export const createLabelUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editLabel
 * request: editLabelUsingPUT
 * url: editLabelUsingPUTURL
 * method: editLabelUsingPUT_TYPE
 * raw_url: editLabelUsingPUT_RAW_URL
 * @param labelRequest - labelRequest
 */
export const editLabelUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['labelRequest'] !== undefined) {
    body = parameters['labelRequest']
  }
  if (parameters['labelRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: labelRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editLabelUsingPUT_RAW_URL = function() {
  return '/label'
}
export const editLabelUsingPUT_TYPE = function() {
  return 'put'
}
export const editLabelUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllLabels
 * request: getAllLabelsUsingGET
 * url: getAllLabelsUsingGETURL
 * method: getAllLabelsUsingGET_TYPE
 * raw_url: getAllLabelsUsingGET_RAW_URL
 */
export const getAllLabelsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllLabelsUsingGET_RAW_URL = function() {
  return '/label/all'
}
export const getAllLabelsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllLabelsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllActiveLabels
 * request: getAllActiveLabelsUsingGET
 * url: getAllActiveLabelsUsingGETURL
 * method: getAllActiveLabelsUsingGET_TYPE
 * raw_url: getAllActiveLabelsUsingGET_RAW_URL
 */
export const getAllActiveLabelsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/all/active'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllActiveLabelsUsingGET_RAW_URL = function() {
  return '/label/all/active'
}
export const getAllActiveLabelsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllActiveLabelsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/all/active'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getActiveLabelsByBadgeTypeAndAgeGroup
 * request: getActiveLabelsByBadgeTypeAndAgeGroupUsingGET
 * url: getActiveLabelsByBadgeTypeAndAgeGroupUsingGETURL
 * method: getActiveLabelsByBadgeTypeAndAgeGroupUsingGET_TYPE
 * raw_url: getActiveLabelsByBadgeTypeAndAgeGroupUsingGET_RAW_URL
 * @param ageGroupName - ageGroupName
 * @param badgeType - badgeType
 */
export const getActiveLabelsByBadgeTypeAndAgeGroupUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/all/{badgeType}/{ageGroupName}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{ageGroupName}', `${parameters['ageGroupName']}`)
  if (parameters['ageGroupName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ageGroupName'))
  }
  path = path.replace('{badgeType}', `${parameters['badgeType']}`)
  if (parameters['badgeType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: badgeType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getActiveLabelsByBadgeTypeAndAgeGroupUsingGET_RAW_URL = function() {
  return '/label/all/{badgeType}/{ageGroupName}'
}
export const getActiveLabelsByBadgeTypeAndAgeGroupUsingGET_TYPE = function() {
  return 'get'
}
export const getActiveLabelsByBadgeTypeAndAgeGroupUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/all/{badgeType}/{ageGroupName}'
  path = path.replace('{ageGroupName}', `${parameters['ageGroupName']}`)
  path = path.replace('{badgeType}', `${parameters['badgeType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchLabel
 * request: searchLabelUsingPOST
 * url: searchLabelUsingPOSTURL
 * method: searchLabelUsingPOST_TYPE
 * raw_url: searchLabelUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchLabelUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchLabelUsingPOST_RAW_URL = function() {
  return '/label/search'
}
export const searchLabelUsingPOST_TYPE = function() {
  return 'post'
}
export const searchLabelUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchLabelCount
 * request: searchLabelCountUsingPOST
 * url: searchLabelCountUsingPOSTURL
 * method: searchLabelCountUsingPOST_TYPE
 * raw_url: searchLabelCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchLabelCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchLabelCountUsingPOST_RAW_URL = function() {
  return '/label/search/count'
}
export const searchLabelCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchLabelCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLabelById
 * request: getLabelByIdUsingGET
 * url: getLabelByIdUsingGETURL
 * method: getLabelByIdUsingGET_TYPE
 * raw_url: getLabelByIdUsingGET_RAW_URL
 * @param id - id
 */
export const getLabelByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLabelByIdUsingGET_RAW_URL = function() {
  return '/label/{id}'
}
export const getLabelByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getLabelByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteLabel
 * request: deleteLabelUsingDELETE
 * url: deleteLabelUsingDELETEURL
 * method: deleteLabelUsingDELETE_TYPE
 * raw_url: deleteLabelUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteLabelUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/label/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteLabelUsingDELETE_RAW_URL = function() {
  return '/label/{id}'
}
export const deleteLabelUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteLabelUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/label/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateMeritBadge
 * request: createOrUpdateMeritBadgeUsingPOST
 * url: createOrUpdateMeritBadgeUsingPOSTURL
 * method: createOrUpdateMeritBadgeUsingPOST_TYPE
 * raw_url: createOrUpdateMeritBadgeUsingPOST_RAW_URL
 * @param meritBadgeEditDto - meritBadgeEditDTO
 */
export const createOrUpdateMeritBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['meritBadgeEditDto'] !== undefined) {
    body = parameters['meritBadgeEditDto']
  }
  if (parameters['meritBadgeEditDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: meritBadgeEditDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateMeritBadgeUsingPOST_RAW_URL = function() {
  return '/merit-badge/'
}
export const createOrUpdateMeritBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateMeritBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicMeritBadge
 * request: searchPublicMeritBadgeUsingPOST
 * url: searchPublicMeritBadgeUsingPOSTURL
 * method: searchPublicMeritBadgeUsingPOST_TYPE
 * raw_url: searchPublicMeritBadgeUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPublicMeritBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/public/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicMeritBadgeUsingPOST_RAW_URL = function() {
  return '/merit-badge/public/search'
}
export const searchPublicMeritBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicMeritBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/public/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicMeritBadgeCount
 * request: searchPublicMeritBadgeCountUsingPOST
 * url: searchPublicMeritBadgeCountUsingPOSTURL
 * method: searchPublicMeritBadgeCountUsingPOST_TYPE
 * raw_url: searchPublicMeritBadgeCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPublicMeritBadgeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/public/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicMeritBadgeCountUsingPOST_RAW_URL = function() {
  return '/merit-badge/public/search/count'
}
export const searchPublicMeritBadgeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicMeritBadgeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/public/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPublicMeritBadge
 * request: getPublicMeritBadgeUsingGET
 * url: getPublicMeritBadgeUsingGETURL
 * method: getPublicMeritBadgeUsingGET_TYPE
 * raw_url: getPublicMeritBadgeUsingGET_RAW_URL
 * @param id - id
 */
export const getPublicMeritBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/public/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPublicMeritBadgeUsingGET_RAW_URL = function() {
  return '/merit-badge/public/{id}'
}
export const getPublicMeritBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const getPublicMeritBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/public/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMeritBadge
 * request: searchMeritBadgeUsingPOST
 * url: searchMeritBadgeUsingPOSTURL
 * method: searchMeritBadgeUsingPOST_TYPE
 * raw_url: searchMeritBadgeUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMeritBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMeritBadgeUsingPOST_RAW_URL = function() {
  return '/merit-badge/search'
}
export const searchMeritBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMeritBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMeritBadgeCount
 * request: searchMeritBadgeCountUsingPOST
 * url: searchMeritBadgeCountUsingPOSTURL
 * method: searchMeritBadgeCountUsingPOST_TYPE
 * raw_url: searchMeritBadgeCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMeritBadgeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMeritBadgeCountUsingPOST_RAW_URL = function() {
  return '/merit-badge/search/count'
}
export const searchMeritBadgeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMeritBadgeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMeritBadge
 * request: getMeritBadgeUsingGET
 * url: getMeritBadgeUsingGETURL
 * method: getMeritBadgeUsingGET_TYPE
 * raw_url: getMeritBadgeUsingGET_RAW_URL
 * @param id - id
 */
export const getMeritBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMeritBadgeUsingGET_RAW_URL = function() {
  return '/merit-badge/{id}'
}
export const getMeritBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const getMeritBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMeritBadge
 * request: deleteMeritBadgeUsingDELETE
 * url: deleteMeritBadgeUsingDELETEURL
 * method: deleteMeritBadgeUsingDELETE_TYPE
 * raw_url: deleteMeritBadgeUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteMeritBadgeUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/merit-badge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMeritBadgeUsingDELETE_RAW_URL = function() {
  return '/merit-badge/{id}'
}
export const deleteMeritBadgeUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMeritBadgeUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/merit-badge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMeritBadgeCubScoutBadgeCardData
 * request: getMeritBadgeCubScoutBadgeCardDataUsingGET
 * url: getMeritBadgeCubScoutBadgeCardDataUsingGETURL
 * method: getMeritBadgeCubScoutBadgeCardDataUsingGET_TYPE
 * raw_url: getMeritBadgeCubScoutBadgeCardDataUsingGET_RAW_URL
 * @param id - id
 * @param level - level
 */
export const getMeritBadgeCubScoutBadgeCardDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-data/merit-badge/cub-scouts/{id}/level/{level}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters['level'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: level'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMeritBadgeCubScoutBadgeCardDataUsingGET_RAW_URL = function() {
  return '/print-data/merit-badge/cub-scouts/{id}/level/{level}'
}
export const getMeritBadgeCubScoutBadgeCardDataUsingGET_TYPE = function() {
  return 'get'
}
export const getMeritBadgeCubScoutBadgeCardDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-data/merit-badge/cub-scouts/{id}/level/{level}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMeritBadgeScoutBadgeCardData
 * request: getMeritBadgeScoutBadgeCardDataUsingGET
 * url: getMeritBadgeScoutBadgeCardDataUsingGETURL
 * method: getMeritBadgeScoutBadgeCardDataUsingGET_TYPE
 * raw_url: getMeritBadgeScoutBadgeCardDataUsingGET_RAW_URL
 * @param id - id
 * @param level - level
 */
export const getMeritBadgeScoutBadgeCardDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-data/merit-badge/scouts/{id}/level/{level}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters['level'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: level'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMeritBadgeScoutBadgeCardDataUsingGET_RAW_URL = function() {
  return '/print-data/merit-badge/scouts/{id}/level/{level}'
}
export const getMeritBadgeScoutBadgeCardDataUsingGET_TYPE = function() {
  return 'get'
}
export const getMeritBadgeScoutBadgeCardDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-data/merit-badge/scouts/{id}/level/{level}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{level}', `${parameters['level']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrailBadgeCardData
 * request: getTrailBadgeCardDataUsingGET
 * url: getTrailBadgeCardDataUsingGETURL
 * method: getTrailBadgeCardDataUsingGET_TYPE
 * raw_url: getTrailBadgeCardDataUsingGET_RAW_URL
 * @param id - id
 */
export const getTrailBadgeCardDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-data/trail-badge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrailBadgeCardDataUsingGET_RAW_URL = function() {
  return '/print-data/trail-badge/{id}'
}
export const getTrailBadgeCardDataUsingGET_TYPE = function() {
  return 'get'
}
export const getTrailBadgeCardDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-data/trail-badge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadRankPrintFile
 * request: downloadRankPrintFileUsingGET
 * url: downloadRankPrintFileUsingGETURL
 * method: downloadRankPrintFileUsingGET_TYPE
 * raw_url: downloadRankPrintFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadRankPrintFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/public/rank/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadRankPrintFileUsingGET_RAW_URL = function() {
  return '/print-file/public/rank/download/{id}'
}
export const downloadRankPrintFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadRankPrintFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/public/rank/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRankPrintFileListByRankId
 * request: getRankPrintFileListByRankIdUsingGET
 * url: getRankPrintFileListByRankIdUsingGETURL
 * method: getRankPrintFileListByRankIdUsingGET_TYPE
 * raw_url: getRankPrintFileListByRankIdUsingGET_RAW_URL
 * @param rankId - rankId
 */
export const getRankPrintFileListByRankIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/public/rank/list/{rankId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{rankId}', `${parameters['rankId']}`)
  if (parameters['rankId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rankId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRankPrintFileListByRankIdUsingGET_RAW_URL = function() {
  return '/print-file/public/rank/list/{rankId}'
}
export const getRankPrintFileListByRankIdUsingGET_TYPE = function() {
  return 'get'
}
export const getRankPrintFileListByRankIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/public/rank/list/{rankId}'
  path = path.replace('{rankId}', `${parameters['rankId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRankPrintFile
 * request: getRankPrintFileUsingGET
 * url: getRankPrintFileUsingGETURL
 * method: getRankPrintFileUsingGET_TYPE
 * raw_url: getRankPrintFileUsingGET_RAW_URL
 * @param id - id
 */
export const getRankPrintFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/public/rank/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRankPrintFileUsingGET_RAW_URL = function() {
  return '/print-file/public/rank/{id}'
}
export const getRankPrintFileUsingGET_TYPE = function() {
  return 'get'
}
export const getRankPrintFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/public/rank/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateRankPrintFile
 * request: updateRankPrintFileUsingPUT
 * url: updateRankPrintFileUsingPUTURL
 * method: updateRankPrintFileUsingPUT_TYPE
 * raw_url: updateRankPrintFileUsingPUT_RAW_URL
 * @param rankPrintFileDataDto - rankPrintFileDataDTO
 */
export const updateRankPrintFileUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/rank/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['rankPrintFileDataDto'] !== undefined) {
    body = parameters['rankPrintFileDataDto']
  }
  if (parameters['rankPrintFileDataDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rankPrintFileDataDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateRankPrintFileUsingPUT_RAW_URL = function() {
  return '/print-file/rank/update'
}
export const updateRankPrintFileUsingPUT_TYPE = function() {
  return 'put'
}
export const updateRankPrintFileUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/rank/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadRankPrintFile
 * request: uploadRankPrintFileUsingPOST
 * url: uploadRankPrintFileUsingPOSTURL
 * method: uploadRankPrintFileUsingPOST_TYPE
 * raw_url: uploadRankPrintFileUsingPOST_RAW_URL
 * @param rankPrintFileDataDto - rankPrintFileDataDTO
 */
export const uploadRankPrintFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/rank/upload'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['rankPrintFileDataDto'] !== undefined) {
    body = parameters['rankPrintFileDataDto']
  }
  if (parameters['rankPrintFileDataDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rankPrintFileDataDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadRankPrintFileUsingPOST_RAW_URL = function() {
  return '/print-file/rank/upload'
}
export const uploadRankPrintFileUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadRankPrintFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/rank/upload'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteRankPrintFile
 * request: deleteRankPrintFileUsingDELETE
 * url: deleteRankPrintFileUsingDELETEURL
 * method: deleteRankPrintFileUsingDELETE_TYPE
 * raw_url: deleteRankPrintFileUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteRankPrintFileUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/print-file/rank/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRankPrintFileUsingDELETE_RAW_URL = function() {
  return '/print-file/rank/{id}'
}
export const deleteRankPrintFileUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRankPrintFileUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/print-file/rank/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateRank
 * request: createOrUpdateRankUsingPOST
 * url: createOrUpdateRankUsingPOSTURL
 * method: createOrUpdateRankUsingPOST_TYPE
 * raw_url: createOrUpdateRankUsingPOST_RAW_URL
 * @param rankEditDto - rankEditDTO
 */
export const createOrUpdateRankUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['rankEditDto'] !== undefined) {
    body = parameters['rankEditDto']
  }
  if (parameters['rankEditDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rankEditDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateRankUsingPOST_RAW_URL = function() {
  return '/rank/'
}
export const createOrUpdateRankUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateRankUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAgeGroupMap
 * request: getAgeGroupMapUsingGET
 * url: getAgeGroupMapUsingGETURL
 * method: getAgeGroupMapUsingGET_TYPE
 * raw_url: getAgeGroupMapUsingGET_RAW_URL
 */
export const getAgeGroupMapUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/age-group-map'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAgeGroupMapUsingGET_RAW_URL = function() {
  return '/rank/age-group-map'
}
export const getAgeGroupMapUsingGET_TYPE = function() {
  return 'get'
}
export const getAgeGroupMapUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/age-group-map'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllRanks
 * request: getAllRanksUsingGET
 * url: getAllRanksUsingGETURL
 * method: getAllRanksUsingGET_TYPE
 * raw_url: getAllRanksUsingGET_RAW_URL
 */
export const getAllRanksUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllRanksUsingGET_RAW_URL = function() {
  return '/rank/all'
}
export const getAllRanksUsingGET_TYPE = function() {
  return 'get'
}
export const getAllRanksUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllActiveRanks
 * request: getAllActiveRanksUsingGET
 * url: getAllActiveRanksUsingGETURL
 * method: getAllActiveRanksUsingGET_TYPE
 * raw_url: getAllActiveRanksUsingGET_RAW_URL
 */
export const getAllActiveRanksUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/all/active'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllActiveRanksUsingGET_RAW_URL = function() {
  return '/rank/all/active'
}
export const getAllActiveRanksUsingGET_TYPE = function() {
  return 'get'
}
export const getAllActiveRanksUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/all/active'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchRank
 * request: searchRankUsingPOST
 * url: searchRankUsingPOSTURL
 * method: searchRankUsingPOST_TYPE
 * raw_url: searchRankUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchRankUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchRankUsingPOST_RAW_URL = function() {
  return '/rank/search'
}
export const searchRankUsingPOST_TYPE = function() {
  return 'post'
}
export const searchRankUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchRankCount
 * request: searchRankCountUsingPOST
 * url: searchRankCountUsingPOSTURL
 * method: searchRankCountUsingPOST_TYPE
 * raw_url: searchRankCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchRankCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchRankCountUsingPOST_RAW_URL = function() {
  return '/rank/search/count'
}
export const searchRankCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchRankCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRankView
 * request: getRankViewUsingGET
 * url: getRankViewUsingGETURL
 * method: getRankViewUsingGET_TYPE
 * raw_url: getRankViewUsingGET_RAW_URL
 * @param id - id
 */
export const getRankViewUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/view/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRankViewUsingGET_RAW_URL = function() {
  return '/rank/view/{id}'
}
export const getRankViewUsingGET_TYPE = function() {
  return 'get'
}
export const getRankViewUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/view/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRank
 * request: getRankUsingGET
 * url: getRankUsingGETURL
 * method: getRankUsingGET_TYPE
 * raw_url: getRankUsingGET_RAW_URL
 * @param id - id
 */
export const getRankUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRankUsingGET_RAW_URL = function() {
  return '/rank/{id}'
}
export const getRankUsingGET_TYPE = function() {
  return 'get'
}
export const getRankUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteRank
 * request: deleteRankUsingDELETE
 * url: deleteRankUsingDELETEURL
 * method: deleteRankUsingDELETE_TYPE
 * raw_url: deleteRankUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteRankUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/rank/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteRankUsingDELETE_RAW_URL = function() {
  return '/rank/{id}'
}
export const deleteRankUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteRankUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/rank/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateReview
 * request: createOrUpdateReviewUsingPOST
 * url: createOrUpdateReviewUsingPOSTURL
 * method: createOrUpdateReviewUsingPOST_TYPE
 * raw_url: createOrUpdateReviewUsingPOST_RAW_URL
 * @param reviewDto - reviewDTO
 */
export const createOrUpdateReviewUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/review/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reviewDto'] !== undefined) {
    body = parameters['reviewDto']
  }
  if (parameters['reviewDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reviewDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateReviewUsingPOST_RAW_URL = function() {
  return '/review/'
}
export const createOrUpdateReviewUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateReviewUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/review/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getReviewsForInstrument
 * request: getReviewsForInstrumentUsingGET
 * url: getReviewsForInstrumentUsingGETURL
 * method: getReviewsForInstrumentUsingGET_TYPE
 * raw_url: getReviewsForInstrumentUsingGET_RAW_URL
 * @param instrumentId - instrumentId
 * @param instrumentType - instrumentType
 */
export const getReviewsForInstrumentUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/review/instrument'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['instrumentId'] !== undefined) {
    queryParameters['instrumentId'] = parameters['instrumentId']
  }
  if (parameters['instrumentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: instrumentId'))
  }
  if (parameters['instrumentType'] !== undefined) {
    queryParameters['instrumentType'] = parameters['instrumentType']
  }
  if (parameters['instrumentType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: instrumentType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getReviewsForInstrumentUsingGET_RAW_URL = function() {
  return '/review/instrument'
}
export const getReviewsForInstrumentUsingGET_TYPE = function() {
  return 'get'
}
export const getReviewsForInstrumentUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/review/instrument'
  if (parameters['instrumentId'] !== undefined) {
    queryParameters['instrumentId'] = parameters['instrumentId']
  }
  if (parameters['instrumentType'] !== undefined) {
    queryParameters['instrumentType'] = parameters['instrumentType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editScoutLaw
 * request: editScoutLawUsingPUT
 * url: editScoutLawUsingPUTURL
 * method: editScoutLawUsingPUT_TYPE
 * raw_url: editScoutLawUsingPUT_RAW_URL
 * @param scoutLawDto - scoutLawDTO
 */
export const editScoutLawUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['scoutLawDto'] !== undefined) {
    body = parameters['scoutLawDto']
  }
  if (parameters['scoutLawDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: scoutLawDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editScoutLawUsingPUT_RAW_URL = function() {
  return '/scout-law'
}
export const editScoutLawUsingPUT_TYPE = function() {
  return 'put'
}
export const editScoutLawUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createScoutLaw
 * request: createScoutLawUsingPOST
 * url: createScoutLawUsingPOSTURL
 * method: createScoutLawUsingPOST_TYPE
 * raw_url: createScoutLawUsingPOST_RAW_URL
 * @param scoutLawDto - scoutLawDTO
 */
export const createScoutLawUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['scoutLawDto'] !== undefined) {
    body = parameters['scoutLawDto']
  }
  if (parameters['scoutLawDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: scoutLawDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createScoutLawUsingPOST_RAW_URL = function() {
  return '/scout-law/'
}
export const createScoutLawUsingPOST_TYPE = function() {
  return 'post'
}
export const createScoutLawUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllScoutLawsByType
 * request: getAllScoutLawsByTypeUsingGET
 * url: getAllScoutLawsByTypeUsingGETURL
 * method: getAllScoutLawsByTypeUsingGET_TYPE
 * raw_url: getAllScoutLawsByTypeUsingGET_RAW_URL
 * @param type - type
 */
export const getAllScoutLawsByTypeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/all/{type}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllScoutLawsByTypeUsingGET_RAW_URL = function() {
  return '/scout-law/all/{type}'
}
export const getAllScoutLawsByTypeUsingGET_TYPE = function() {
  return 'get'
}
export const getAllScoutLawsByTypeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/all/{type}'
  path = path.replace('{type}', `${parameters['type']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * reorderScoutLaw
 * request: reorderScoutLawUsingPUT
 * url: reorderScoutLawUsingPUTURL
 * method: reorderScoutLawUsingPUT_TYPE
 * raw_url: reorderScoutLawUsingPUT_RAW_URL
 * @param order - order
 */
export const reorderScoutLawUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/reorder'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['order'] !== undefined) {
    body = parameters['order']
  }
  if (parameters['order'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: order'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const reorderScoutLawUsingPUT_RAW_URL = function() {
  return '/scout-law/reorder'
}
export const reorderScoutLawUsingPUT_TYPE = function() {
  return 'put'
}
export const reorderScoutLawUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/reorder'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchScoutLaw
 * request: searchScoutLawUsingPOST
 * url: searchScoutLawUsingPOSTURL
 * method: searchScoutLawUsingPOST_TYPE
 * raw_url: searchScoutLawUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchScoutLawUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchScoutLawUsingPOST_RAW_URL = function() {
  return '/scout-law/search'
}
export const searchScoutLawUsingPOST_TYPE = function() {
  return 'post'
}
export const searchScoutLawUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchScoutLawCount
 * request: searchScoutLawCountUsingPOST
 * url: searchScoutLawCountUsingPOSTURL
 * method: searchScoutLawCountUsingPOST_TYPE
 * raw_url: searchScoutLawCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchScoutLawCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchScoutLawCountUsingPOST_RAW_URL = function() {
  return '/scout-law/search/count'
}
export const searchScoutLawCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchScoutLawCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getScoutLaw
 * request: getScoutLawUsingGET
 * url: getScoutLawUsingGETURL
 * method: getScoutLawUsingGET_TYPE
 * raw_url: getScoutLawUsingGET_RAW_URL
 * @param id - id
 */
export const getScoutLawUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getScoutLawUsingGET_RAW_URL = function() {
  return '/scout-law/{id}'
}
export const getScoutLawUsingGET_TYPE = function() {
  return 'get'
}
export const getScoutLawUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteScoutLaw
 * request: deleteScoutLawUsingDELETE
 * url: deleteScoutLawUsingDELETEURL
 * method: deleteScoutLawUsingDELETE_TYPE
 * raw_url: deleteScoutLawUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteScoutLawUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/scout-law/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteScoutLawUsingDELETE_RAW_URL = function() {
  return '/scout-law/{id}'
}
export const deleteScoutLawUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteScoutLawUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/scout-law/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateSpecialty
 * request: createOrUpdateSpecialtyUsingPOST
 * url: createOrUpdateSpecialtyUsingPOSTURL
 * method: createOrUpdateSpecialtyUsingPOST_TYPE
 * raw_url: createOrUpdateSpecialtyUsingPOST_RAW_URL
 * @param specialtyDto - specialtyDTO
 */
export const createOrUpdateSpecialtyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['specialtyDto'] !== undefined) {
    body = parameters['specialtyDto']
  }
  if (parameters['specialtyDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: specialtyDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateSpecialtyUsingPOST_RAW_URL = function() {
  return '/specialty/'
}
export const createOrUpdateSpecialtyUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateSpecialtyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllSpecialties
 * request: getAllSpecialtiesUsingGET
 * url: getAllSpecialtiesUsingGETURL
 * method: getAllSpecialtiesUsingGET_TYPE
 * raw_url: getAllSpecialtiesUsingGET_RAW_URL
 */
export const getAllSpecialtiesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/public/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllSpecialtiesUsingGET_RAW_URL = function() {
  return '/specialty/public/all'
}
export const getAllSpecialtiesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllSpecialtiesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/public/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchSpecialty
 * request: searchSpecialtyUsingPOST
 * url: searchSpecialtyUsingPOSTURL
 * method: searchSpecialtyUsingPOST_TYPE
 * raw_url: searchSpecialtyUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchSpecialtyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSpecialtyUsingPOST_RAW_URL = function() {
  return '/specialty/search'
}
export const searchSpecialtyUsingPOST_TYPE = function() {
  return 'post'
}
export const searchSpecialtyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchSpecialtyCount
 * request: searchSpecialtyCountUsingPOST
 * url: searchSpecialtyCountUsingPOSTURL
 * method: searchSpecialtyCountUsingPOST_TYPE
 * raw_url: searchSpecialtyCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchSpecialtyCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSpecialtyCountUsingPOST_RAW_URL = function() {
  return '/specialty/search/count'
}
export const searchSpecialtyCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchSpecialtyCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSpecialty
 * request: getSpecialtyUsingGET
 * url: getSpecialtyUsingGETURL
 * method: getSpecialtyUsingGET_TYPE
 * raw_url: getSpecialtyUsingGET_RAW_URL
 * @param id - id
 */
export const getSpecialtyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSpecialtyUsingGET_RAW_URL = function() {
  return '/specialty/{id}'
}
export const getSpecialtyUsingGET_TYPE = function() {
  return 'get'
}
export const getSpecialtyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteSpecialty
 * request: deleteSpecialtyUsingDELETE
 * url: deleteSpecialtyUsingDELETEURL
 * method: deleteSpecialtyUsingDELETE_TYPE
 * raw_url: deleteSpecialtyUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteSpecialtyUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/specialty/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSpecialtyUsingDELETE_RAW_URL = function() {
  return '/specialty/{id}'
}
export const deleteSpecialtyUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteSpecialtyUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/specialty/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateSustainableDevelopmentGoal
 * request: createOrUpdateSustainableDevelopmentGoalUsingPOST
 * url: createOrUpdateSustainableDevelopmentGoalUsingPOSTURL
 * method: createOrUpdateSustainableDevelopmentGoalUsingPOST_TYPE
 * raw_url: createOrUpdateSustainableDevelopmentGoalUsingPOST_RAW_URL
 * @param sustainableDevelopmentGoalEditDto - sustainableDevelopmentGoalEditDTO
 */
export const createOrUpdateSustainableDevelopmentGoalUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['sustainableDevelopmentGoalEditDto'] !== undefined) {
    body = parameters['sustainableDevelopmentGoalEditDto']
  }
  if (parameters['sustainableDevelopmentGoalEditDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sustainableDevelopmentGoalEditDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateSustainableDevelopmentGoalUsingPOST_RAW_URL = function() {
  return '/sustainable-development-goal/'
}
export const createOrUpdateSustainableDevelopmentGoalUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateSustainableDevelopmentGoalUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllSustainableDevelopmentGoals
 * request: getAllSustainableDevelopmentGoalsUsingGET
 * url: getAllSustainableDevelopmentGoalsUsingGETURL
 * method: getAllSustainableDevelopmentGoalsUsingGET_TYPE
 * raw_url: getAllSustainableDevelopmentGoalsUsingGET_RAW_URL
 */
export const getAllSustainableDevelopmentGoalsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/public/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllSustainableDevelopmentGoalsUsingGET_RAW_URL = function() {
  return '/sustainable-development-goal/public/all'
}
export const getAllSustainableDevelopmentGoalsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllSustainableDevelopmentGoalsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/public/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchSustainableDevelopmentGoal
 * request: searchSustainableDevelopmentGoalUsingPOST
 * url: searchSustainableDevelopmentGoalUsingPOSTURL
 * method: searchSustainableDevelopmentGoalUsingPOST_TYPE
 * raw_url: searchSustainableDevelopmentGoalUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchSustainableDevelopmentGoalUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSustainableDevelopmentGoalUsingPOST_RAW_URL = function() {
  return '/sustainable-development-goal/search'
}
export const searchSustainableDevelopmentGoalUsingPOST_TYPE = function() {
  return 'post'
}
export const searchSustainableDevelopmentGoalUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchSustainableDevelopmentGoalCount
 * request: searchSustainableDevelopmentGoalCountUsingPOST
 * url: searchSustainableDevelopmentGoalCountUsingPOSTURL
 * method: searchSustainableDevelopmentGoalCountUsingPOST_TYPE
 * raw_url: searchSustainableDevelopmentGoalCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchSustainableDevelopmentGoalCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchSustainableDevelopmentGoalCountUsingPOST_RAW_URL = function() {
  return '/sustainable-development-goal/search/count'
}
export const searchSustainableDevelopmentGoalCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchSustainableDevelopmentGoalCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSustainableDevelopmentGoal
 * request: getSustainableDevelopmentGoalUsingGET
 * url: getSustainableDevelopmentGoalUsingGETURL
 * method: getSustainableDevelopmentGoalUsingGET_TYPE
 * raw_url: getSustainableDevelopmentGoalUsingGET_RAW_URL
 * @param id - id
 */
export const getSustainableDevelopmentGoalUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSustainableDevelopmentGoalUsingGET_RAW_URL = function() {
  return '/sustainable-development-goal/{id}'
}
export const getSustainableDevelopmentGoalUsingGET_TYPE = function() {
  return 'get'
}
export const getSustainableDevelopmentGoalUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteSustainableDevelopmentGoal
 * request: deleteSustainableDevelopmentGoalUsingDELETE
 * url: deleteSustainableDevelopmentGoalUsingDELETEURL
 * method: deleteSustainableDevelopmentGoalUsingDELETE_TYPE
 * raw_url: deleteSustainableDevelopmentGoalUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteSustainableDevelopmentGoalUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/sustainable-development-goal/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSustainableDevelopmentGoalUsingDELETE_RAW_URL = function() {
  return '/sustainable-development-goal/{id}'
}
export const deleteSustainableDevelopmentGoalUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteSustainableDevelopmentGoalUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/sustainable-development-goal/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * test
 * request: testUsingGET
 * url: testUsingGETURL
 * method: testUsingGET_TYPE
 * raw_url: testUsingGET_RAW_URL
 */
export const testUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/test'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const testUsingGET_RAW_URL = function() {
  return '/test'
}
export const testUsingGET_TYPE = function() {
  return 'get'
}
export const testUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/test'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateTrailBadge
 * request: createOrUpdateTrailBadgeUsingPOST
 * url: createOrUpdateTrailBadgeUsingPOSTURL
 * method: createOrUpdateTrailBadgeUsingPOST_TYPE
 * raw_url: createOrUpdateTrailBadgeUsingPOST_RAW_URL
 * @param trailBadgeEditDto - trailBadgeEditDTO
 */
export const createOrUpdateTrailBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['trailBadgeEditDto'] !== undefined) {
    body = parameters['trailBadgeEditDto']
  }
  if (parameters['trailBadgeEditDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trailBadgeEditDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateTrailBadgeUsingPOST_RAW_URL = function() {
  return '/trail-badge/'
}
export const createOrUpdateTrailBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateTrailBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicTrailBadge
 * request: searchPublicTrailBadgeUsingPOST
 * url: searchPublicTrailBadgeUsingPOSTURL
 * method: searchPublicTrailBadgeUsingPOST_TYPE
 * raw_url: searchPublicTrailBadgeUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPublicTrailBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/public/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicTrailBadgeUsingPOST_RAW_URL = function() {
  return '/trail-badge/public/search'
}
export const searchPublicTrailBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicTrailBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/public/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPublicTrailBadgeCount
 * request: searchPublicTrailBadgeCountUsingPOST
 * url: searchPublicTrailBadgeCountUsingPOSTURL
 * method: searchPublicTrailBadgeCountUsingPOST_TYPE
 * raw_url: searchPublicTrailBadgeCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPublicTrailBadgeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/public/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPublicTrailBadgeCountUsingPOST_RAW_URL = function() {
  return '/trail-badge/public/search/count'
}
export const searchPublicTrailBadgeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPublicTrailBadgeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/public/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPublicTrailBadge
 * request: getPublicTrailBadgeUsingGET
 * url: getPublicTrailBadgeUsingGETURL
 * method: getPublicTrailBadgeUsingGET_TYPE
 * raw_url: getPublicTrailBadgeUsingGET_RAW_URL
 * @param id - id
 */
export const getPublicTrailBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/public/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPublicTrailBadgeUsingGET_RAW_URL = function() {
  return '/trail-badge/public/{id}'
}
export const getPublicTrailBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const getPublicTrailBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/public/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchTrailBadge
 * request: searchTrailBadgeUsingPOST
 * url: searchTrailBadgeUsingPOSTURL
 * method: searchTrailBadgeUsingPOST_TYPE
 * raw_url: searchTrailBadgeUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchTrailBadgeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTrailBadgeUsingPOST_RAW_URL = function() {
  return '/trail-badge/search'
}
export const searchTrailBadgeUsingPOST_TYPE = function() {
  return 'post'
}
export const searchTrailBadgeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchTrailBadgeCount
 * request: searchTrailBadgeCountUsingPOST
 * url: searchTrailBadgeCountUsingPOSTURL
 * method: searchTrailBadgeCountUsingPOST_TYPE
 * raw_url: searchTrailBadgeCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchTrailBadgeCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchTrailBadgeCountUsingPOST_RAW_URL = function() {
  return '/trail-badge/search/count'
}
export const searchTrailBadgeCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchTrailBadgeCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrailBadge
 * request: getTrailBadgeUsingGET
 * url: getTrailBadgeUsingGETURL
 * method: getTrailBadgeUsingGET_TYPE
 * raw_url: getTrailBadgeUsingGET_RAW_URL
 * @param id - id
 */
export const getTrailBadgeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrailBadgeUsingGET_RAW_URL = function() {
  return '/trail-badge/{id}'
}
export const getTrailBadgeUsingGET_TYPE = function() {
  return 'get'
}
export const getTrailBadgeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteTrailBadge
 * request: deleteTrailBadgeUsingDELETE
 * url: deleteTrailBadgeUsingDELETEURL
 * method: deleteTrailBadgeUsingDELETE_TYPE
 * raw_url: deleteTrailBadgeUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteTrailBadgeUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trail-badge/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteTrailBadgeUsingDELETE_RAW_URL = function() {
  return '/trail-badge/{id}'
}
export const deleteTrailBadgeUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteTrailBadgeUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trail-badge/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUsers
 * request: getUsersUsingGET
 * url: getUsersUsingGETURL
 * method: getUsersUsingGET_TYPE
 * raw_url: getUsersUsingGET_RAW_URL
 * @param query - query
 */
export const getUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/users'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsersUsingGET_RAW_URL = function() {
  return '/users'
}
export const getUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/users'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}