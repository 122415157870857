<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            {{ this.$route.params.id ? 'Edytuj kategorię' : 'Dodaj kategorię' }}
        </h1>
        <VeeForm v-if="loaded" @submit="saveCategory" v-slot="{ errors }">
            <div class="category-form grid">
                <div class="col-12 grid">
                    <CustomInputText v-model="category.name"
                                     class="md:col-6 col-12 category-input" label="nazwa" name="name" rules="required"/>
                </div>
                <div class="col-12 grid">
                    <CustomSelectOneCategoryType v-model="this.category.categoryType"
                                                 class="md:col-6 col-12 category-input" label="typ kategorii"
                                                 name="type" rules="required"/>
                </div>
                <div class="col-12 grid">
                    <CustomInputTextArea v-model="this.category.description"
                                         class="md:col-6 col-12 category-input" label="opis kategorii"
                                         name="description"/>
                </div>
                <div class="col-12 grid">
                    <CustomSelectOneFile v-model="category.coverImage" class="md:col-6 col-12" label="grafika"
                                         name="file"/>
                </div>
                <div class="col-12 grid">
                    <div class="md:col-6 col-12">
                        <Toolbar class="actions">
                            <template #end>
                                <PrimeButton :disabled="isDisabled(errors)" icon="pi pi-check" label="Zapisz"
                                             type="submit"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createCategoryUsingPOST as createCategory,
        editCategoryUsingPUT as editCategory,
        getCategoryByIdUsingGET as getCategory,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import GoBack from "@/components/GoBack";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {StatusCodes} from "@/utils/StatusCodes";
    import CustomSelectOneFile from "@/components/form/CustomSelectOneFile";
    import CustomSelectOneCategoryType from "@/components/form/CustomSelectOneCategoryType";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "CategoryFormView",

        components: {
            PrimeButton,
            Toolbar,
            CustomInputText,
            Toast,
            VeeForm,
            CustomSelectOneFile,
            CustomSelectOneCategoryType,
            CustomInputTextArea,
            GoBack,
        },
        data() {
            return {
                category: {
                    id: null,
                    name: null,
                    coverImage: null,
                    categoryType: null,
                },
                loaded: false,
                categoryType: [{name: "Sprawność zuchowa", type: "CUB_MERIT_BADGE"},
                               {name: "Sprawność harcerska", type: "SCOUT_MERIT_BADGE"},
                               {name: "Trop zuchowy", type: "CUB_TRAIL_BADGE"},
                               {name: "Trop harcerski", type: "SCOUT_TRAIL_BADGE"},
                ],
            };
        },
        updated() {
            this.category.id = this.$route.params.id;
        },
        beforeMount() {
            if (this.$route.params.id) {
                getCategory({id: this.$route.params.id}).then((response) => {
                    this.category = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },
        methods: {
            async saveCategory() {
                if (this.$route.params.id) {
                    try {
                        await editCategory({categoryRequest: this.category});
                        this.notifySuccess();
                        this.$router.push({name: "categoryList"});
                    } catch (error) {
                        this.notifyError(error);
                    }
                } else {
                    try {
                        await createCategory({categoryRequest: this.category});
                        this.notifySuccess();
                        // Dajemy czas na zobaczenie powiadomienia przed przekierowaniem
                        // eslint-disable-next-line no-promise-executor-return
                        await new Promise((r) => setTimeout(r, 2000));
                        this.$router.push({name: "categoryList"});
                    } catch (error) {
                        this.notifyError(error);
                    }
                }
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            notifyError(error) {
                switch (error.response.status) {
                    case StatusCodes.CONFLICT:
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Kategoria o takiej nazwie już istnieje",
                            life: 3000,
                        });
                        break;
                    default:
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać danych",
                            life: 3000,
                        });
                }
            },
            notifySuccess() {
                this.$toast.add({
                    severity: "success",
                    summary: "Sukces",
                    detail: "Kategoria została zapisana",
                    life: 3000,
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .category-form {
        width: 80%;
        max-width: 800px;
    }
</style>
