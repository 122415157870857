<template>
    <div>
        <DataTable ref="externalFileTable" :value="externalFiles" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column header="podgląd" headerStyle="margin: auto" >
                <template #body="slotProps">
                    <a :href="slotProps.data.fileUrl" target="_blank">
                        <img :alt="slotProps.data.name" style="max-height: 100px"
                             :src="slotProps.data.fileUrl"/>
                    </a>
                </template>
            </Column>
            <Column field="name" header="nazwa" :sortable="true"/>
            <Column field="externalSystemModificationDate" header="data modyfikacji" :sortable="true"/>
            <Column field="id" header="id" :sortable="true"/>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        searchExternalFileCountUsingPOST as searchExternalFileCount,
        searchExternalFileUsingPOST as searchExternalFile,
    } from "@/swagger/vue-api-client";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "../../../utils/SearchCriteria";

    export default {
        name: "ExternalFileTable",
        components: {
            Column, DataTable,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                externalFiles: [],
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchExternalFile({searchCriteria: this.searchCriteria}).then((response) => {
                    this.externalFiles = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchExternalFileCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                    sortOrder: this.searchCriteria.page.sortOrder,
                };
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
