/* eslint-disable */
export const ScrollUtils = {

    scrollToField(fieldId) {
        const element = document.getElementById(fieldId);
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
    },

}
