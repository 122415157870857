/* eslint-disable */
import axios from 'axios'

export const FileUtils = {

    forceFileDownload(response, fileName) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    downloadFile(query, fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'post',
            responseType: 'blob',
            data: query,
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },

    downloadFileUsingGET(fileName, url) {
        axios({
            url: process.env.VUE_APP_API_URL + url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            this.forceFileDownload(response, fileName);
        });
    },

    async downloadFileAsBlob(ctx, url, fileName) {
        await ctx.$http.get(url, {responseType: 'arraybuffer'}).then(
            response => {
                window.console.log(response);
                let blob = new Blob([response.data], {type: response.headers['content-type']});
                if (!!window.MSInputMethodContext && !!document.documentMode) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            },
            error => {
                if (error.status === 403) ctx.$router.push("/forbidden");
            }
        );
    },

    async fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },

    removeExtensionFromFilename(filename) {
        return filename.substring(0, filename.lastIndexOf('.')) || filename;
    }
};
