<template>
    <div class="labels-list flex" :class="{showAllLabels: showAllLabels}" v-if="labels && labels.length">
        <div class="labels-list-inner flex align-items-center gap-3 flex-wrap">
            <div v-for="label in labels" :key="label.id">
                <LabelSingle :labelData="label" @select="listenerSelectLabel" :selectedLabels="selectedLabels"/>
            </div>
        </div>
        <div v-if="isTooMuchLabels">
            <div v-if="selectedLabels.length > 0">
                <i class="pi pi-angle-down" @click="showAllLabelsToggle" @keyup.enter="showAllLabelsToggle"
                   v-if="!showAllLabels" v-badge="selectedLabels.length"></i>
                <i class="pi pi-angle-up"  @click="showAllLabelsToggle" @keyup.enter="showAllLabelsToggle"
                   v-else v-badge="selectedLabels.length"></i>
            </div>
            <div v-else>
                <i class="pi pi-angle-down" @click="showAllLabelsToggle" @keyup.enter="showAllLabelsToggle"
                   v-if="!showAllLabels"></i>
                <i class="pi pi-angle-up"  @click="showAllLabelsToggle" @keyup.enter="showAllLabelsToggle"
                   v-else></i>
            </div>
        </div>
    </div>
</template>

<script>

    import BadgeDirective from "primevue/badgedirective";
    import {
        getActiveLabelsByBadgeTypeAndAgeGroupUsingGET as getActiveLabelsByBadgeTypeAndAgeGroup,
        getAllActiveLabelsUsingGET as getAllActiveLabels,
    } from "@/swagger/vue-api-client";
    import LabelSingle from "./LabelSingle";

    const badge = BadgeDirective;

    export default {
        name: "LabelsList",
        components: {LabelSingle},
        directives: {
            badge,
        },

        props: {
            modelValue: null,
            badgeType: {
                type: String,
                default: null,
                required: false,
            },
            ageGroupName: {
                type: String,
                default: null,
                required: false,
            },
        },
        data() {
            return {
                showAllLabels: false,
                isTooMuchLabels: false,
                labels: null,
                loaded: false,
            };
        },
        emits: ["update:modelValue"],

        beforeMount() {
            this.getLabels();
            this.loaded = true;
        },

        mounted() {
            if (document.querySelector(".labels-list-inner")
                && document.querySelector(".labels-list-inner").scrollHeight > 40) {
                this.isTooMuchLabels = true;
            } else {
                this.isTooMuchLabels = false;
            }
        },
        computed: {
            selectedLabels() {
                return this.internalValue;
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        watch: {
            selectedLabels: {
                handler() {
                    if (this.selectedLabels.length === 0) {
                        this.clearSelectedLabels();
                    }
                },
                deep: true,
            },
        },

        methods: {
            listenerSelectLabel(id) {
                if (this.selectedLabels.includes(id)) {
                    const index = this.selectedLabels.indexOf(id);
                    this.selectedLabels.splice(index, 1);
                    this.internalValue = this.selectedLabels;
                } else {
                    this.selectedLabels.push(id);
                    this.internalValue = this.selectedLabels;
                }
            },
            showAllLabelsToggle() {
                this.showAllLabels = !this.showAllLabels;
            },
            clearSelectedLabels() {
                this.selectedLabels = [];
            },
            getLabels() {
                if (!this.badgeType) {
                    getAllActiveLabels().then((response) => {
                        this.labels = response.data;
                    });
                    return;
                }
                const groupName = this.ageGroupName ? this.ageGroupName : this.$route.params.groupName;
                getActiveLabelsByBadgeTypeAndAgeGroup({ageGroupName: groupName, badgeType: this.badgeType})
                    .then((response) => {
                        this.labels = response.data;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
    .labels-list-inner {
        max-height: 38px;
        transition: max-height .4s ease-in-out;
        overflow: hidden;
        max-width: 1100px;
    }
    .showAllLabels {
        .labels-list-inner {
            max-height: 800px;
        }
    }
    .pi-angle-down,
    .pi-angle-up {
        cursor: pointer;
    }
</style>
<style lang="less">
    .labels-list {
        .p-overlay-badge .p-badge {
            right: -6px;
        }
    }
</style>
