<template>
    <div>
        <DataTable ref="userTable" :value="users" :lazy="true" :paginator="true" v-model:rows="searchParams.page.limit"
                   v-model:first="searchParams.page.offset"
                   removableSort :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="e-mail" :sortable="true"></Column>
            <Column field="systemRole" header="Rola" headerStyle="width: 200px;">
                <template #body="slotProps">
                    <CustomBadge label="Administrator" color="primary" v-show="slotProps.data.systemRole === 'ADMIN'"/>
                    <CustomBadge label="Moderator" color="secondary"
                                 v-show="slotProps.data.systemRole === 'MODERATOR'"/>
                    <CustomBadge label="Użytkownik" color="tertiary" v-show="slotProps.data.systemRole === 'USER'"/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchUserCountUsingPOST as searchUserCount,
        searchUserUsingPOST as searchUser,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomBadge from "../../../components/CustomBadge";
    import {SearchCriteria} from "../../../utils/SearchCriteria";

    export default {
        name: "UserTable",
        components: {
            CustomBadge, Column, DataTable,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        data() {
            return {
                loading: false,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
            };
        },

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;

                searchUser({searchCriteria: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchUserCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                    sortOrder: this.searchCriteria.page.sortOrder,
                };
            },
        },

    };
</script>

<style scoped>

</style>
