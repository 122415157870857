<template>
    <div class="grid">
        <div class="md:col-4 col-12">
            <CustomInputText name="name" label="nazwa" v-model="internalValue.name"/>
        </div>
        <div class="md:col-4 col-12">
            <CustomSelectOneCategoryType class="category-input"
                                         label="typ kategorii" v-model="internalValue.categoryType"
                                         rules="required" name="type"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneCategoryType from "@/components/form/CustomSelectOneCategoryType";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "CategorySearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText, CustomSelectOneCategoryType},
    };
</script>

<style lang="less" scoped>

</style>
