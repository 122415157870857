<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-bookmark-fill"></i> Lista stopni</h1>

        <div class="search-criteria" @keyup.enter="search">
            <RankSearchCriteria ref="rankSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <div class="flex gap-3">
                        <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                        <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj stopień" icon="pi pi-plus" @click="createRank"/>
            </template>
        </Toolbar>

        <RankTable ref="rankTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import RankSearchCriteria from "./components/RankSearchCriteria";
    import RankTable from "./components/RankTable";

    export default {
        name: "RankListView",
        components: {
            RankTable, RankSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.rankTable.search();
            },

            createRank() {
                this.$router.push({name: "rankCreate"});
            },

            async clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: "id",
                        sortOrder: 1,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
