/* eslint-disable */
import authAzureService from "@/auth-azure.service";

export const SystemRole = {
    ADMIN: "admin",
    MODERATOR: "moderator",

    isAdmin() {
        return authAzureService?.hasRole(SystemRole.ADMIN);
    },

    isModerator() {
        return authAzureService?.hasRole(SystemRole.MODERATOR);
    },

    isAdminOrModerator() {
        return SystemRole.isModerator() || SystemRole.isAdmin();
    },

    getUserRoles() {
        return authAzureService?.getUserRoles();
    },

};
