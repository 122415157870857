<template>
    <div>
        <div v-html="trailBadge.introductionText" class="trail-badge-description"></div>
        <div class="my-7 flex">
            <div class="font-bold mr-3">{{ $t("message.headers.service") }}:</div>
            <div v-if="trailBadge.duty">tak</div>
            <div v-else>nie</div>
        </div>
        <TrailBadgeSustainableDevelopmentGoals
            :sustainable-development-goals="trailBadge?.sustainableDevelopmentGoals" />
    </div>
</template>

<script>

    import TrailBadgeSustainableDevelopmentGoals
        from "@/views/trailBadge/components/TrailBadgeSustainableDevelopmentGoals";

    export default {
        name: "TrailBadgePublicRealizationPlan",
        components: {TrailBadgeSustainableDevelopmentGoals},

        props: {
            trailBadge: {
                type: Object,
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
