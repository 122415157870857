<template>
    <span class="go-back relative mb-3">
        <PrimeButton label="Powrót" icon="pi pi-arrow-left"
                     @click="$router.back()" @keyup.enter="$router.back()"/>
    </span>
</template>

<script>
    import PrimeButton from "primevue/button";

    export default {
        components: {PrimeButton},
        name: "GoBack",
    };
</script>

<style lang="less">
    @import "@/assets/theme/variable.less";

    .go-back {
        z-index: 4;
        @media only screen and (max-width: @media-breakpoint-down-lg) {
            z-index: 0;
            button {
                margin-top: 60px;
            }
        }
        @media only screen and (max-width: @media-breakpoint-down-md) {
            button {
                margin-top: 0;
            }
        }
    }
</style>
