<template>
    <div class="custom-multi-select">
        <label v-if="showLabel" class="multiselect-label" :for="name">{{ label }}</label>
        <MultiSelect :id="name" :options="internalItems" :optionLabel="itemLabel" :optionValue="itemValue"
                     v-model="internalValue" :scrollHeight="scrollHeight" appendTo="self"/>
    </div>
</template>

<script>
    import MultiSelect from "primevue/multiselect";

    export default {
        name: "CustomMultiSelect",
        props: {
            showLabel: {
                type: Boolean,
                default: true,
            },
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            items: {
                type: Array,
            },
            scrollHeight: {
                type: String,
                default: null,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                internalItems: this.items,
            };
        },

        mounted() {
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {MultiSelect},
    };
</script>

<style lang="less" scoped>
    @import "../../../assets/theme/variable";
    .custom-multi-select {
        margin-bottom: 10px;
        position: relative;

        label {
            margin-left: 0.5em;
            font-weight: 300;
            color: @color-black-trans-70;
            font-size: @label-font-size;
        }

        .p-multiselect {
            width: 100%;
            border-radius: 0;
            margin-top: .5em;
        }
    }
</style>
