<template>
    <router-link :to="{name: instrument.instrumentType,
                       params: { instrumentId: instrument.id }}"
                 class="basic-card"
                 :class="[{withoutPhoto: !instrument.coverUrl},
                          {'flex flex-column md:flex-row md:col-12 gap-0 md:gap-5 align-items-center' : isListView}]">
        <LazyImageItem
            :key="instrument.coverUrl"
            class="imageItem"
            v-if="instrument.coverUrl"
            :source="instrument.coverUrl"
            :alt="instrument.instrumentName"/>
        <div v-if="optionView === 'cards'" class="basic-card-desc">
            <h2>{{ instrumentName }}</h2>
            <div v-if="instrument.instrumentName" class="basic-card-type">{{ instrument.instrumentName }}</div>
        </div>
        <template v-if="optionView === 'list' && instrument.instrumentDesc">
            <h2 v-html="instrument.instrumentDesc"></h2>
        </template>
    </router-link>
</template>

<script>
    import LazyImageItem from "@/components/data/LazyImageItem";

    export default {
        name: "BasicCard",
        components: {LazyImageItem},

        props: {
            instrument: {
                type: Object,
            },
            optionView: {
                type: String,
                default: "cards",
            },
        },

        computed: {
            instrumentName() {
                if (!this.instrument.femaleName) {
                    return this.instrument.name;
                }
                return (this.instrument.name && this.instrument.name !== this.instrument.femaleName)
                    ? (this.instrument.name + " / " + this.instrument.femaleName) : this.instrument.name;
            },
            isListView() {
                if (this.optionView === "list") {
                    return true;
                }
                return false;
            },
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less">
    @import "@/assets/theme/variable.less";

    .basic-card {
        border: 1px solid @color-gray;
        text-align: center;
        height: 100%;
        display: block;
        text-decoration: none;
        &.withoutPhoto {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        img {
            padding: 35px 20px 25px;
        }
        h2 {
            padding: 0 10px;
            margin-top: 0;
            margin-bottom: 25px;
        }
        .basic-card-type {
            font-size: 20px;
        }
        @media only screen and (max-width: @media-breakpoint-down-lg) {
            img {
                padding: 0;
            }
            h2 {
                font-size: 22px;
            }
        }
        @media only screen and (max-width: @media-breakpoint-down-md) {
            figure {
                margin: 20px 35px;
            }
        }
        @media only screen and (max-width: @media-breakpoint-down-xs) {
            h2 {
                padding: 0 4px;
                font-size: 16px;
                line-height: 1.3;
            }
        }
    }
    .list {
        .basic-card {
            border: none;
            padding: 30px 0;
            border-bottom: 1px solid @color-gray;
            text-decoration: none;
            h2 {
                font-weight: 300;
                text-align: left;
                margin: 0;
                padding: 0;
            }
            figure {
                flex-shrink: 0;
                margin-inline: 0;
                img {
                    width: 100px;
                    padding: 0;
                }
            }
        }
    }
    figure.imageWrapper {
        opacity: 0;
    }
    figure.imageWrapper.loaded {
        animation-name: fadein;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-direction: normal;
        animation-timing-function: ease-out;
        @keyframes fadein {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
</style>
