<template>
    <div class="mb-6">
        <div class="font-bold mb-3">{{ $t("message.headers.realizationPlan") }}:</div>
        <div>{{ $t("message.headers.realizationPlanSubHeader") }}</div>
        <ol class="realization-plan-list list-with-big-counter">
            <li class="mb-4">
                <div>
                    <div class="font-bold mb-2">Zaplanujcie, czyli zbierzcie potrzebne informacje,
                        ustalcie, co chcecie zrobić i podzielcie się obowiązkami:</div>
                    <div v-html="trailBadge.planText"></div>
                </div>
            </li>
            <li class="mb-4">
                <div>
                    <div class="font-bold mb-2">Zróbcie, wykonajcie zadanie wedle własnego pomysłu, w którym
                        każdy członek zastępu ma swoją rolę do odegrania:</div>
                    <div v-html="trailBadge.toDoText" class="list-with-checkbox-outer"></div>
                </div>
            </li>
            <li class="mb-4">
                <div>
                    <div class="font-bold mb-2">Podsumujcie, oceńcie w swoim gronie czy
                        udało wam się osiągnąć założone cele i czy każdy dołożył się do ich realizacji,
                        a następnie pochwalcie się swoimi osiągnięciami.</div>
                    <div v-html="trailBadge.summaryText"></div>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>

    export default {
        name: "TrailBadgePublicRealizationPlan",

        props: {
            trailBadge: {
                type: Object,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {},

        methods: {
        },
    };
</script>
