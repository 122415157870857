<template>
    <div class="merit-badge-task-edit mt-3">
        <div class="flex-box  justify-content-between">
            <h4 style="display: inline;" class="header">Wskazówki bibliograficzne</h4>
            <div class="my-4">
                <PrimeButton label="Dodaj wskazówkę bibliograficzną" icon="pi pi-plus" class="" @click="newTask"/>
            </div>
        </div>
        <DataTable :value="value" :dataKey="id" class="editable-cells-table mb-4" responsiveLayout="scroll"
                   @rowReorder="onRowReorder">
            <template #empty>
                Brak wskazówek bibliograficznych
            </template>
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="label" header="etykieta" bodyClass="align-cell-baseline">
                <template #body="{ data, field, index }">
                    <CustomInputText v-model="data[field]" style="width: 100%"
                                     :name="index + field"
                                     rules="required"/>
                </template>
            </Column>
            <Column field="link" header="link" bodyClass="align-cell-baseline">
                <template #body="{ data, field, index }">
                    <CustomInputText v-model="data[field]" style="width: 100%"
                                     :name="index + field"
                                     rules="required|url"/>
                </template>
            </Column>
            <Column headerStyle="width: 5rem" header="usuń">
                <template #body="{ index }">
                    <PrimeButton type="button" icon="pi pi-trash" @click="deleteTask(index)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>

    import DataTable from "primevue/datatable";
    import PrimeButton from "primevue/button";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOneLevel from "@/components/form/CustomSelectOneLevel";
    import CustomSelectOneRank from "@/components/form/CustomSelectOneRank";

    export default {
        name: "TrailBadgeBibliographicHintsList",
        components: {
            DataTable,
            PrimeButton,
            Column,
            CustomInputText,
            CustomSelectOneLevel,
            CustomSelectOneRank,
        },
        props: {
            modelValue: Array,
        },
        emits: ["update:modelValue"],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        methods: {
            onRowReorder(event) {
                this.value = event.value;
            },
            deleteTask(i) {
                this.value = this.value.filter((el, index) => index !== i);
            },
            newTask() {
                this.value = [...this.value, {text: "", level: ""}];
            },
        },
    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable.less";
    .merit-badge-task-edit {
        color: @color-black-trans-70;
        .align-cell-baseline {
            vertical-align: baseline;
        }
    }
</style>
