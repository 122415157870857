<template>
    <div class="sustainable-development-goal mb-7">
        <div class="font-bold mb-3">{{ sustainableDevelopmentGoals?.length > 1 ?
            $t("message.headers.sustainableDevelopmentGoals") :
            $t("message.headers.sustainableDevelopmentGoal") }}:</div>
        <div v-for="sdg in sustainableDevelopmentGoals" :key="sdg.id"
             class="flex align-items-center gap-5">
            <img :src="sdg.coverUrl" alt="cel zrównoważonego rozwoju">
            <div class="sustainable-development-goal-desc">{{ sustainableDevelopmentGoalLabel(sdg) }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TrailBadgeSustainableDevelopmentGoals",
        methods: {
            sustainableDevelopmentGoalLabel(sdg) {
                if (!sdg) {
                    return "";
                }
                return "Cel "
                    + sdg.pointNumber
                    + ": "
                    + sdg.name;
            },
        },
        props: {
            sustainableDevelopmentGoals: {
                type: Array,
                default: null,
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../../assets/theme/variable";

    .trail-badge-description {
        color: @color-purple-scout;
        font-weight: 700;
    }

    .sustainable-development-goal {
        img {
            width: 100px;
        }

        .sustainable-development-goal-desc {
            font-size: 20px;
        }
    }
</style>
