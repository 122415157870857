<template>
    <div class="review-list">
        <template v-if="loaded">
            <div v-if="reviews">
                <h4 style="display: inline;" class="header">Recenzje</h4>
                <Timeline :value="reviews" align="alternate" >
                    <template #content="slotProps">
                        <Card>
                            <template #title>
                                {{slotProps.item.reviewer}}
                            </template>
                            <template #subtitle>
                                {{displayDate(slotProps.item.creationDate)}}
                            </template>
                            <template #content>
                                <p v-html="slotProps.item.reviewText"/>
                            </template>
                        </Card>
                    </template>
                </Timeline>
            </div>
            <div v-else>
                Brak recenzji
            </div>
        </template>
        <div v-else>
            Wczytywanie recenzji...
        </div>
    </div>
    <AddReviewDialog :instrument-type="instrumentType"
                     :instrument-id="instrumentId"
                     class="mt-5"
                     @review-saved="onReviewSaved"/>
</template>

<script>
    import Timeline from "primevue/timeline";
    import Card from "primevue/card";
    import {DateUtils} from "@/utils/DateUtils";
    import AddReviewDialog from "@/components/AddReviewDialog";
    import {getReviewsForInstrumentUsingGET as getReviews} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ReviewListComponent",
        components: {Timeline, Card, AddReviewDialog},
        props: {
            instrumentType: {
                type: String,
                default: null,
            },
            instrumentId: {
                type: Number,
                default: null,
            },
            initialReviews: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                loaded: true,
                reviews: [],
            };
        },
        beforeMount() {
            this.reviews = this.initialReviews;
        },
        methods: {
            displayDate(date) {
                return DateUtils.displayDateTimeInPolishFormat(date);
            },
            async onReviewSaved() {
                try {
                    await getReviews(
                        {instrumentType: this.instrumentType, instrumentId: this.instrumentId},
                    ).then((response) => {
                        this.reviews = response.data;
                    });
                } catch (error) {
                    ToastUtils.showError(
                        this,
                        "Błąd!",
                        "Wystąpił błąd przy odczycie recenzji z bazy danych",
                    );
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";
    .review-list {
        font-size: @label-font-size;
        padding-top: 10px;
        border-top: 1px solid #eee;
        padding-top: 20px;
        .header {
            color: @color-black-trans-70;
            font-size: 25px;
        }
    }
</style>
