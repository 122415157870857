<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-chart-pie"></i> Lista obszarów rozwoju </h1>

        <div class="search-criteria" @keyup.enter="search">
            <DevelopmentAreaSearchCriteria ref="developmentAreaSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj nowy obszar rozwoju" icon="pi pi-plus" @click="createDevelopmentArea"/>
            </template>
        </Toolbar>

        <DevelopmentAreaTable ref="developmentAreaTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import DevelopmentAreaSearchCriteria from "./components/DevelopmentAreaSearchCriteria";
    import DevelopmentAreaTable from "./components/DevelopmentAreaTable";

    export default {
        name: "DevelopmentAreaListView",
        components: {
            DevelopmentAreaTable, DevelopmentAreaSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.developmentAreaTable.search();
            },

            createDevelopmentArea() {
                this.$router.push({name: "developmentAreaCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
