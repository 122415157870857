import {createApp, h} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {createI18n} from "vue-i18n";
import AuthAzureService from "@/auth-azure.service";
import router from "./router";
import store from "./store";
import App from "./App";
import {setDomain} from "./swagger/vue-api-client";
import "./assets/theme/theme.less";
import {messagesEN} from "./messages/messages_en";
import {messagesPL} from "./messages/messages_pl";
import "./validators";
import LazyLoadDirective from "./directives/LazyLoadDirective";

setDomain(process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const loggedIn = AuthAzureService.isLoggedIn();
        if (!loggedIn) {
            sessionStorage.setItem("redirectPath", to.path);
            AuthAzureService.appSignIn();
        } else {
            const userRoles = AuthAzureService.getUserRoles();
            if (to.meta.roles.some((r) => userRoles.includes(r))) {
                next();
            } else {
                // użytkownik nie ma odpowiedniej roli aby wejść na daną stronę
                next({name: "forbidden"});
            }
        }
    } else {
        next();
    }
});

const messages = {
    EN: {
        message: messagesEN,
    },
    PL: {
        message: messagesPL,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "PL",
    messages,
});

const app = createApp({
    i18n,
    render: () => h(App),
    beforeCreate() {
        AuthAzureService.init();
    },
    async mounted() {
        const redirectPath = sessionStorage.getItem("redirectPath");
        if (AuthAzureService.isLoggedIn() && redirectPath) {
            // Define function to be used as callback
            const redirectFunc = function () {
                sessionStorage.removeItem("redirectPath");
                router.push(redirectPath);
            };

            if (!AuthAzureService.waitingOnAccessToken) {
                redirectFunc();
            } else {
                // Register callback to be executed when accessToken has been assigned
                AuthAzureService.accessTokenCallbacks.push(redirectFunc);
            }
        }
    },
}).use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(ToastService)
    .use(ConfirmationService)
    .use(i18n)
    .use(PrimeVue)
    .directive("lazyload", LazyLoadDirective)
    .mount("#app");

app.axios.interceptors.request.use(async (config) => {
    if (!AuthAzureService) {
        return config;
    }
    const accessToken = await AuthAzureService.getTokenIfExistsOrFetchFromCache();

    if (accessToken) {
        config.headers.Authorization = "Bearer " + accessToken;
    }

    return config;
});
