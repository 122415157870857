<template>
    <div>
        <Toast />
        <DataTable ref="scoutLawTable" :value="rules" :lazy="true" :paginator="false"
                   :totalRecords="totalRecords" :loading="loading" @row-reorder="onRowReorder" editMode="row"
                   v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" >
            <Column :rowReorder="true" headerStyle="width: 3em" />
            <Column header="punkt" headerStyle="width:5em">
                <template #body="slotProps">
                    {{slotProps.index + 1}}
                </template>
            </Column>
            <Column field="rule" header="tytuł" >
                <template #editor="slotProps">
                    <CustomInputText v-model="slotProps.data[slotProps.field]" />
                </template>
            </Column>
            <Column :rowEditor="true" bodyStyle="text-align:center; width: 9em"/>
            <Column headerStyle="width: 3rem;" bodyStyle="width: 3rem;">
                <template #body="slotProps">
                    <PrimeButton type="button" icon="pi pi-trash" style="margin-left: 5px"
                                 class="p-button-danger" @click="deleteCategory(slotProps.data.id)">
                    </PrimeButton>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        deleteScoutLawUsingDELETE as deleteScoutLaw,
        searchScoutLawCountUsingPOST as searchScoutLawCount,
        searchScoutLawUsingPOST as searchScoutLaw,
        reorderScoutLawUsingPUT as reorderScoutLaw,
        editScoutLawUsingPUT as editScoutLaw,
    } from "@/swagger/vue-api-client";
    import Toast from "primevue/toast";
    import PrimeButton from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ScoutLawTable",
        components: {
            CustomInputText,
            PrimeButton,
            Column,
            DataTable,
            Toast,
        },
        props: {
            ageGroupType: null,
        },
        data() {
            return {
                loading: false,
                totalRecords: 0,
                rules: [],
                editingRows: [],
                searchCriteria: {
                    ageGroupType: null,
                    page: {
                        offset: 0,
                        sortField: "pointNumber",
                        sortOrder: 1,
                    },
                },
            };
        },

        mounted() {
            this.searchCriteria.ageGroupType = this.ageGroupType;
            this.updateTotalRecords();
            this.onPage();
        },

        methods: {
            onRowReorder(event) {
                this.rules = event.value;
                reorderScoutLaw({order: this.rules});
                // Poinformować o sukcesie/porażce?
            },
            onRowEditSave(event) {
                const {newData, index} = event;
                this.rules[index] = newData;
                editScoutLaw({scoutLawDto: this.rules[index]});
            },
            onPage() {
                this.loading = true;
                searchScoutLaw({searchCriteria: this.searchCriteria}).then((response) => {
                    this.rules = response.data;
                    this.loading = false;
                });
            },
            updateTotalRecords() {
                searchScoutLawCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },
            deleteCategory(scoutLawId) {
                this.$confirm.require({
                    message: "Ta operacja jest nieodwracalna",
                    header: "Czy na pewno chcesz usunąć punkt prawa harcerskiego?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "tak",
                    rejectLabel: "nie",
                    accept: () => {
                        deleteScoutLaw({id: scoutLawId}).then(() => {
                            ToastUtils.showSuccess(
                                this,
                                "Punkt prawa harcerskiego usunięty",
                                "Udało się usunąć prawo harcerskie",
                            );
                        }).catch(() => {
                            ToastUtils.showError(this);
                        });
                    },
                });
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
