/* eslint-disable */
export const messagesEN = {
    login: {
        title: "Sign in",
        login: "Login",
        password: "Password",
        action: {
            login: "Sign in",
            remindPassword: "Remind password",
        },
    },
    menu: {
        users: "Users",
    },
};
