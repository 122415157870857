<template>
    <div class="cub-scout-merit-badge-card-view">
        <table>
            <tr>
                <th class="merit-badge-name">
                    <img v-if="meritBadgeCardData.coverImageUrl"
                         :src="meritBadgeCardData.coverImageUrl" alt="">
                    {{ meritBadgeName }}
                </th>
                <th class="merit-badge-level">
                    <img v-for="star in meritBadgeCardData.numberOfStars" :key="star" src="@/assets/badge-star.png"
                         alt="">
                </th>
            </tr>
            <tr v-for="(task, index) in meritBadgeCardData.tasks" :key="task">
                <td>
                    <div class="cell-inner">
                        <span class="order-item">{{ index + 1 }}.</span>
                        {{ task }}
                    </div>
                </td>
                <td></td>
            </tr>
        </table>
        <div class="row">
            <div class="name">
                <span class="dots"></span>
                <span>imię</span>
            </div>
            <div class="surname">
                <span class="dots"></span>
                <span>nazwisko</span>
            </div>
        </div>
        <div class="row">
            <div class="date">
                <span class="dots"></span>
                <span>data otwarcia</span>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        getMeritBadgeCubScoutBadgeCardDataUsingGET as getMeritBadgeCubScoutBadgeCardData,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CubScoutMeritBadgeCardView",
        data() {
            return {
                meritBadgeCardData: "",
            };
        },
        mounted() {
        },
        beforeMount() {
            getMeritBadgeCubScoutBadgeCardData({id: this.$route.params.id, level: this.$route.params.level})
                .then((response) => {
                    this.meritBadgeCardData = response.data;
                }).catch(() => {
                    ToastUtils.showError(this);
                });
        },
        computed: {
            meritBadgeName() {
                if (!this.meritBadgeCardData.femaleName) {
                    return this.meritBadgeCardData.maleName;
                }
                return (this.meritBadgeCardData.maleName !== this.meritBadgeCardData.femaleName)
                    ? (this.meritBadgeCardData.maleName + " / " + this.meritBadgeCardData.femaleName)
                    : this.meritBadgeCardData.maleName;
            },
        },
    };
</script>

<style lang="less">
    @import "@/assets/theme/variable";

    @media print {
        .cub-scout-merit-badge-card-view {
            margin: 0 10mm;
            overflow: hidden;
            padding-top: 45px;
            table {
                width: 100%;
                font-size: 16px;
                border-collapse: collapse;
                .merit-badge-name {
                    text-transform: uppercase;
                    img {
                        max-width: 80px;
                        margin-top: -45px;
                    }
                }
                .merit-badge-level {
                    width: 120px;
                    img {
                        max-width: 30px;
                    }
                }
                th {
                    background-color: @color-blue;
                    print-color-adjust: exact;
                    color: @color-white;
                }
                th, td {
                    padding: 10px;
                    border: 3px solid @color-blue;
                    break-inside: avoid;
                }
                td {
                    padding-left: 40px;
                    font-weight: 300;
                }
                .order-item {
                    font-size: 18px;
                    color: @color-yellow-dark;
                    margin-right: 4px;
                    display: block;
                    font-weight: bold;
                }
                .cell-inner {
                    display: flex;
                    line-height: 20px;
                }
            }
            .row {
                width: 100%;
                display: flex;
                gap: 10px;
                & > div {
                    flex: 0 1 50%;
                    &.name, &.surname {
                        padding-top: 18px;
                    }
                }
                .date {
                    margin-left: auto;
                }
                span {
                    font-size: 14px;
                    width: 100%;
                    display: block;
                    text-align: center;
                    &.dots {
                        height: 36px;
                        border-bottom-style: dotted;
                        border-color: @color-blue;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
</style>
