<template>
    <PrimeButton :label="labelData.name" class="p-button-rounded p-button-sm pl-4 pr-5 label-single"
                 :class="{active: isActive}"
                 @click="selectLabel(labelData.id)" />
</template>

<script>

    import PrimeButton from "primevue/button";

    export default {
        name: "LabelSingle",
        components: {PrimeButton},

        props: {
            labelData: {
                type: Object,
            },
            selectedLabels: {
                type: Array,
            },
        },

        computed: {
        },

        data() {
            return {
                isActive: false,
            };
        },
        beforeMount() {
        },
        watch: {
            selectedLabels: {
                handler() {
                    if (this.selectedLabels.length) {
                        for (let i = 0; i < this.selectedLabels.length; i += 1) {
                            if (this.selectedLabels[i] === this.labelData.id) {
                                this.isActive = true;
                                break;
                            } else {
                                this.isActive = false;
                            }
                        }
                    } else {
                        this.isActive = false;
                    }
                },
                deep: true,
            },
        },
        mounted() {
        },
        methods: {
            selectLabel(labelId) {
                this.$emit("select", labelId);
            },
        },

    };
</script>

<style lang="less">
    @import "@/assets/theme/variable.less";
    .p-button-rounded.label-single {
        &:enabled:focus {
            background: @primary-color;
        }
        &:enabled:hover {
            background: @secondary-color;
        }
        &.active {
            background-color: @secondary-color;
            &.p-button:enabled:focus {
                background-color: @secondary-color;
            }
            .p-button-label {
                display: flex;
                gap: 10px;
                &:after {
                    content: "X";
                    display: block;
                    position: absolute;
                    right: 14px;
                }
            }
        }
    }
</style>
