<template>
    <div v-if="loaded">
        <GoBack />
        <h1 class="md:hidden mt-3">{{ trailBadge.ageGroup.maleName }} - {{ trailBadge.name }}</h1>
        <div>
            <div class="grid flex-column md:block gap-5 md:gap-0 justify-content-between
                        align-items-center md:align-items-start pt-6">
                <div class="col-12 md:col-4 grid flex-column align-items-center gap-4 md:ml-3"
                     style="float: right">
                    <PrimeButton :label="$t('message.buttons.downloadSampleCard')" @click="printCard"
                                 class="p-button-rounded download-card-button" v-if="!trailBadge.cubRank"
                                 :loading="downloadingFile"/>
                    <img :src="trailBadge.coverUrl" :alt="trailBadge.name" class="col-10 sm:col-6 md:col-12
                    traile-badge-img">
                </div>
                <div class="col-12">
                    <h1 class="md:block hidden">{{ trailBadge.ageGroup.maleName }} - {{ trailBadge.name }}</h1>
                    <TrailBadgePublicBasicInfo v-if="!trailBadge.ageGroup.cubsRank" :trail-badge="trailBadge"/>
                    <div v-else>
                        <div class="font-bold mb-3">{{ $t("message.headers.tipsForStaff") }}:</div>
                        <div v-html="trailBadge.tipsForSupervisors"></div>
                        <TrailBadgeSustainableDevelopmentGoals
                            :sustainable-development-goals="trailBadge?.sustainableDevelopmentGoals"
                            class="mb-3"/>
                        <TrailBadgePublicBibliographicHints :bibliographicHints="trailBadge.bibliographicHintList"/>
                    </div>
                </div>
            </div>
            <TrailBadgePublicRealizationPlan v-if="!trailBadge.ageGroup.cubsRank"
                                             :trail-badge="trailBadge" />
        </div>
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import {
        getPublicTrailBadgeUsingGET as getPublicTrailBadge,
        printTrailBadgeCardUsingGETURL as getTrailBadgePrintUrl,
    } from "@/swagger/vue-api-client";
    import GoBack from "@/components/GoBack";
    import {FileUtils} from "@/utils/FileUtils";
    import TrailBadgeSustainableDevelopmentGoals
        from "@/views/trailBadge/components/TrailBadgeSustainableDevelopmentGoals";
    import TrailBadgePublicRealizationPlan from "./components/TrailBadgePublicRealizationPlan";
    import TrailBadgePublicBasicInfo from "./components/TrailBadgePublicBasicInfo";
    import TrailBadgePublicTasks from "./components/TrailBadgePublicTasks";
    import TrailBadgePublicBibliographicHints
        from "./components/TrailBadgePublicBibliographicHints";

    export default {
        name: "TrailBadgePublicSingleView",
        components: {
            TrailBadgeSustainableDevelopmentGoals,
            PrimeButton,
            TrailBadgePublicRealizationPlan,
            TrailBadgePublicBasicInfo,
            TrailBadgePublicTasks,
            TrailBadgePublicBibliographicHints,
            GoBack,
        },

        props: {
        },

        computed: {},

        data() {
            return {
                loaded: false,
                trailBadge: {},
                downloadingFile: false,
            };
        },
        beforeMount() {
            if (this.$route.params.instrumentId) {
                getPublicTrailBadge({id: this.$route.params.instrumentId}).then((response) => {
                    this.trailBadge = response.data;
                    this.loaded = true;
                });
            } else {
                this.$router.push({name: "notFound"});
            }
        },

        mounted() {
        },

        methods: {
            async printCard() {
                this.downloadingFile = true;
                if (this.trailBadge.cubsRank) {
                    this.downloadingFile = false;
                    return;
                }
                const url = getTrailBadgePrintUrl({trailBadgeId: this.trailBadge.id});
                await FileUtils.downloadFileAsBlob(this, url, this.trailBadge.name + ".pdf");
                this.downloadingFile = false;
            },
            sustainableDevelopmentGoal(sdg) {
                if (!sdg) {
                    return "";
                }
                return "Cel "
                    + sdg.pointNumber
                    + ": "
                    + sdg.name;
            },
        },

    };
</script>

<style lang="less">
    @import "../../assets/theme/variable";
</style>
