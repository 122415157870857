<template>
    <div>
        <Toast />
        <DataTable ref="categoryTable" :value="categories" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="categoryType" header="typ" :sortable="true">
                <template #body="slotProps">
                    {{this.categoryTypeMap.get(slotProps.data.categoryType)}}
                </template>
            </Column>
            <Column field="name" header="nazwa" :sortable="true"></Column>
            <Column headerStyle="width: 160px">
                <template #body="slotProps">
                    <div class="flex gap-3 flex-wrap">
                        <router-link :to="{name: 'categoryEdit', params: {'id': slotProps.data.id}}">
                            <PrimeButton type="button" icon="pi pi-pencil" class="p-button-success"></PrimeButton>
                        </router-link>
                        <PrimeButton type="button" icon="pi pi-trash"
                                     class="p-button-danger" @click="deleteCategory(slotProps.data.id)">
                        </PrimeButton>
                    </div>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        deleteCategoryUsingDELETE as deleteCategory,
        searchCategoryCountUsingPOST as searchCategoryCount,
        searchCategoryUsingPOST as searchCategory,
    } from "@/swagger/vue-api-client";
    import Toast from "primevue/toast";
    import PrimeButton from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CategoryTable",
        components: {
            PrimeButton, Column, DataTable, Toast,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                categories: [],
                categoryTypeMap: new Map(
                    [["CUB_MERIT_BADGE", "Sprawność zuchowa"],
                     ["SCOUT_MERIT_BADGE", "Sprawność harcerska"],
                     ["CUB_TRAIL_BADGE", "Trop zuchowy"],
                     ["SCOUT_TRAIL_BADGE", "Trop harcerski"]],
                ),
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            async onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;
                this.searchParams = await this.searchCriteria;

                searchCategory({searchCriteria: this.searchParams}).then((response) => {
                    this.categories = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchCategoryCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                    sortOrder: this.searchCriteria.page.sortOrder,
                };
            },

            deleteCategory(categoryId) {
                this.$confirm.require({
                    message: "Ta operacja jest nieodwracalna",
                    header: "Czy na pewno chcesz usunąć kategorię?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "tak",
                    rejectLabel: "nie",
                    accept: () => {
                        deleteCategory({id: categoryId}).then(() => {
                            this.search();
                            ToastUtils.showSuccess(this, "Kategoria usunięta", "Udało się usunąć kategorię");
                        }).catch(() => {
                            ToastUtils.showError(this);
                        });
                    },
                });
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
