<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            <i class="pi pi-check-square"></i>
            {{ this.$route.params.id ? 'Edytuj Cel Zrównoważonego Rozwoju' : 'Dodaj Cel Zrównoważonego Rozwoju' }}
        </h1>
        <VeeForm v-if="loaded" @submit="saveSustainableDevelopmentGoal" v-slot="{ isSubmitting }">
            <div class="p-grid sustainableDevelopmentGoal-form">
                <div class="col-6">
                    <CustomInputText name="name" label="nazwa"
                                     v-model="sustainableDevelopmentGoal.name" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomInputNumber name="pointNumber" label="numer"
                                       v-model="sustainableDevelopmentGoal.pointNumber" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneFile label="grafika" name="file"
                                         v-model="sustainableDevelopmentGoal.file"/>
                </div>
                <div class="col-6">
                    <Toolbar class="actions">
                        <template #end>
                            <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateSustainableDevelopmentGoalUsingPOST as createOrUpdateSustainableDevelopmentGoal,
        getSustainableDevelopmentGoalUsingGET as getSustainableDevelopmentGoal,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import GoBack from "@/components/GoBack";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomSelectOneFile from "@/components/form/CustomSelectOneFile";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "SustainableDevelopmentGoalFormView",

        components: {
            CustomInputNumber,
            CustomSelectOneFile,
            PrimeButton,
            Toolbar,
            CustomInputText,
            Toast,
            VeeForm,
            GoBack,
        },

        props: {
            value: {},
        },

        data() {
            return {
                sustainableDevelopmentGoal: {
                    id: null,
                    name: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getSustainableDevelopmentGoal({id: this.$route.params.id}).then((response) => {
                    this.sustainableDevelopmentGoal = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveSustainableDevelopmentGoal() {
                createOrUpdateSustainableDevelopmentGoal(
                    {sustainableDevelopmentGoalEditDto: this.sustainableDevelopmentGoal},
                ).then(() => {
                    ToastUtils.showSuccess(this, "Zapisano dane");
                    this.$router.push({name: "sustainableDevelopmentGoalList"});
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .sustainableDevelopmentGoal-form {
    }
</style>
