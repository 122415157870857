<template>
    <TabView>
        <TabPanel :header="'Idea: poziom ' + (index + 1)" v-for="(row, index) in modelValue" :key="row.id">
            <CustomEditor v-model="row.description" :name="'description_' + index"/>
        </TabPanel>
    </TabView>
</template>

<script>
    import TabView from "primevue/tabview";
    import TabPanel from "primevue/tabpanel";
    import CustomEditor from "@/components/form/CustomEditor";

    export default {
        name: "MeritBadgeDescriptions",
        components: {TabView, TabPanel, CustomEditor},
        props: {
            modelValue: Array,
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style scoped>

</style>
