<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" ><i class="pi pi-table"></i>
            {{ 'Dodaj punkt prawa' }}
        </h1>
        <VeeForm @submit="saveScoutLaw" v-slot="{ errors }">
            <div class="scout-law-form p-grid">
                <CustomInputText class="col-6 scout-law-input"
                                 name="rule" label="treść" v-model="scoutLaw.rule" rules="required"/>
                <CustomSelectOne class="col-6 scout-law-input"
                                 label="rodzaj prawa" name="type" rules="required" :items="ageGroupType"
                                 item-label="type" v-model="this.scoutLaw.ageGroupType" item-value="group"
                                 :empty-value="false"/>
                <Toolbar class="col-6 actions">
                    <template #end>
                        <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isDisabled(errors)"/>
                    </template>
                </Toolbar>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createScoutLawUsingPOST as createScoutLaw,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import GoBack from "@/components/GoBack";
    import {Form as VeeForm} from "vee-validate";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "ScoutLawFormView",

        components: {
            PrimeButton, Toolbar, CustomInputText, Toast, VeeForm, CustomSelectOne, GoBack,
        },
        data() {
            return {
                scoutLaw: {
                    id: null,
                    rule: null,
                    ageGroupType: null,
                },
                ageGroupType: [{type: "Harcerskie", group: "SCOUT"}, {type: "Zuchowe", group: "CUB"}],
            };
        },
        updated() {
            this.scoutLaw.id = this.$route.params.id;
        },
        methods: {
            async saveScoutLaw() {
                try {
                    await createScoutLaw({scoutLawDto: this.scoutLaw});
                    ToastUtils.showSuccess(
                        this,
                        "Punkt prawa harcerskiego dodany",
                        "Udało się utworzyć prawo harcerskie",
                    );
                    this.$router.push({name: "scoutLaw", query: {type: this.scoutLaw.ageGroupType}});
                } catch (error) {
                    ToastUtils.showError(this);
                }
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
        },
    };
</script>

<style lang="less" scoped>
.scout-law-form {
    width: 80%;
    max-width: 800px;
}
.scout-law-input {
    padding-right: 2px;
    padding-left: 0;
}
</style>
