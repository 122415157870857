<template>
    <div class="menu-wrapper">
        <PrimeMenu :model="items"/>
    </div>
</template>

<script>
    import PrimeMenu from "primevue/menu";

    export default {
        name: "AppMenu",

        components: {PrimeMenu},
        emits: ["menu-toggle"],

        data() {
            return {
                items: [
                    {
                        label: this.$t("message.menu.cubScouts"),
                        items: [
                            {
                                label: this.$t("message.menu.stars"),
                                to: "/rank/list/cub-scouts",
                                class: "cub-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.meritBadge"),
                                to: "/merit-badge/list/cub-scouts",
                                class: "cub-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.trailBadge"),
                                to: "/trail-badge/list/cub-scouts",
                                class: "cub-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.individualTasks"),
                                to: "/challenge/list/cub-scouts",
                                class: "cub-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                        ],
                    },
                    {
                        label: this.$t("message.menu.scouts"),
                        items: [{label: this.$t("message.menu.levels"), to: "/rank/list/scouts", class: "scouts"},
                                {
                                    label: this.$t("message.menu.meritBadge"),
                                    to: "/merit-badge/list/scouts",
                                    class: "scouts",
                                    command: () => this.$emit("menu-toggle"),
                                },
                                {
                                    label: this.$t("message.menu.trailBadge"),
                                    to: "/trail-badge/list/scouts",
                                    class: "scouts",
                                    command: () => this.$emit("menu-toggle"),
                                },
                                {
                                    label: this.$t("message.menu.challenges"),
                                    to: "/challenge/list/scouts",
                                    class: "scouts",
                                    command: () => this.$emit("menu-toggle"),
                                },
                        ],
                    },
                    {
                        label: this.$t("message.menu.seniorScouts"),
                        items: [
                            {
                                label: this.$t("message.menu.levels"),
                                to: "/rank/list/senior-scouts",
                                class: "senior-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.meritBadge"),
                                to: "/merit-badge/list/senior-scouts",
                                class: "senior-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.trailBadge"),
                                to: "/trail-badge/list/senior-scouts",
                                class: "senior-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.challenges"),
                                to: "/challenge/list/senior-scouts",
                                class: "senior-scouts",
                                command: () => this.$emit("menu-toggle"),
                            },
                        ],
                    },
                    {
                        label: this.$t("message.menu.wanderers"),
                        items: [
                            {
                                label: this.$t("message.menu.levels"),
                                to: "/rank/list/wanderers",
                                class: "wanderers",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.meritBadge"),
                                to: "/merit-badge/list/wanderers",
                                class: "wanderers",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.trailBadge"),
                                to: "/trail-badge/list/wanderers",
                                class: "wanderers",
                                command: () => this.$emit("menu-toggle"),
                            },
                            {
                                label: this.$t("message.menu.challenges"),
                                to: "/challenge/list/wanderers",
                                class: "wanderers",
                                command: () => this.$emit("menu-toggle"),
                            },
                        ],
                    },
                ],
            };
        },
    };
</script>

<style lang="less">
    .layout-topbar {
        .p-menuitem-icon {
        width: max-content;
        }
    }
</style>
