<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="name" item-value="id" :label="label" :loading="loading"
                         :key="items" :rules=rules />
    </div>
</template>

<script>
    import {
        getAllCategoriesForTypeUsingGET as getAllForCategory,
        getAllCategoriesUsingGET as getAll,
    } from "../../swagger/vue-api-client";
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneCategory",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            categoryType: {
                type: Symbol,
                default: null,
            },
            rules: {
                type: String,
                default: null,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [],
                loading: true,
            };
        },

        beforeMount() {
            if (!this.categoryType) {
                getAll().then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
            } else {
                getAllForCategory({categoryType: this.categoryType.description}).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
            }
        },
        watch: {
            categoryType() {
                getAllForCategory({categoryType: this.categoryType.description}).then((response) => {
                    this.items = response.data;
                });
            },
        },
        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
