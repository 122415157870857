<template>
    <div class="list-view">
        <h1 class="page-header">Wszystkie instrumenty</h1>
        <div class="search-criteria public-view mb-5 mt-6" @keyup.enter="search">
            <homeSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
            <Toolbar>
                <template #start>
                    <PrimeButton icon="pi pi-search" class="p-button-rounded mr-3" @click="search" :disabled="loading"/>
                    <PrimeButton icon="pi pi-times" class="p-button-rounded p-button-outlined" @click="clear"/>
                </template>
            </Toolbar>
        </div>
        <BasicCardList :instruments="instruments" :loading="loading"/>
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import BasicCardList from "@/components/BasicCard/BasicCardList";
    import {searchBadgesUsingPOST as searchBadges} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import homeSearchCriteria from "./components/homeSearchCriteria";

    export default {
        name: "homeView",
        components: {
            homeSearchCriteria,
            BasicCardList,
            PrimeButton,
            Toolbar,
        },

        props: {
        },

        computed: {
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                instruments: [],
                loading: true,
            };
        },
        beforeMount() {
            SearchCriteria.loadCriteria(this);
            this.search();
        },

        mounted() {
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);
                this.searchBadgesWithParams();
            },
            searchBadgesWithParams() {
                searchBadges({badgesSearchRequest: this.searchCriteria}).then((response) => {
                    this.instruments = response.data;
                    // eslint-disable-next-line max-len,no-return-assign
                    this.instruments.forEach((val) => val.instrumentType = val.type === "MERIT_BADGE" ? "meritBadge" : "trailBadge");
                    this.loading = false;
                }).catch((error) => { window.console.log(error); ToastUtils.showError(this); });
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },
            getClearSearchCriteria() {
                return {
                    phrase: "",
                    labelIds: [],
                    page: {
                    },
                };
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
