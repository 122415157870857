<template>
    <div class="p-fluid custom-input-number">
        <label v-if="label" class="input-number-label" :for="name">{{ label }}</label>
        <InputText :id="name" type="number" v-model="internalValue" :disabled="disabled"
                   :class="computedClasses" @input="handleChange" @blur="handleBlur"
                   :placeholder="placeholder" />
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputNumber",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            rules: undefined,
            modelValue: null,
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: null,
            },
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        components: {InputText},
    };
</script>

<style lang="less" scoped>
    @import "../../assets/theme/variable";
    .custom-input-number {
        margin-bottom: 10px;
        text-align: left;
    }
    .input-number-label {
        margin-left: 0.5em;
        font-weight: 300;
        color: @color-black-trans-70;
        font-size: 18px;
    }
</style>
