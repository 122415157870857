<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-verified"></i> Lista wyzwań i zuchowych zadań indywidualnych</h1>

        <div class="search-criteria" @keyup.enter="search">
            <ChallengeSearchCriteria ref="challengeSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj wyzwanie/zadanie" icon="pi pi-plus" @click="createChallenge"/>
            </template>
        </Toolbar>

        <ChallengeTable ref="challengeTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import ChallengeSearchCriteria from "./components/ChallengeSearchCriteria";
    import ChallengeTable from "./components/ChallengeTable";

    export default {
        name: "ChallengeListView",
        components: {
            ChallengeTable, ChallengeSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.challengeTable.search();
            },

            createChallenge() {
                this.$router.push({name: "challengeCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
