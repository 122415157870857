<template>
    <div class="rank-task-edit">
        <div class="flex-box  justify-content-between">
            <h4 style="display: inline;" class="header">Lista zadań</h4>
            <div class="my-4">
                <PrimeButton label="Dodaj zadanie" icon="pi pi-plus" class="" @click="newTask"/>
            </div>
        </div>
        <DataTable :value="value" class="editable-cells-table mb-4"
                   responsiveLayout="scroll" @rowReorder="onRowReorder">
            <template #empty>
                Brak zadań
            </template>
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="name" header="treść zadania">
                <template #body="{ data, field, index }">
                    <CustomInputText v-model="data[field]" style="width: 100%"
                                     :name="index + field" placeholder="treść zadania"
                                     rules="required"/>
                </template>
            </Column>
            <Column headerStyle="width: 5rem" header="usuń">
                <template #body="slotProps">
                    <PrimeButton type="button" icon="pi pi-trash" @click="deleteTask(slotProps.data.id)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import PrimeButton from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";

    export default {
        name: "RankTaskEditList",
        components: {
            DataTable, CustomInputText, Column, PrimeButton,
        },
        props: {
            modelValue: Array,
        },
        emits: ["update:modelValue"],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        methods: {
            onRowReorder(event) {
                this.value = event.value;
            },
            deleteTask(taskId) {
                this.value = this.value.filter((el) => el.id !== taskId);
            },
            async newTask() {
                this.value = [...this.value, {text: ""}];
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 100));
                this.scrollToLastRow();
            },
            scrollToLastRow() {
                const newInputId = (this.value.length - 1) + "text";
                const element = document.getElementById(newInputId);
                element.focus();
            },
        },
    };
</script>

<style scoped lang="less">
@import "../../../assets/theme/variable.less";
.rank-task-edit {
    border-top: 1px solid #eee;
    padding-top: 20px;
    color: @color-black-trans-70
}
</style>
