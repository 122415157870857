<template>
    <div v-if="dataRank" class="rank-single-view">
        <GoBack />
        <h1 class="md:hidden mt-3">{{ getName }}</h1>
        <div class="flex flex-column md:flex-row justify-content-between
                    align-items-start md:gap-8 gap-4 mb-5 md:mb-0 md:mt-6">
            <div class="flex flex-column md:flex-order-1 flex-order-2">
                <h1 class="md:block hidden">{{ getName }}</h1>
                <div class="rank-description" v-html="getDescription"/>
            </div>
            <div class="flex flex-column flex-shrink-0 md:flex-order-2 flex-order-1">
                <router-link v-if="!this.dataRank.subScoutRank"
                             :to="{name: 'rankSingleView', params: {sex: getOtherGender,
                                                                    groupName: this.$route.params.groupName}}">
                    <PrimeButton :label="getOtherGenderMesssage" type="submit"
                                 class="p-button-rounded mt-3"/>
                </router-link>
                <div v-if="filesLoaded" class="flex flex-column">
                    <div v-if="filesLoaded"
                         class="flex flex-column">
                        <span v-for="file in files" :key="file.id">
                            <PrimeButton :label="file.label"
                                         @click="downloadFile(file)"
                                         class="p-button-rounded mt-3"/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex md:flex-row flex-column md:align-items-center justify-content-between mt-3">
            <RankSingleBasicInfo :basicInfo="dataRank"/>
            <div v-if="this.dataRank.coverUrl"
                 class="align-self-start md:align-self-center flex align-items-center gap-2 cover-div
                        flex-order-1 md:flex-order-2" :class="getImgType">
                <img class="cover-img" :src="dataRank.coverUrl" :alt="dataRank.name">
            </div>
        </div>
        <RankSingleTasks :tasks="dataRank.tasks"/>
        <div v-if="dataRank.textForPupils" class="extra-text mt-8">
            <p class="font-bold" >Wskazówki dla podopiecznych</p>
            <p v-html="dataRank.textForPupils" />
        </div>
        <div v-if="dataRank.textForSupervisor">
            <p class="font-bold">Wskazówki dla drużynowego</p>
            <p v-html="dataRank.textForSupervisor" />
        </div>
    </div>
</template>

<script>

    import {
        downloadRankPrintFileUsingGETURL as downloadFileURL,
        getRankPrintFileListByRankIdUsingGET as getFiles,
        getRankViewUsingGET as getRank,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import GoBack from "@/components/GoBack";
    import {Genders} from "@/utils/Genders";
    import {FileUtils} from "@/utils/FileUtils";
    import RankSingleTasks from "./components/RankSingleTasks";
    import RankSingleBasicInfo from "./components/RankSingleBasicInfo";

    export default {
        name: "RankSingleView",
        components: {
            RankSingleTasks, RankSingleBasicInfo, PrimeButton, GoBack,
        },
        props: {
        },

        data() {
            return {
                dataRank: null,
                filesLoaded: false,
                files: null,
            };
        },

        computed: {
            stars() {
                return parseInt(this.$route.params.stars, 10);
            },
            getOtherGender() {
                return this.$route.params.sex === Genders.FEMALE ? Genders.MALE : Genders.FEMALE;
            },
            getOtherGenderMesssage() {
                return "Zobacz w wersji " + (this.getOtherGender === Genders.FEMALE ? "żeńskiej" : "męskiej");
            },
            showFemaleVersion() {
                return this.$route.params.sex === Genders.FEMALE && !this.dataRank.subScoutRank;
            },
            getName() {
                return this.showFemaleVersion ? this.dataRank.femaleName : this.dataRank.name;
            },
            getDescription() {
                return this.showFemaleVersion ? this.dataRank.femaleDescription : this.dataRank.description;
            },
            getImgType() {
                const {coverUrl} = this.dataRank;
                switch (true) {
                    case coverUrl.includes("belka_harcerska"):
                        return "belka";
                    case coverUrl.includes("gwiazdka_zuchowa"):
                        return "gwiazdka";
                    case coverUrl.includes("strzalka_starszoharcerska"):
                        return "strzalka";
                    default:
                        return "default";
                }
            },
        },
        beforeMount() {
            getRank({id: this.$route.params.id}).then((response) => {
                this.dataRank = response.data;
            }).catch((error) => {
                window.console.log(error);
            });
            getFiles({rankId: this.$route.params.id}).then((response) => {
                this.files = response.data;
                this.filesLoaded = true;
            }).catch((error) => {
                window.console.log(error);
            });
        },
        methods: {
            async downloadFile(file) {
                await FileUtils.downloadFileAsBlob(
                    this,
                    await downloadFileURL({id: file.id}),
                    file.filename,
                );
            },
        },
    };
</script>

<style lang="less">
    @import "../../assets/theme/variable";
    .rank-single-view {
        .rank-description {
            color: @color-purple-scout;
            font-weight: 500;
        }
        .cover-div {
            background-color:@color-purple-scout;
            &.default {
                padding: 10px;
                max-width: 160px;
            }
            &.belka {
                padding: 20px;
                .cover-img {
                    width: 80px;
                }
            }
            &.gwiazdka {
                padding: 10px;
                .cover-img {
                    width: 140px;
                }
            }
            &.strzalka {
                padding: 15px 5px;
                .cover-img {
                    width: 140px;
                }
            }
        }
    }
</style>
