<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="name" item-value="id" :rules="rules" :label="label" :show-label="showLabel"/>
    </div>
</template>

<script>
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneLevel",
        props: {
            showLabel: {
                type: Boolean,
                default: true,
            },
            name: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                label: "poziom",
                items: [
                    {
                        id: 1,
                        name: "1",
                    },
                    {
                        id: 2,
                        name: "2",
                    },
                    {
                        id: 3,
                        name: "3",
                    },
                    {
                        id: 4,
                        name: "4",
                    },
                ],
            };
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
