<template>
    <div>
        <TabView :activeIndex="activeIndex" class="levels"
                 :class="{cubsTabView : meritBadge.cubsRank}" @tab-change="handleTabChange">
            <TabPanel v-for="level in meritBadge.levels" :key="level.level">
                <template #header>
                    <div v-if="meritBadge.cubsRank"
                         class="flex justify-content-center merit-badge-tab-view-custom-header">
                        <!-- eslint-disable-next-line -->
                        <svg v-for="item in level.level" :key="item" xmlns='http://www.w3.org/2000/svg' fill="#fff" width="20" viewBox='0 0 260 245'><path d='m56,237 74-228 74,228L10,96h240'/></svg>
                    </div>
                    <div v-else class="flex justify-content-center merit-badge-tab-view-custom-header
                                                  header-no-cubs">
                        <div v-for="item in level.level" :key="item">
                            *
                        </div>
                    </div>
                </template>
                <div>
                    <MeritBadgeTasks :tasks="level.tasks" :is-cubs-rank="meritBadge.cubsRank"/>
                </div>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>

    import TabView from "primevue/tabview";
    import TabPanel from "primevue/tabpanel";
    import MeritBadgeTasks from "./MeritBadgeTasks";

    export default {
        name: "MeritBadgeLevels",
        components: {TabView, TabPanel, MeritBadgeTasks},

        props: {
            meritBadge: {
                type: Object,
            },
            activeIndex: {
                type: Number,
            },
            tasks: {
                type: Array,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
            goToMaleRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "male"}});
            },
            goToFemaleRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "female"}});
            },
            goToCubsRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "cub-scouts"}});
            },
            handleTabChange(event) {
                this.$router.replace({path: this.$route.path, query: {level: event.index}});
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
