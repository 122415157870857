<template>
    <div class="list-view">
        <h1>{{ $t("message.headers.trailBadge") }}</h1>
        <div class="search-criteria public-view mb-5" @keyup.enter="search">
            <TrailBadgePublicSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
            <Toolbar>
                <template #start>
                    <PrimeButton icon="pi pi-search" class="p-button-rounded mr-3" @click="search" :disabled="loading"/>
                    <PrimeButton icon="pi pi-times" class="p-button-rounded p-button-outlined" @click="clear"/>
                </template>
            </Toolbar>
        </div>
        <BasicCardList :instruments="instruments" :loading="loading"/>
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import BasicCardList from "@/components/BasicCard/BasicCardList";
    import {
        searchPublicTrailBadgeUsingPOST as searchPublicTrailBadge,
    } from "@/swagger/vue-api-client";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import TrailBadgePublicSearchCriteria from "./components/TrailBadgePublicSearchCriteria";

    export default {
        name: "TrailBadgePublicListView",
        components: {
            TrailBadgePublicSearchCriteria,
            BasicCardList,
            PrimeButton,
            Toolbar,
        },

        props: {
        },

        computed: {
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                instruments: [],
                loading: true,
            };
        },
        beforeMount() {
            SearchCriteria.loadCriteria(this);
            this.search();
        },

        mounted() {
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);
                this.searchTrailBadge();
            },
            searchTrailBadge() {
                searchPublicTrailBadge({searchCriteria: this.searchCriteria}).then((response) => {
                    this.instruments = response.data;
                    // eslint-disable-next-line no-return-assign
                    this.instruments.forEach((val) => val.instrumentType = "trailBadge");
                    this.loading = false;
                });
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },
            getClearSearchCriteria() {
                return {
                    ageGroupName: this.$route.params.groupName,
                    cubsRank: this.$route.params.groupName === "cub-scouts",
                    phrase: "",
                    categoryId: "",
                    sustainableDevelopmentGoalIds: [],
                    labelIds: [],
                    page: {
                    },
                };
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
