<template>
    <div>
        <CustomSelectOne :label=label :name="name" :rules="rules" :items="categoryType"
                         item-label="name" v-model="internalValue" item-value="type"
                         :empty-value="emptyValue"/>
    </div>
</template>

<script>
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneCategoryType",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                categoryType: [{name: "Sprawność zuchowa", type: "CUB_MERIT_BADGE"},
                               {name: "Sprawność harcerska", type: "SCOUT_MERIT_BADGE"},
                               {name: "Trop zuchowy", type: "CUB_TRAIL_BADGE"},
                               {name: "Trop harcerski", type: "SCOUT_TRAIL_BADGE"},
                ],
            };
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
