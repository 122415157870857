/* eslint-disable */
export const SearchCriteria = {

    ARRAY_PREFIX: "array_",
    DATE_PREFIX: "date_",

    updateUrl(context) {
        const urlParam = {};
        Object.entries(context.searchCriteria).forEach(([key, value]) => {
            if (key !== "page") {
                if (value && Array.isArray(value)) {
                    urlParam[this.ARRAY_PREFIX + key] = "" + value;
                } else if (value && value.toISOString) {
                    urlParam[this.DATE_PREFIX + key] = value.toISOString();
                } else if (value || value === false) {
                    urlParam[key] = "" + value;
                }
            } else {
                Object.entries(value).forEach(([pageKey, pageValue]) => {
                    if (pageValue) {
                        urlParam["page" + pageKey] = "" + pageValue;
                    }
                });
            }
        });

        if (JSON.stringify(urlParam) !== JSON.stringify(context.$route.query)) {
            context.$router.push({name: context.$route.name, query: urlParam});
        }
    },

    loadCriteria(context) {
        Object.entries(context.$route.query).forEach(([key, value]) => {
            if (value) {
                if (key.startsWith("page")) {
                    context.searchCriteria.page[key.substr(4)] = isNaN(value) ? value : parseInt(value, 10);
                } else if (key.startsWith(this.ARRAY_PREFIX)) {
                    let valAsArray = value.split(",");
                    if (this.containsOnlyNumbers(valAsArray)) {
                        context.searchCriteria[key.replace(this.ARRAY_PREFIX, '')] = valAsArray.map(Number);
                    } else {
                        context.searchCriteria[key.replace(this.ARRAY_PREFIX, '')] = valAsArray;
                    }
                } else if (key.startsWith(this.DATE_PREFIX)) {
                    context.searchCriteria[key.replace(this.DATE_PREFIX, '')] = new Date(value);
                } else {
                    if (value === "false") {
                        context.searchCriteria[key] = false;
                    } else if (value === "true") {
                        context.searchCriteria[key] = true;
                    } else {
                        context.searchCriteria[key] = isNaN(value) ? value : parseInt(value, 10);
                    }
                }
            }
        });
    },

    containsOnlyNumbers(array) {
        return array.every(element => {
            return this.isNumeric(element);
        });
    },

    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

};
