<template>
    <div v-if="loaded">
        <Toolbar class="actions mt-6">
            <template #end>
                <PrimeButton label="Dodaj recenzję"
                             icon="pi pi-plus"
                             @click="visible = true"/>
            </template>
        </Toolbar>
    </div>
    <Dialog v-model:visible="visible"
            :closable="true"
            header="Dodaj recenzję"
            :modal="true"
            style="width: 50%">
        <VeeForm @submit="saveReview" v-slot="{ isSubmitting }">
            <div class="d-flex flex-column align-items-center gap-4">
                <div class="d-flex flex-column gap-3">
                    <CustomEditor name="reviewTextEditor"
                                  v-model="review.reviewText"
                                  :height="500"
                                  rules="required"/>
                </div>
                <div class="col-12">
                    <Toolbar class="actions">
                        <template #end>
                            <PrimeButton :disabled="isSubmitting"
                                         icon="pi pi-check"
                                         label="Zapisz"
                                         type="submit"/>
                        </template>
                    </Toolbar>
                </div>
            </div>
        </VeeForm>
    </Dialog>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Dialog from "primevue/dialog";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import Toolbar from "primevue/toolbar";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {Form as VeeForm} from "vee-validate";
    import CustomEditor from "@/components/form/CustomEditor";
    import {createOrUpdateReviewUsingPOST as createOrUpdateReview} from "@/swagger/vue-api-client";

    export default {
        name: "AddReviewDialog",
        components: {
            Dialog,
            PrimeButton,
            CustomInputTextArea,
            Toolbar,
            VeeForm,
            CustomEditor,
        },
        emits: ["review-saved"],
        props: {
            instrumentType: {
                type: String,
                default: null,
            },
            instrumentId: {
                type: Number,
                default: null,
            },
        },

        data() {
            return {
                visible: false,
                review: {
                    instrumentType: null,
                    instrumentId: null,
                    reviewText: null,
                },
                loaded: false,
            };
        },
        mounted() {
            this.review.instrumentId = this.instrumentId;
            this.review.instrumentType = this.instrumentType;
            this.loaded = true;
        },
        methods: {
            async saveReview() {
                try {
                    await createOrUpdateReview({reviewDto: this.review});
                    ToastUtils.showSuccess(
                        this,
                        "Sukces!",
                        "Pomyślnie dodano recenzję",
                    );
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise((r) => setTimeout(r, 2000));
                    this.visible = false;
                    this.$emit("review-saved");
                    this.review.reviewText = null;
                } catch (error) {
                    ToastUtils.showError(
                        this,
                        "Błąd!",
                        "Wystąpił błąd przy zapisie recenzji",
                    );
                }
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
