<template>
    <div  class="list-view" :class="computedClass" :key="$route.fullPath" v-if="dataRanks">
        <h1 v-if="!dataRanks.cubsRank" class="page-header">
            {{ $t('message.menu.levels') }}
        </h1>
        <h1  v-else class="page-header">
            {{ $t('message.menu.stars') }}
        </h1>
        <div class="flex gap-6 ranks-inner mt-2">
            <div v-if="!dataRanks.cubsRank" class="flex flex-column md:flex-row lg:flex-column xl:flex-row
            gap-6 ranks-inner mt-2">
                <div class="flex gap-4 ranks-inner flex-column ranks-male">
                    <h2 class="flex-1 text-center uppercase">{{ $t('message.headers.scoutsFemale') }}</h2>
                    <div v-for="(rank, index) in dataRanks.ranks" :key="rank.id">
                        <SingleRank :rankName="rank.femaleName" :ageGroupType="'female'" :dataRank="rank"
                                    :groupName="dataRanks.ageGroupName" :stars="index+1"/>
                    </div>
                </div>
                <div class="flex gap-4 ranks-inner flex-column ranks-female">
                    <h2 class="flex-1 text-center uppercase">{{ $t('message.headers.scouts') }}</h2>
                    <div v-for="(rank, index) in dataRanks.ranks" :key="rank.id">
                        <SingleRank :rankName="rank.name" :dataRank="rank"
                                    :ageGroupType="dataRanks.ageGroupName !== 'Zuchy' ? 'male' : 'cub-scouts'"
                                    :groupName="dataRanks.ageGroupName" :stars="index+1"/>
                    </div>
                </div>
            </div>
            <div v-else class="flex gap-6 ranks-inner mt-2">
                <div class="flex gap-4 ranks-inner flex-column ranks-female">
                    <div v-for="(rank) in dataRanks.ranks" :key="rank.id">
                        <SingleRank :rankName="rank.name" :dataRank="rank"
                                    :ageGroupType="dataRanks.ageGroupName !== 'Zuchy' ? 'male' : 'cub-scouts'"
                                    :groupName="dataRanks.ageGroupName" :stars="rank.rankNo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getAgeGroupMapUsingGET as getRanks} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import SingleRank from "./components/SingleRank";

    export default {
        name: "RankListView",
        components: {SingleRank},

        props: {
        },

        computed: {
            computedClass() {
                return this.$route.params.groupName + "-levels levels";
            },
            dataRanks() {
                if (!this.allRanks) {
                    return null;
                }
                return this.allRanks[this.$route.params.groupName];
            },
        },

        data() {
            return {
                allRanks: null,
            };
        },
        mounted() {
            getRanks().then((response) => {
                this.allRanks = response.data;
            }).catch(() => {
                ToastUtils.showError(this, "Nie udało się pobrać danych", "Nieoczekiwany błąd", 5000);
            });
        },
    };
</script>

<style lang="less" scoped>
    .ranks-inner {
        max-width: 1100px;
        margin: 0 auto;
        width: 100%;
    }
</style>
