<template>
    <div :class="containerClass" class="layout-wrapper layout-static">
        <Toast />
        <ConfirmDialog/>
        <div class="sidebar">
            <transition name="layout-sidebar">
                <div class="layout-sidebar">
                    <div class="logo md:flex hidden justify-content-center">
                        <router-link :to="{name: 'homeView'}">
                            <img src="@/assets/logo.svg" alt="logo">
                        </router-link>
                    </div>
                    <AppMenu @menu-toggle="closeSidebarOnMobile" class="pt-2 md:pt-0"/>
                </div>
            </transition>
        </div>
        <div class="layout-mask" :class="{active: overlayMenuActive}" @click="onMenuSidebarToggle"
             @keyup.enter="showAllLabelsToggle"></div>
        <div class="layout-wrapper-inner">
            <AppTopBar @menu-toggle="onMenuSidebarToggle"/>
            <div class="layout-main container-content">
                <div class="content">
                    <router-view :key="$route.path"/>
                </div>
            </div>
        </div>

    </div>
    <footer class="container-content">
        <AppFooter />
    </footer>
</template>

<script>
    import Toast from "primevue/toast";
    import AppTopBar from "@/components/AppTopBar";
    import ConfirmDialog from "primevue/confirmdialog";
    import AppMenu from "../../components/AppMenu";
    import AppFooter from "../../components/AppFooter";

    export default {
        name: "LayoutDashboard",
        components: {
            AppTopBar,
            ConfirmDialog,
            AppMenu,
            AppFooter,
            Toast,
        },

        data() {
            return {
                layoutMode: "static",
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
            };
        },
        computed: {

            containerClass() {
                return [{
                    "layout-static": this.layoutMode === "static",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                }];
            },
        },
        methods: {
            onMenuSidebarToggle() {
                this.menuClick = true;
                this.mobileMenuActive = !this.mobileMenuActive;
                this.overlayMenuActive = !this.overlayMenuActive;
            },
            closeSidebarOnMobile() {
                if (this.mobileMenuActive) {
                    this.onMenuSidebarToggle();
                }
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/layout-dasboard";
    @import "../assets/less/responsive";
    @import "../../components/assets/less/sidebar";
    @import "@/assets/theme/variable.less";
    /* Works on Firefox */
    .sidebar {
        scrollbar-width: thin;
        scrollbar-color: @color-green @color-gray;
        @media only screen and (min-width: 1025px) {
            position: sticky;
            top: 0;
            max-height: 100vh;
        }
    }

    /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: @color-gray;
    }

    ::-webkit-scrollbar-thumb {
        background-color: @color-green;
        border-radius: 20px;
        border: 3px solid @color-gray;
    }

</style>
