<template>
    <div class="metadata">
        <div class="p-grid" v-if="data">
            <div class="col-12">
                Data utworzenia: {{displayDate(data.createdOn)}} przez: {{data.createdBy}}
            </div>
            <div class="col-12">
                Data ostatniej modyfikacji: {{displayDate(data.lastModifiedOn)}} przez: {{data.lastModifiedBy}}
            </div>
        </div>
    </div>
</template>

<script>
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "MetadataComponent",
        props: {
            data: {
                type: Object,
            },
        },
        methods: {
            displayDate(date) {
                return DateUtils.displayDateTimeInPolishFormat(date);
            },
        },
    };
</script>

<style scoped lang="less">
@import "../../assets/theme/variable";

.metadata {
    border-top: 1px solid #eee;
    font-size: @label-font-size;
    padding-top: 10px;
}
</style>
