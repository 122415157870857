<template>
    <div>
        <router-link :to="{ name:'rankSingleView',
                            params:{groupName: groupName, id: dataRank.id,
                                    sex: ageGroupType, stars: stars}}"
                     class="single-level p-3 sm:p-5 text-center block">
            <h2 class="font-medium">{{ rankName }}</h2>
        </router-link>
    </div>
</template>

<script>

    export default {
        name: "SingleRank",
        components: {},

        props: {
            stars: {
                type: Number,
            },
            ageGroupType: {
                type: String,
            },
            rankName: {
                type: String,
            },
            dataRank: {
                type: Object,
            },
            groupName: {
                type: String,
            },
            coverUrl: {
                type: String,
            },
        },

        computed: {
            computedClass() {
                switch (this.groupName) {
                    case "Harcerze":
                        return "scouts";
                    case "Zuchy":
                        return "cub-scouts";
                    case "Harcerze starsi":
                        return "senior-scouts";
                    case "Wędrownicy":
                        return "wanderers";
                    default:
                        return "default";
                }
            },
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
    @import "@/assets/theme/variable.less";

    .single-level {
        h2 {
            color: @color-white;
            font-size: 30px;
        }
    }
    .cub-scouts-levels {
        .single-level {
            background-color: @color-yellow;
        }
    }
    .scouts-levels {
        .single-level {
            background-color: @secondary-color;
        }

        .ranks-female {
            .single-level {
                background-color: @color-green-dark3;
            }
        }
    }

    .senior-scouts-levels {
        .single-level {
            background-color: @color-blue;
        }

        .ranks-female {
            .single-level {
                background-color: @color-blue-light;

            }
        }
    }

    .wanderers-levels {
        .single-level {
            background-color: @color-red;
        }

        .ranks-female {
            .single-level {
                background-color: @color-red-dark2;
            }
        }
    }
</style>
