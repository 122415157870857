<template>
    <div class="background-panel">
        <p class="image-credit">Autorka zdjęcia: Agnieszka Madetko-Kurczab</p>
        <div class="text-panel absolute top-50 left-50 sm:p-5 p-3 flex flex-column bg-white
                    sm:max-w-max w-full translate-x-50 align-items-center text-center">
            <h3> Wystąpił błąd </h3>
            <p> Skontaktuj się z administratorem systemu </p>
            <router-link v-slot="{navigate}" :to="{name: 'homeView'}">
                <div @click="navigate" @keydown="navigate">
                    <PrimeButton icon="pi pi-undo" label="Powrót na stronę główną"/>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";

    export default {
        name: "ErrorView",
        components: {PrimeButton},
    };
</script>

<style lang="less" scoped>
@import url("@/views/assets/less/status-page.less");

.background-panel {
    background-image: url("../assets/error-image.jpg");
}

</style>
