<template>
    <div class="rank-print-files mt-3">
        <div class="flex-box  justify-content-between">
            <h4 style="display: inline;" class="header">Lista wydruków</h4>
            <div class="my-4">
                <PrimeButton label="Dodaj plik" icon="pi pi-plus" class="" @click="openAddFileDialog"/>
            </div>
        </div>
        <DataTable :value="files" :dataKey="id" class="editable-cells-table mb-4" responsiveLayout="scroll">
            <template #empty>
                Brak plików z wydrukami
            </template>
            <Column field="label" header="etykieta" :sortable="true">
            </Column>
            <Column field="filename" header="nazwa pliku" :sortable="true">
                <template #body="{ data }">
                    {{ fileUtils.removeExtensionFromFilename(data.filename) }}
                </template>
            </Column>
            <Column headerStyle="width: 7rem" header="pobierz plik">
                <template #body="{ data }">
                    <PrimeButton type="button" icon="pi pi-download" @click="downloadFile(data)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
            <Column headerStyle="width: 6rem" header="edytuj plik">
                <template #body="{ data }">
                    <PrimeButton type="button" icon="pi pi-pencil" @click="editFile(data)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
            <Column headerStyle="width: 5rem" header="usuń plik">
                <template #body="{ data }">
                    <PrimeButton type="button" icon="pi pi-trash" @click="deleteFile(data.id, $event)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
        </DataTable>
        <FileFormDialog :editing="true" v-model:visible="showEditFileDialog" :rank-id="rankId"
                        :edited-file="editedFile" @file-added="onFilesChange"/>
        <FileFormDialog :editing="false"
                        v-model:visible="showAddFileDialog" @file-added="onFilesChange"
                        :rank-id="rankId"/>
    </div>
</template>

<script>

    import DataTable from "primevue/datatable";
    import PrimeButton from "primevue/button";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOneLevel from "@/components/form/CustomSelectOneLevel";
    import CustomSelectOneRank from "@/components/form/CustomSelectOneRank";
    import {
        downloadRankPrintFileUsingGETURL as downloadFileURL,
        deleteRankPrintFileUsingDELETE as deleteFile,
    } from "@/swagger/vue-api-client";
    import FileFormDialog from "@/components/form/FileFormDialog";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "RankPrintFiles",
        components: {
            DataTable,
            PrimeButton,
            Column,
            CustomInputText,
            CustomSelectOneLevel,
            CustomSelectOneRank,
            FileFormDialog,
        },
        props: {
            modelValue: Array,
            rankId: {
                type: Number,
            },
            files: {
                type: Array,
                default: () => [],
            },
        },
        emits: ["update:modelValue", "files-changed"],
        data() {
            return {
                showEditFileDialog: false,
                showAddFileDialog: false,
                editedFile: undefined,
                fileUtils: FileUtils,
            };
        },
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        methods: {
            deleteTask(i) {
                this.value = this.value.filter((el, index) => index !== i);
            },
            async newTask() {
                this.value = [...this.value, {text: "", level: ""}];
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 100));
                this.scrollToLastRow();
            },
            scrollToLastRow() {
                const newInputId = (this.value.length - 1) + "text";
                const element = document.getElementById(newInputId);
                element.focus();
            },
            deleteFile(id, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Czy na pewno usunąć plik?",
                    message: "Ta operacja jest nieodwracalna",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        deleteFile({id})
                            .then(() => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Pomyślnie usunięto plik",
                                    life: 3000,
                                });
                                this.onFilesChange();
                            })
                            .catch((error) => {
                                if (error.response.status === 403) {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie masz wystarczających uprawnień",
                                        life: 3000,
                                    });
                                } else {
                                    this.$toast.add({
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Wystąpił nieoczekiwany błąd",
                                        life: 3000,
                                    });
                                }
                            });
                    },
                    reject: () => {
                        // callback to execute when user rejects the action
                    },
                });
            },

            async downloadFile(file) {
                await FileUtils.downloadFileAsBlob(this, await downloadFileURL({id: file.id}), file.filename);
            },

            editFile(file) {
                this.editedFile = file;
                this.editedFile.filename = FileUtils.removeExtensionFromFilename(file.filename);
                this.showEditFileDialog = true;
            },

            openAddFileDialog() {
                this.showAddFileDialog = !this.showAddFileDialog;
            },
            onFilesChange() {
                this.$emit("files-changed");
            },
        },
    };
</script>

<style lang="less">
    @import "@/assets/theme/variable.less";
    .rank-print-files {
        border-top: 1px solid #eee;
        padding-top: 20px;
        color: @color-black-trans-70;
        .align-cell-baseline {
            vertical-align: baseline;
        }
    }
</style>
