<template>
    <div :key="$route.params.groupName" class="list-view">
        <h1>{{ pageHeader }}</h1>
        <div class="search-criteria public-view" @keyup.enter="search">
            <ChallengePublicSearchCriteria ref="userSearchCriteria" v-model="searchCriteria"/>
            <Toolbar>
                <template #start>
                    <PrimeButton icon="pi pi-search" class="p-button-rounded mr-3" @click="search" :disabled="loading"/>
                    <PrimeButton icon="pi pi-times" class="p-button-rounded p-button-outlined" @click="clear"/>
                </template>
            </Toolbar>
        </div>
        <BasicCardList :instruments="instruments" :optionView="'list'" :loading="loading"/>
    </div>
</template>

<script>

    import BasicCardList from "@/components/BasicCard/BasicCardList";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import ChallengePublicSearchCriteria
        from "@/views/challenge/components/ChallengePublicSearchCriteria";
    import {searchPublicChallengeUsingPOST as searchChellange} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {SearchCriteria} from "../../utils/SearchCriteria";

    export default {
        name: "ChallengePublicListView",
        components: {
            ChallengePublicSearchCriteria,
            BasicCardList,
            PrimeButton,
            Toolbar,
        },

        props: {
        },

        computed: {
            pageHeader() {
                switch (this.$route.params.groupName) {
                    case "cub-scouts":
                        return this.$t("message.headers.individualTasks");
                    default:
                        return this.$t("message.headers.challenges");
                }
            },
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                instruments: [],
                loading: true,
            };
        },
        beforeMount() {
            SearchCriteria.loadCriteria(this);
            this.challengeSearch({challengePublicSearchCriteria: this.searchCriteria});
        },

        mounted() {
        },

        methods: {
            search() {
                this.challengeSearch({challengePublicSearchCriteria: this.searchCriteria});
                SearchCriteria.updateUrl(this);
                this.searchCriteria.ageGroupName = this.$route.params.groupName;
            },
            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },
            challengeSearch(challengePublicSearchCriteria) {
                searchChellange(challengePublicSearchCriteria)
                    .then((response) => {
                        this.instruments = response.data;
                        // eslint-disable-next-line no-return-assign
                        this.instruments.forEach((val) => val.instrumentType = "challenge");
                        this.loading = false;
                    }).catch(() => {
                        ToastUtils.showError(this);
                    });
            },
            getClearSearchCriteria() {
                return {
                    ageGroupName: this.$route.params.groupName,
                    phrase: "",
                    developmentAreaId: "",
                    page: {
                        limit: 100,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
