<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-check-square"></i> Lista Celów Zrównoważonego Rozwoju</h1>

        <div class="search-criteria" @keyup.enter="search">
            <SustainableDevelopmentGoalSearchCriteria ref="sustainableDevelopmentGoalSearchCriteria"
                                                      v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj Cel Zrównoważonego Rozwoju" icon="pi pi-plus"
                             @click="createSustainableDevelopmentGoal"/>
            </template>
        </Toolbar>

        <SustainableDevelopmentGoalTable ref="sustainableDevelopmentGoalTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import SustainableDevelopmentGoalSearchCriteria
        from "./components/SustainableDevelopmentGoalSearchCriteria";
    import SustainableDevelopmentGoalTable from "./components/SustainableDevelopmentGoalTable";

    export default {
        name: "SustainableDevelopmentGoalListView",
        components: {
            SustainableDevelopmentGoalTable, SustainableDevelopmentGoalSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.sustainableDevelopmentGoalTable.search();
            },

            createSustainableDevelopmentGoal() {
                this.$router.push({name: "sustainableDevelopmentGoalCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
