<template>
    <div>
        <h2>{{ $t('message.headers.tasks') }}</h2>
        <div class="list-with-checkbox-outer">
            <ul>
                <li v-for="task in tasks" :key="task.id">
                    <div>
                        <span class="list-with-checkbox-name">{{ task.text }}</span>
                        <PrimeButton v-if="task.rank && isCubsRank"
                                     :label="task.rank.name"
                                     @click="goToCubsRankView(task.rank.id)"
                                     class="p-button-rounded small-button"/>
                        <PrimeButton v-if="task.rank && !isCubsRank"
                                     :label="task.rank.name"
                                     @click="goToMaleRankView(task.rank.id)"
                                     class="p-button-rounded small-button mr-2"/>
                        <PrimeButton v-if="task.rank && !isCubsRank"
                                     :label="task.rank.femaleName"
                                     @click="goToFemaleRankView(task.rank.id)"
                                     class="p-button-rounded small-button"/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    // import axios from "axios";
    import PrimeButton from "primevue/button";

    export default {
        name: "MeritBadgeTasks",
        components: {PrimeButton},

        props: {
            tasks: {
                type: Array,
            },
            isCubsRank: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
            goToMaleRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "male"}});
            },
            goToFemaleRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "female"}});
            },
            goToCubsRankView(rankId) {
                this.$router.push({name: "rankSingleView", params: {id: rankId, sex: "cub-scouts"}});
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
