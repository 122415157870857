<template>
    <div>
        <h2>{{ $t('message.headers.bibliographicHints') }}:</h2>
        <div class="list-with-checkbox-outer">
            <ul>
                <li v-for="bibliographicHint in bibliographicHints" :key="bibliographicHint.id">
                    <a :href="bibliographicHint.link"    >
                        {{ bibliographicHint.label }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: "TrailBadgePublicBibliographicHints",
        components: {},

        props: {
            bibliographicHints: {
                type: Array,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
</style>
