<template>
    <div class="my-6 flex-order-2 md:flex-order-1">
        <div class="flex gap-1 md:gap-3 mb-3 flex-wrap">
            <div class="font-bold flex-shrink-0" >Wiek: </div>
            <div>{{ basicInfo.age }}</div>
        </div>
        <div class="flex gap-1 md:gap-3 mb-3 flex-wrap">
            <div class="font-bold flex-shrink-0">Sprawności: </div>
            <div>{{ basicInfo.numberOfRequiredMeritBadges }}</div>
        </div>
        <div class="flex gap-1 md:gap-3 mb-3 flex-wrap" v-if="!basicInfo.subScoutRank">
            <div class="font-bold flex-shrink-0">Czas trwania próby: </div>
            <div>{{ basicInfo.attemptDuration }}</div>
        </div>
        <div class="flex gap-1 md:gap-3 mb-3 flex-wrap">
            <div class="font-bold flex-shrink-0">Tropy: </div>
            <div>{{ basicInfo.numberOfTrailBadges }}</div>
        </div>
        <div class="flex gap-1 md:gap-3 flex-wrap">
            <div class="font-bold flex-shrink-0">
                {{basicInfo.subScoutRank ? 'Zadania indywidualne:' : 'Wyzwania:'}}
            </div>
            <div>{{ basicInfo.numberOfChallenges }}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "RankSingleBasicInfo",
        components: {},

        props: {
            basicInfo: {
                type: Object,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
</style>
