<template>
    <div class="trail-badge-card-view" v-if="trailBadgeCardData">
        <div class="trail-badge-card-header" :style="'background-color:' + trailBadgeCardData.ageGroup.hexColor">
            <div class="category-name text-right uppercase font-semibold mb-4">
                {{ trailBadgeCardData.category.name }}
            </div>
            <div class="category-outer">
                <div class="img-outer flex mr-3 gap-2">
                    <div class="header-img flex-shrink-0" v-if="trailBadgeCardData.ageGroup.coverImageUrl">
                        <img :src="trailBadgeCardData.ageGroup.coverImageUrl" alt="">
                    </div>
                    <div class="header-img flex-shrink-0" v-if="trailBadgeCardData.category.coverImageUrl">
                        <img :src="trailBadgeCardData.category.coverImageUrl" alt="">
                    </div>
                </div>
                <div class="category-desc" v-if="trailBadgeCardData.category.description">
                    {{ trailBadgeCardData.category.description }}
                </div>
            </div>
        </div>
        <div class="trail-badge-card-add-data">
            <div class="add-data-field flex mb-5">
                <div class="add-data-label">Służba: </div>{{ trailBadgeCardData.duty ? 'TAK' : 'NIE' }}
            </div>
            <div class="add-data-field sustainable-development-goal">
                <div class="add-data-label">Cel zrównoważonego rozwoju: </div>
                <template v-for="goal in trailBadgeCardData.sustainableDevelopmentGoals" :key="goal">
                    <div class="add-data-field-inner flex align-items-center gap-5 mt-4">
                        <div class="add-data-field-img flex-shrink-0" v-if="goal.coverUrl">
                            <img :src="goal.coverUrl" alt="">
                        </div>
                        <div class="add-data-field-desc">
                            <div class="add-data-label">
                                Cel {{ goal.pointNumber }}:
                            </div>
                            <div class="add-data-name mt-2">
                                {{ goal.name }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="trail-badge-card-separator"
             :style="'background-color:' + trailBadgeCardData.ageGroup.hexColor"></div>
        <div class="title-outer">
            <h1 class="text-center pt-5">{{ trailBadgeCardData.name }}</h1>
            <div class="subtitle text-center">{{ trailBadgeCardData.introductionText }}</div>
        </div>
        <div class="introduction-realization-text text-white font-semibold py-2 my-3"
             :style="'background-color:' + trailBadgeCardData.ageGroup.hexColor">
            Poniżej znajdziecie gotowy pomysł do zrealizowania w zastępie.
            <br>Możecie postąpić zgodnie z propozycją lub wprowadzić do niej modyfikacje
        </div>
        <ol class="realization-plan-list list-with-big-counter">
            <li class="mb-3">
                <div>
                    <div class="font-semibold my-2"><span class="font-bold">Zaplanujcie</span>,
                        czyli zbierzcie potrzebne informacje,
                        ustalcie, co chcecie zrobić i podzielcie się obowiązkami:</div>
                    <div v-html="trailBadgeCardData.planText"></div>
                </div>
            </li>
            <li class="mb-3">
                <div>
                    <div class="font-bold my-2">Zróbcie, wykonajcie zadanie wedle własnego pomysłu, w którym
                        każdy członek zastępu ma swoją rolę do odegrania:</div>
                    <div class="list-with-checkbox-outer" v-html="trailBadgeCardData.toDoText"></div>
                </div>
            </li>
            <li class="mb-3">
                <div>
                    <div class="font-bold my-2">Podsumujcie, oceńcie w swoim gronie czy
                        udało wam się osiągnąć założone cele i czy każdy dołożył się do ich realizacji,
                        a następnie pochwalcie się swoimi osiągnięciami.</div>
                    <div v-html="trailBadgeCardData.summaryText"></div>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
    import {getTrailBadgeCardDataUsingGET as getTrailBadgeData} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "TrailBadgeCardView",
        data() {
            return {
                trailBadgeCardData: null,
            };
        },
        computed: {
        },
        beforeMount() {
            getTrailBadgeData({id: this.$route.params.id})
                .then((response) => {
                    this.trailBadgeCardData = response.data;
                }).catch(() => {
                    ToastUtils.showError(this);
                });
        },
    };
</script>

<style lang="less">
    @import "@/assets/theme/variable";
    @page {
        margin:  10mm 0 20mm 0;
        width: 8.27inch;
        height: 11.7inch;
    }
    @media print {
        .trail-badge-card-view {
            font-size: 18px;
            font-family: "Museo Sans";
            print-color-adjust: exact;
            .list-with-checkbox-outer {
                padding: 10px 0;
            }
            .trail-badge-card-header {
                min-height: 200px;
                padding: 7mm;
                color: @color-white;
                font-weight: bold;
                .img-outer {
                    float: left;
                }
                .header-img {
                    width: 100px;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .trail-badge-card-add-data {
                padding: 10mm 25mm;
            }
            .add-data-field-img {
                width: 120px;
                img {
                    max-width: 100%;
                }
            }
            .add-data-label {
                font-weight: bold;
                color: @color-navy-blue;
            }
            .add-data-name {
                font-size: 16px;
            }
            .trail-badge-card-separator {
                height: 5px;
                width: 100%;
            }
            .title-outer,
            .introduction-realization-text,
            .realization-plan-list {
                padding: 0 20mm;
                font-size: 17px;
                .subtitle {
                    font-size: 19px;
                }
                h1 {
                    color: @color-navy-blue;
                }
                ul {
                    li {
                        margin-bottom: 12px;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
</style>
