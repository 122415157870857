<template>
    <div>
        <Toast />
        <h1 class="page-header"><i class="pi pi-users"></i> Dane użytkownika</h1>
        <div class="container">
            <VeeForm v-if="loaded" @submit="saveUser" v-slot="{ isSubmitting }">
                <div class="p-grid user-form">
                    <div class="p-col-6">
                        <CustomInputText name="firstName" label="imię" v-model="user.firstName" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="lastName" label="nazwisko" v-model="user.lastName" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="email" label="e-mail" v-model="user.email" rules="required|email"/>
                    </div>
                    <div class="p-col-6">
                        <CustomPassword name="password" label="hasło" v-model="user.password" rules="validPassword"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template #end>
                                <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </VeeForm>
        </div>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {
        createOrUpdateUserUsingPOST as createOrUpdateUser,
        getUserUsingGET as getUser,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomPassword from "../../components/form/CustomPassword";
    import {SystemRole} from "../../utils/SystemRole";

    export default {
        name: "UserFormView",

        components: {
            PrimeButton, Toolbar, CustomInputText, CustomPassword, Toast, VeeForm,
        },

        props: {
            value: {},
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    password: null,
                    systemRole: SystemRole.ADMIN,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getUser({id: this.$route.params.id}).then((response) => {
                    this.user = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveUser() {
                createOrUpdateUser({userDto: this.user}).then(() => {
                    this.$toast.add({
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane użytkownika",
                        life: 3000,
                    });
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .user-form {
        width: 80%;
        max-width: 800px;
    }
</style>
