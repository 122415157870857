import moment from "moment";

/* eslint-disable */
export const DateUtils = {
    defaultDateFormat: "YYYY-MM-DD",
    defaultDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    timestampFormat: "YYYY-MM-DD-HH-mm-ss",
    dateFormat: "YYYY-MM-DD",
    defaultTimeOnlyFormat: "HH:mm",
    polishDateFormat: "DD MMMM YYYY",
    polishDateTimeFormat: "DD MMMM YYYY HH:mm:ss",

    displayDateTime(dateString) {
        return moment(dateString).format(this.defaultDateTimeFormat);
    },

    displayDate(dateString) {
        return moment(dateString).format(this.dateFormat);
    },

    isInThePast(dateString) {
        return moment().isSameOrAfter(dateString);
    },

    isInTheFuture(dateString) {
        return moment().isSameOrBefore(dateString);
    },

    dateAsString(date) {
        return moment(date).format(this.dateFormat);
    },

    getTimeOnly(date) {
        return moment(date).format(this.defaultTimeOnlyFormat);
    },

    displayDateOnlyInPolishFormat(date) {
        return moment(date).locale('pl').format(this.polishDateFormat)
    },

    displayDateTimeInPolishFormat(dateString) {
        return moment(dateString).locale('pl').format(this.polishDateTimeFormat);
    },

};
