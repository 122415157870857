<template>
    <div>
        <h2>{{ $t('message.headers.tasks') }}</h2>
        <div class="list-with-checkbox-outer">
            <ul>
                <li v-for="task in tasks" :key="task.id">
                    <div>
                        <span class="list-with-checkbox-name">{{ task.name }}</span>
                        <PrimeButton v-if="task.label" :label="task.label" type="submit"
                                     class="p-button-rounded small-button"/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    // import axios from "axios";
    import PrimeButton from "primevue/button";

    export default {
        name: "TrailBadgePublicTasks",
        components: {PrimeButton},

        props: {
            tasks: {
                type: Array,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
</style>
