<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            <i class="pi pi-shield"></i>
            {{ this.$route.params.id ? 'Edytuj sprawność' : 'Dodaj sprawność' }}
        </h1>
        <VeeForm v-if="loaded" v-slot="{ isSubmitting, validate, errors }">
            <div class="p-grid meritBadge-form">
                <div class="col-6">
                    <CustomSelectOneAgeGroup name="ageGroup" label="metodyka" return-type="object"
                                             v-model="meritBadge.ageGroup" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="name" label="nazwa wersji męskiej"
                                     v-model="meritBadge.name" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="female name" label="nazwa wersji żeńskiej"
                                     v-model="meritBadge.femaleName" rules="required"/>
                </div>
                <div class="col-6 mb-2">
                    <CustomCheckbox name="active" label="aktywny" v-model="meritBadge.active"/>
                </div>
                <div class="col-12" v-if="meritBadge.ageGroup  && cubAgeGroupSelected">
                    <CustomEditor v-model="meritBadge.description"
                                  label="wymagania" name="description"/>
                </div>
                <div class="col-12" v-if="meritBadge.ageGroup && scoutAgeGroupSelected">
                    <MeritBadgeDescriptions v-model="meritBadge.levelDescriptions"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneFile v-model="meritBadge.coverImage" label="grafika"
                                         name="coverImage"/>
                </div>
                <div class="col-6" v-if="meritBadge.ageGroup">
                    <CustomSelectOneCategory v-model="meritBadge.categoryId" name="categoryId"
                                             label="kategoria" :category-type="getCategoryType"
                                             rules="required"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneSpecialty v-model="meritBadge.specialtyId" name="specialtyId"
                                              label="specjalność"/>
                </div>
                <div class="col-6">
                    <CustomMultiselectLabel label="etykiety" name="label-multiselect"
                                            v-model="this.meritBadge.labelIds"/>
                </div>
                <div class="col-12">
                    <MeritBadgeTasksList v-model="meritBadge.taskList"/>
                </div>
                <div class="col-12">
                    <CustomInputText name="sourceUrl" label="link do źródła"
                                     v-model="meritBadge.sourceUrl" rules="url"/>
                </div>
                <div class="col-12">
                    <Toolbar class="actions">
                        <template #end>
                            <FormErrorMessage :has-errors="hasErrors(errors)"/>
                            <PrimeButton label="Zapisz" @click="validateAndSaveIfValid(validate)"
                                         icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
                <div class="col-12">
                    <MetadataComponent :data="meritBadge.metadata"/>
                </div>
                <div v-if="!creationMode" class="col-12">
                    <ReviewListComponent  :initial-reviews="meritBadge.reviews"
                                          :instrument-type="InstrumentType.MERIT_BADGE"
                                          :instrument-id="meritBadge.id" />
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateMeritBadgeUsingPOST as createOrUpdateMeritBadge,
        getMeritBadgeUsingGET as getMeritBadge,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import GoBack from "@/components/GoBack";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import CustomSelectOneAgeGroup from "@/components/form/CustomSelectOneAgeGroup";
    import CustomSelectOneCategory from "@/components/form/CustomSelectOneCategory";
    import CustomSelectOneSpecialty from "@/components/form/CustomSelectOneSpecialty";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomEditor from "@/components/form/CustomEditor";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomSelectOneFile from "@/components/form/CustomSelectOneFile";
    import {CategoryType} from "@/utils/CategoryTypes";
    import MeritBadgeTasksList from "@/views/meritBadge/components/MeritBadgeTasksList";
    import CustomMultiselectLabel from "@/components/form/CustomMultiselectLabel";
    import MetadataComponent from "@/components/data/MetadataComponent";
    import ReviewListComponent from "@/components/data/ReviewListComponent";
    import InstrumentType from "@/utils/InstrumentTypes";
    import MeritBadgeDescriptions from "@/views/meritBadge/components/MeritBadgeDescriptions";
    import FormErrorMessage from "@/components/FormErrorMessage";
    import {ScrollUtils} from "@/utils/ScrollUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "MeritBadgeFormView",

        components: {
            FormErrorMessage,
            MeritBadgeDescriptions,
            ReviewListComponent,
            CustomMultiselectLabel,
            MeritBadgeTasksList,
            CustomSelectOneFile,
            CustomEditor,
            CustomSelectOneAgeGroup,
            CustomSelectOneCategory,
            CustomSelectOneSpecialty,
            PrimeButton,
            Toolbar,
            CustomInputText,
            Toast,
            VeeForm,
            CustomCheckbox,
            MetadataComponent,
            GoBack,
        },

        props: {
            value: {},
        },

        data() {
            return {
                meritBadge: {
                    id: null,
                    name: null,
                    taskList: [],
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getMeritBadge({id: this.$route.params.id}).then((response) => {
                    this.meritBadge = response.data;
                    this.addMissingLevels();
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
                this.addMissingLevels();
            }
        },

        methods: {
            hasErrors(errorsObj) {
                if (errorsObj) {
                    return Object.keys(errorsObj)?.length > 0;
                }
                return false;
            },
            validateAndSaveIfValid(validate) {
                validate().then((result) => {
                    if (result.valid !== true) {
                        ScrollUtils.scrollToField(Object.keys(result.errors)[0]);
                    } else {
                        this.saveMeritBadge();
                    }
                }).catch(() => {
                    ToastUtils.showError(this);
                });
            },
            saveMeritBadge() {
                createOrUpdateMeritBadge({meritBadgeEditDto: this.meritBadge}).then(() => {
                    ToastUtils.showSuccess(this, "Zapisano dane");
                    this.$router.push({name: "meritBadgeList"});
                }).catch(() => {
                    ToastUtils.showError(this);
                });
            },
            createLevel(level) {
                return {description: null, level};
            },
            addMissingLevels() {
                if (!this.meritBadge.levelDescriptions) {
                    this.meritBadge.levelDescriptions = [];
                }
                for (let idx = 1; idx <= 4; idx += 1) {
                    if (!this.meritBadge.levelDescriptions?.find((el) => el.level === idx)) {
                        this.meritBadge.levelDescriptions.push(this.createLevel(idx));
                    }
                }
                this.meritBadge?.levelDescriptions?.sort((n1, n2) => n1.level - n2.level);
            },

        },
        computed: {
            InstrumentType() {
                return InstrumentType;
            },
            creationMode() {
                return !this.$route.params.id;
            },
            cubAgeGroupSelected() {
                if (!this.meritBadge) {
                    return undefined;
                }
                return this.meritBadge.ageGroup != null && this.meritBadge.ageGroup.cubsRank === true;
            },
            scoutAgeGroupSelected() {
                if (!this.meritBadge) {
                    return undefined;
                }
                return this.meritBadge.ageGroup != null && this.meritBadge.ageGroup.cubsRank === false;
            },
            getCategoryType() {
                return this.$route.params.groupName === this.cubAgeGroupSelected
                    ? CategoryType.SCOUT_MERIT_BADGE : CategoryType.CUB_MERIT_BADGE;
            },
        },
    };
</script>

<style lang="less" scoped>
    .meritBadge-form {

    }
</style>
