/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        login: "Login",
        password: "Hasło",
        action: {
            login: "Zaloguj",
            remindPassword: "Zapomniałeś hasła?",
        },
    },
    menu: {
        users: "Użytkownicy",
        cubScouts: "Zuchy",
        stars: "Gwiazdki",
        meritBadge: "Sprawności",
        trailBadge: "Tropy",
        individualTasks: "Zadania indywidualne",
        levels: "Stopnie",
        challenges: "Wyzwania",
        scouts: "Harcerze",
        seniorScouts: "Harcerze starsi",
        wanderers: "Wędrownicy",
    },
    other: {
        emptyTable: "Brak wyników",
        emptyValue: "Brak"
    },
    validations: {
        required: "Pole jest wymagane",
        email: "Niepoprawny format adresu e-mail",
        confirmed: "Wartości nie są takie same",
        min: "Wymagana długość: 0:{min}",
        max: "Maksymalna długość: 0:{max}",
        min_value: "Minimalna wartość: 0:{min_value}",
        max_value: "Maksymalna wartość: 0:{max_value}",
        is: "",
        regex: "",
        validPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
        notTheSame: "nie są takie same",
        url: "Należy wprowadzić poprawny adres http/https",
    },
    headers: {
        tasks: "Zadania",
        scouts: "Harcerze",
        scoutsFemale: "Harcerki",
        challenges: "Wyzwania",
        meritBadge: "Sprawności",
        trailBadge: "Tropy",
        individualTasks: "Zadania indywidualne",
        scoutLaw: "Prawo harcerskie",
        cubLaw: "Prawo zuchowe",
        specialty: "Specjalność",
        meritBadgeLevel: "Poziom sprawności",
        levelOfAdvancement: "Poziom zaawansowania",
        service: "Służba",
        sustainableDevelopmentGoal: "Cel zrównoważonego rozwoju",
        sustainableDevelopmentGoals: "Cele zrównoważonego rozwoju",
        realizationPlan: "Plan realizacji",
        realizationPlanSubHeader: "Poniżej znajdziecie gotowy pomysł do zrealizowania w zastępie. Możecie postąpić zgodnie z propozycją lub wprowadzić do niej modyfikacje.",
        tipsForStaff: "Wskazówki dla kadry",
        bibliographicHints: "Wskazówki bibliograficzne",
    },
    buttons: {
        downloadSampleCard: "Pobierz kartę próby",
    },
};
