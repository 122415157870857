<template>
    <div>
        <CustomMultiSelect :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                           item-label="name" item-value="id" :label="label"
                           rules="required" scrollHeigh="400"/>
    </div>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiselect";

    export default {
        name: "CustomMultiselectLevel",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [
                    {
                        id: 1,
                        name: "*",
                    },
                    {
                        id: 2,
                        name: "**",
                    },
                    {
                        id: 3,
                        name: "***",
                    },
                ],
            };
        },

        components: {CustomMultiSelect},
    };
</script>

<style scoped>

</style>
