<template>
    <div class="merit-badge-task-edit mt-3">
        <div class="flex-box  justify-content-between">
            <h4 style="display: inline;" class="header">Lista zadań</h4>
            <div class="my-4">
                <PrimeButton label="Dodaj zadanie" icon="pi pi-plus" class="" @click="newTask"/>
            </div>
        </div>
        <DataTable :value="value" :dataKey="id" class="editable-cells-table mb-4" responsiveLayout="scroll"
                   @rowReorder="onRowReorder" columnResizeMode="">
            <template #empty>
                Brak zadań
            </template>
            <Column :rowReorder="true" headerStyle="width: 1%; white-space: nowrap"
                    :reorderableColumn="false" />
            <Column field="text" header="treść zadania" bodyClass="align-cell-baseline">
                <template #body="{ data, field, index }">
                    <CustomInputText v-model="data[field]" style="width: 100%"
                                     :name="index + field" placeholder="treść zadania"
                                     rules="required"/>
                </template>
            </Column>
            <Column headerStyle="width: 1%; white-space: nowrap" field="level" header="poziom"
                    bodyClass="align-cell-baseline">
                <template #body="{ data, field, index }">
                    <CustomSelectOneLevel v-model="data[field]" :name="index + field" rules="required"
                                          :show-label="false"/>
                </template>
            </Column>
            <Column headerStyle="width: 1%; white-space: nowrap" field="rankId" header="stopień"
                    bodyClass="align-cell-baseline">
                <template #body="{ data, field, index }">
                    <CustomSelectOneRank v-model="data[field]" :name="index + field"
                                         :show-label="false"/>
                </template>
            </Column>
            <Column headerStyle="width: 1%; white-space: nowrap" header="usuń">
                <template #body="{ index }">
                    <PrimeButton type="button" icon="pi pi-trash" @click="deleteTask(index)"
                                 class="p-button-rounded p-button-text p-button-plain"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>

    import DataTable from "primevue/datatable";
    import PrimeButton from "primevue/button";
    import Column from "primevue/column";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOneLevel from "@/components/form/CustomSelectOneLevel";
    import CustomSelectOneRank from "@/components/form/CustomSelectOneRank";

    export default {
        name: "MeritBadgeTasksList",
        components: {
            DataTable,
            PrimeButton,
            Column,
            CustomInputText,
            CustomSelectOneLevel,
            CustomSelectOneRank,
        },
        props: {
            modelValue: Array,
        },
        emits: ["update:modelValue"],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
        methods: {
            onRowReorder(event) {
                this.value = event.value;
            },
            deleteTask(i) {
                this.value = this.value.filter((el, index) => index !== i);
            },
            async newTask() {
                this.value = [...this.value, {text: "", level: ""}];
                // eslint-disable-next-line no-promise-executor-return
                await new Promise((r) => setTimeout(r, 100));
                this.scrollToLastRow();
            },
            scrollToLastRow() {
                const newInputId = (this.value.length - 1) + "text";
                const element = document.getElementById(newInputId);
                element.focus();
            },
        },
    };
</script>

<style lang="less">
    @import "../../../assets/theme/variable.less";
    .merit-badge-task-edit {
        color: @color-black-trans-70;
        .align-cell-baseline {
            vertical-align: baseline;
        }
    }
</style>
