<template>
    <div>
        <div class="grid flex align-items-end grid">
            <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                <CustomInputText name="firstName" label="nazwa" v-model="internalValue.phrase"/>
            </div>
            <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                <CustomSelectOneCategory v-model="internalValue.categoryId" name="categoryId"
                                         label="kategoria" :category-type="getCategoryType"/>
            </div>
            <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                <CustomMultiselectSustainableDevelopmentGoal v-model="internalValue.sustainableDevelopmentGoalIds"
                                                             name="name"
                                                             label="cel zrównoważonego rozwoju"/>
            </div>
        </div>
        <LabelsList v-model="internalValue.labelIds" class="mb-4" :badge-type="BadgeType.TRAIL_BADGE" />
    </div>
</template>

<script>
    import CustomSelectOneCategory from "@/components/form/CustomSelectOneCategory";
    import CustomMultiselectSustainableDevelopmentGoal
        from "@/components/form/CustomMultiselectSustainableDevelopmentGoal";
    import CustomInputText from "@/components/form/CustomInputText";
    import LabelsList from "@/components/Labels/LabelsList";
    import {CategoryType} from "@/utils/CategoryTypes";
    import BadgeType from "@/utils/BadgeTypes";

    export default {
        name: "TrailBadgePublicSearchCriteria",
        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            BadgeType() {
                return BadgeType;
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            getCategoryType() {
                return this.$route.params.groupName !== "cub-scouts"
                    ? CategoryType.SCOUT_TRAIL_BADGE : CategoryType.CUB_TRAIL_BADGE;
            },
        },

        components: {
            CustomInputText,
            CustomSelectOneCategory,
            LabelsList,
            CustomMultiselectSustainableDevelopmentGoal,
        },
    };
</script>

<style scoped>

</style>
