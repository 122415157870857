<template>
    <div>
        <h1 class="page-header" :key="$route.fullPath">
            <i class="pi pi-cog"></i>
            Tekst w stopce
        </h1>
        <VeeForm v-if="loaded" @submit="saveConfig" v-slot="{ isSubmitting }">
            <div class="p-grid config-form">
                <div class="col-12">
                    <CustomEditor name="content-editor" label="treść" v-model="config.value"
                                  rules="required" :height="500"/>
                </div>
                <div class="col-12">
                    <Toolbar class="actions">
                        <template #start>
                            <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateConfigUsingPOST as createOrUpdateConfig,
        getConfigByKeyUsingGET as getConfigByKey,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomEditor from "@/components/form/CustomEditor";

    export default {
        name: "ConfigFormView",

        components: {
            PrimeButton, Toolbar, CustomEditor, Toast, VeeForm,
        },

        props: {
            value: {},
        },

        data() {
            return {
                config: {
                    id: null,
                    key: "footerContent",
                    value: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            getConfigByKey({key: "footerContent"}).then((response) => {
                this.config = response.data;
                this.loaded = true;
            }).catch(() => {
                this.loaded = true;
            });
        },

        methods: {
            saveConfig() {
                createOrUpdateConfig({configDto: this.config}).then(() => {
                    ToastUtils.showSuccess(this, "Zapisano dane");
                    this.$router.push({name: "homeView"});
                }).catch(() => ToastUtils.showError(this));
            },
        },
    };
</script>

<style lang="less" scoped>
    .config-form {
    }
</style>
