<template>
    <div>
        <CustomMultiSelect :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                           item-label="rule" item-value="id" :label="label" v-if="!loading"
                           rules="required" scrollHeigh="400"/>
    </div>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiselect";
    import {getAllScoutLawsByTypeUsingGET as getAll} from "../../swagger/vue-api-client";

    export default {
        name: "CustomMultiselectScoutLaw",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
            ageGroupType: {
                type: Symbol,
                required: true,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        watch: {
            ageGroupType() {
                this.fetchData();
            },
        },

        data() {
            return {
                items: [],
                loading: true,
            };
        },

        beforeMount() {
            this.fetchData();
        },

        methods: {
            fetchData() {
                this.loading = true;
                getAll({type: this.ageGroupType.description}).then((response) => {
                    // eslint-disable-next-line no-return-assign
                    response.data.forEach((val) => val.rule = val.pointNumber + ". " + val.rule);
                    this.items = response.data;
                    this.loading = false;
                });
            },
        },

        components: {CustomMultiSelect},
    };
</script>

<style scoped>

</style>
