<template>
    <div class="p-fluid custom-input-textarea">
        <label v-if="label" :for="name" class="input-text-label">{{ label }}</label>
        <Textarea :id="name" v-model="internalValue" :class="computedClasses" :disabled="disabled"
                  :placeholder="placeholder" :rows="rows" cols="30" @blur="handleBlur"
                  @input="handleChange"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Textarea from "primevue/textarea";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputTextarea",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            rows: {
                type: Number,
                default: 5,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: null,
            },
            rules: undefined,
            modelValue: null,
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Textarea},
    };
</script>

<style lang="less" scoped>
@import "../../assets/theme/variable";

.custom-input-textarea {
  margin-bottom: 10px;
  text-align: left;
}

.input-text-label {
  margin-left: 0.5em;
  font-weight: 300;
  color: @color-black-trans-70;
  font-size: 18px;
}
</style>
