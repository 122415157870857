<template>
    <div class="simple-layout-wrapper">
        <div class="main p-fluid">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutSimple",

    };
</script>

<style lang="less">
    @import "../assets/less/layout-simple.less";
</style>
