<template>
    <div>
        <Toast />
        <DataTable ref="developmentAreaTable" :value="levels" :lazy="true" :paginator="true"
                   v-model:rows="searchParams.page.limit" v-model:first="searchParams.page.offset" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onPage" :rowsPerPageOptions="[5,10,20,50]">
            <Column field="name" header="nazwa" :sortable="true"></Column>
            <Column headerStyle="width: 160px">
                <template #body="slotProps">
                    <div class="flex gap-1">
                        <router-link :to="{name: 'developmentAreaEdit', params: {'id': slotProps.data.id}}">
                            <PrimeButton type="button" icon="pi pi-pencil" class="p-button-success"></PrimeButton>
                        </router-link>
                        <PrimeButton type="button" icon="pi pi-trash"
                                     class="p-button-danger"
                                     @click="deleteDevelopmentArea(slotProps.data.id)">
                        </PrimeButton>
                    </div>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import {
        deleteDevelopmentAreaUsingDELETE as deleteDevelopmentArea,
        searchDevelopmentAreaCountUsingPOST as searchDevelopmentAreaCount,
        searchDevelopmentAreaUsingPOST as searchDevelopmentArea,
    } from "@/swagger/vue-api-client";
    import Toast from "primevue/toast";
    import PrimeButton from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SearchCriteria} from "@/utils/SearchCriteria";

    export default {
        name: "DevelopmentAreaTable",
        components: {
            PrimeButton, Column, DataTable, Toast,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
        },

        emits: ["update:searchCriteria"],

        computed: {
            searchParams: {
                get() {
                    return this.searchCriteria;
                },
                set(value) {
                    this.$emit("update:searchCriteria", value);
                },
            },
        },

        data() {
            return {
                loading: false,
                totalRecords: 0,
                levels: [],
            };
        },

        mounted() {
            this.updateTotalRecords();
            this.onPage(this.getFirstPage());
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage());
                this.updateTotalRecords();
            },

            async onPage(event) {
                this.loading = true;

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                this.searchParams.page.sortField = event.sortField;
                this.searchParams.page.sortOrder = event.sortOrder;
                this.searchParams = await this.searchCriteria;

                searchDevelopmentArea({searchCriteria: this.searchParams}).then((response) => {
                    this.levels = response.data;
                    this.loading = false;
                });
                SearchCriteria.updateUrl(this);
            },

            updateTotalRecords() {
                searchDevelopmentAreaCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage() {
                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    sortField: this.searchCriteria.page.sortField,
                    sortOrder: this.searchCriteria.page.sortOrder,
                };
            },

            deleteDevelopmentArea(developmentAreaId) {
                this.$confirm.require({
                    message: "Ta operacja jest nieodwracalna",
                    header: "Czy na pewno chcesz usunąć obszar rozwoju?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "tak",
                    rejectLabel: "nie",
                    accept: () => {
                        deleteDevelopmentArea({id: developmentAreaId}).then(() => {
                            this.search();
                            this.$toast.add({
                                severity: "success",
                                summary: "Obszar rozwoju usunięty",
                                detail: "Udało się usunąć obszar rozwoju",
                                life: 3000,
                            });
                        }).catch(() => {
                            this.$toast.add({
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                                life: 3000,
                            });
                        });
                    },
                });
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
