<template>
    <figure v-lazyload class="imageWrapper">
        <img
            class="imageItem"
            :data-url="source"
            :alt="alt">
    </figure>
</template>

<script>
    export default {
        name: "LazyImageItem",
        components: {
        },
        props: {
            source: {
                type: String,
                required: true,
            },
            alt: {
                type: String,
                required: false,
                default: null,
            },
        },
    };
</script>

<style scoped lang="less">
</style>
