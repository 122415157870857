<template>
    <div class="grid flex align-items-end">
        <div class="sm:col-6 md:col-4 xl:col-3 col-12">
            <CustomInputText name="firstName" label="nazwa" v-model="internalValue.phrase"/>
        </div>
        <div class="sm:col-6 md:col-4 xl:col-3 col-12">
            <CustomSelectOneDevelopmentArea name="firstName" label="obszar rozwoju"
                                            v-model="internalValue.developmentAreaId"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneDevelopmentArea from "@/components/form/CustomSelectOneDevelopmentArea";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "ChallengePublicSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomInputText, CustomSelectOneDevelopmentArea,
        },

        methods: {
        },
    };
</script>

<style scoped>

</style>
