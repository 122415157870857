<template>
    <div class="layout-topbar" :key="loggedIn">
        <div class="app-top-bar-mobile">
            <div class="layout-topbar-icons">
                <MenuBar :model="items" class="p-2"/>
            </div>
        </div>
        <div class="sidebar-mobile">
            <button class="p-link layout-menu-button menu-button-sidebar" @click="onMenuSidebarToggle">
                <span class="pi pi-bars"></span>
            </button>
            <router-link :to="{name: 'homeView'}" class="img-mobile">
                <img src="@/assets/logo.svg" alt="logo">
            </router-link>
        </div>
        <div class="app-top-bar-desktop">
            <div v-if="isDetailView" class="go-back-outer">
                <GoBack />
            </div>
            <div v-if="!isDetailView" class="page-name"
                 :class="pageName ===  'Zadania indywidualne'? 'long-name' : ''"
                 v-html="pageName" />
            <div class="layout-topbar-icons">
                <MenuBar :model="items" class="p-2"/>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuBar from "primevue/menubar";
    import {SystemRole} from "@/utils/SystemRole";
    import GoBack from "@/components/GoBack";
    import authAzureService from "@/auth-azure.service";
    import store from "@/store";

    export default {
        data() {
            return {
                windowTop: "",
                envDesc: process.env.VUE_APP_ENV_DESC,
                fullName: authAzureService.loggedUserFullName,
                isLongName: false,
                items: [
                    {
                        label: "Instrumenty",
                        icon: "pi pi-fw pi-database",
                        visible: () => SystemRole.isAdminOrModerator(),
                        items: [
                            {
                                label: "Stopnie",
                                icon: "pi pi-fw pi-bookmark-fill",
                                to: {name: "rankList"},
                            },
                            {
                                label: "Sprawności",
                                icon: "pi pi-fw pi-shield",
                                to: {name: "meritBadgeList"},
                            },
                            {
                                label: "Tropy",
                                icon: "pi pi-fw pi-map",
                                to: {name: "trailBadgeList"},
                            },
                            {
                                label: "Wyzwania",
                                icon: "pi pi-fw pi-verified",
                                to: {name: "challengeList"},
                            },
                        ],
                    },
                    {
                        label: "Słowniki",
                        icon: "pi pi-fw pi-book",
                        visible: () => SystemRole.isAdmin(),
                        items: [
                            {
                                label: "Kategorie",
                                icon: "pi pi-fw pi-th-large",
                                to: {name: "categoryList"},
                            },
                            {
                                label: "Etykiety",
                                icon: "pi pi-fw pi-tags",
                                to: {name: "labelList"},
                            },
                            {
                                label: "Obszary rozwoju",
                                icon: "pi pi-fw pi-chart-pie",
                                to: {name: "developmentAreaList"},
                            },
                            {
                                label: "Poziomy zaawansowania tropów",
                                icon: "pi pi-fw pi-chart-bar",
                                to: {name: "advancementLevelList"},
                            },
                            {
                                label: "Specjalności",
                                icon: "pi pi-fw pi-star",
                                to: {name: "specialtyList"},
                            },
                            {
                                label: "Prawo harcerskie",
                                icon: "pi pi-fw pi-table",
                                to: {name: "scoutLaw", query: {type: "SCOUT"}},
                            }, {
                                label: "CZR",
                                icon: "pi pi-fw pi-check-square",
                                to: {name: "sustainableDevelopmentGoalList"},
                            },
                        ],
                    },
                    {
                        label: "Ustawienia",
                        icon: "pi pi-fw pi-wrench",
                        visible: () => SystemRole.isAdmin(),
                        items: [
                            {
                                label: "Pliki",
                                icon: "pi pi-fw pi-file-import",
                                to: {name: "filesList"},
                            },
                            {
                                label: "Konfiguracja",
                                icon: "pi pi-cog",
                                to: {name: "configForm"},
                            },
                        ],
                    },
                    {
                        icon: "pi pi-fw pi-user",
                        visible: () => authAzureService.isLoggedIn(),
                        items: [
                            {
                                label: () => authAzureService.getUserName(),
                                class: "user-info",
                            },
                            {
                                label: "Wyloguj",
                                icon: "pi pi-fw pi-power-off",
                                command: () => this.logout(),
                            },
                        ],
                    },
                    {
                        label: "Zaloguj",
                        icon: "pi pi-fw pi-sign-in",
                        visible: () => !authAzureService.isLoggedIn(),
                        command: () => this.login(),
                        class: "user-login",
                    },
                ],
            };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        computed: {
            pageName() {
                if (this.$route.params.groupName === "cub-scouts"
                    && this.$route.name === "challengePublicListView") {
                    return "Zadania indywidualne";
                }
                if (this.$route.params.groupName === "cub-scouts"
                    && this.$route.name === "rankListView") {
                    return "Gwiazdki";
                }
                return this.$route.meta.title;
            },
            loggedIn() {
                return store.state.loggedIn;
            },
            isDetailView() {
                if (this.$route.meta.viewType === "single") {
                    return true;
                }
                return false;
            },
        },
        components: {MenuBar, GoBack},
        methods: {
            onMenuSidebarToggle(event) {
                this.$emit("menu-toggle", event);
            },
            login() {
                authAzureService.appSignIn();
            },
            logout() {
                authAzureService.logout();
            },
            onScroll() {
                if (window.innerWidth > 767) {
                    this.windowTop = window.top.scrollY;
                    if (this.windowTop > 10) {
                        document.querySelector(".layout-topbar").classList.add("scrolled");
                    } else {
                        document.querySelector(".layout-topbar").classList.remove("scrolled");
                    }
                }
            },
        },
    };
</script>

<style lang="less">
    @import "../../src/components/assets/less/app-top-bar";
    .layout-topbar {
        .app-top-bar-mobile {
            display: none;
        }
        .app-top-bar-desktop,
        .app-top-bar-mobile {
            .p-menubar-button {
                display: none;
            }
        }
        .sidebar-mobile {
            display: none;
        }
        .app-top-bar-desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .layout-topbar-icons {
                margin-left: auto;
            }
        }
        @media only screen and (max-width: 1050px) and (min-width: 1024px) {
            .p-menubar .p-menuitem-link {
                padding-inline: 10px;
            }
        }
        @media only screen and (max-width: @media-breakpoint-down-md) {
            .sidebar-mobile {
                display: block;
                position: relative;
                padding: 5px 0;
                border-bottom: 1px solid @color-gray;
                background-color: white;
                .img-mobile {
                    width: 134px;
                    display: flex;
                    margin: 0 auto;
                }
                .menu-button-sidebar {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &, .menu-button-sidebar {
            background-color: white;
            }
            .app-top-bar-desktop {
                display: none;
            }
            .app-top-bar-mobile {
                display: block;
                border-bottom: 1px solid @color-gray;
                .layout-topbar-icons {
                    justify-content: center;
                }
                .p-menubar .p-menuitem-link {
                    padding-right: .2rem;
                    padding-top: .6rem;
                    padding-bottom: .6rem;
                }
                .p-menubar .p-menubar-root-list {
                    display: flex;
                    position: static;
                    box-shadow: none;
                    padding: 0;
                    .p-menuitem {
                        width: fit-content;
                        .p-menuitem-text {
                            display: none;
                        }
                        &.user-login {
                            .p-menuitem-text {
                                display: block;
                            }
                        }
                    }
                }
                .p-menubar .p-submenu-list {
                    position: absolute;
                    border: 1px solid @color-gray;
                    .p-menuitem {
                        .p-menuitem-link {
                            padding-left: 1.5rem;
                        }
                        .p-menuitem-text {
                            display: block;
                        }
                    }
                }
            }
        }
        .p-menubar {
            .user-info {
                .p-menuitem-link:not(.p-disabled) {
                    cursor: default;
                    &:hover {
                        background-color: @color-white;
                    }
                }
            }
        }
    }
</style>
