<template>
    <Dialog :header="editing ? 'Edytuj plik' : 'Dodaj plik'" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}">
        <VeeForm @submit="addOrEditFile" v-slot="{ isSubmitting }">
            <div class="d-flex flex-column align-items-center gap-4">
                <div class="p-col-12">
                    <CustomInputText name="filename" v-model="fileData.filename"
                                     label="nazwa pliku"
                                     icon="pi pi-book"
                                     rules="required"/>
                    <CustomInputTextArea name="label" v-model="fileData.label"
                                         label="etykieta"
                                         rules="required"/>
                    <CustomFileUpload name="file" v-model="file " :rules="editing? '' : 'requiredFile'"
                                      :label="'Wybierz plik'"/>
                    <span v-if="editing" class="p-mb-3 mt-3">
                        Jeśli chcesz zmienić tylko nazwę/etykietę - nie załączaj pliku.
                    </span>
                </div>
            </div>
            <div class="col-12">
                <Toolbar class="actions">
                    <template #end>
                        <PrimeButton label="Anuluj" icon="pi pi-times" class="p-button-text"
                                     @click="closeDialog" :disabled="isSubmitting"/>
                        <PrimeButton label="Zapisz" icon="pi pi-check"
                                     type="submit" :disabled="isSubmitting" />
                    </template>
                </Toolbar>
            </div>

        </VeeForm>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import PrimeButton from "primevue/button";
    import CustomInputText from "@/components/form/CustomInputText";
    import {Form as VeeForm} from "vee-validate";
    import Toolbar from "primevue/toolbar";
    import {
        updateRankPrintFileUsingPUT as updateFile,
        uploadRankPrintFileUsingPOST as uploadFile,
    } from "@/swagger/vue-api-client";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import {FileUtils} from "@/utils/FileUtils";

    export default {
        name: "FileFormDialog",
        components: {
            CustomFileUpload,
            CustomInputTextArea,
            Dialog,
            PrimeButton,
            CustomInputText,
            VeeForm,
            Toolbar,
        },
        mixins: [FileUtils],
        emits: ["update:visible", "file-added"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
            editing: {
                type: Boolean,
                required: true,
            },
            editedFile: {
                type: Object,
                required: false,
                default: () => {},
            },
            rankId: {
                type: Number,
            },
        },

        data() {
            return {
                file: undefined,
                fileData: this.getEmptyFile(),
            };
        },

        watch: {
            editedFile() {
                this.fileData = JSON.parse(JSON.stringify(this.editedFile));
            },
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
        },

        methods: {
            getEmptyFile() {
                return {
                    label: "",
                    filename: "",
                    encodedData: "",
                    rankId: null,
                };
            },
            async addOrEditFile() {
                if (this.editing) {
                    await this.editFile();
                } else {
                    await this.addFile();
                }
            },
            async editFile() {
                // jeśli this.file posiada atrybut name to znaczy, że ktoś podmienił plik
                this.fileData.rankId = this.rankId;
                if (this.file && this.file.name) {
                    this.fileData.encodedData = await FileUtils.fileToBase64(this.file);
                }
                await Promise.resolve(updateFile({rankPrintFileDataDto: this.fileData})).then(() => {
                    this.$emit("file-added");
                    this.internalVisible = false;
                    this.file = undefined;
                    this.fileData = this.getEmptyFile();
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            async addFile() {
                this.fileData.rankId = this.rankId;
                this.fileData.encodedData = await FileUtils.fileToBase64(this.file);

                await Promise.resolve(
                    uploadFile({rankPrintFileDataDto: this.fileData}),
                ).then(() => {
                    this.$emit("file-added");
                    this.internalVisible = false;
                    this.file = undefined;
                    this.fileData = this.getEmptyFile();
                }).catch((error) => {
                    if (error.response.status === 403) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd",
                            life: 3000,
                        });
                    }
                });
            },

            closeDialog() {
                this.internalVisible = false;
                this.file = undefined;
                this.fileData = this.getEmptyFile();
            },

        },
    };
</script>

<style lang="less" scoped>
</style>
