<template>
    <div>
        <CustomMultiSelect :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                           item-label="name" item-value="id" :label="label" v-if="!loading"
                           rules="required" scroll-height="400px" />
    </div>
</template>

<script>
    import CustomMultiSelect from "@/components/form/inner/CustomMultiselect";
    import {getAllLabelsUsingGET as getLabels} from "@/swagger/vue-api-client";

    export default {
        name: "CustomMultiselectLabel",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: null,
                loading: true,
            };
        },
        beforeMount() {
            getLabels().then((response) => {
                this.items = response.data;
                this.loading = false;
            });
        },

        components: {CustomMultiSelect},
    };
</script>

<style scoped>

</style>
