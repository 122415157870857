<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-map"></i> Lista Tropów</h1>

        <div class="search-criteria" @keyup.enter="search">
            <TrailBadgeSearchCriteria ref="trailBadgeSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj trop" icon="pi pi-plus" @click="createTrailBadge"/>
            </template>
        </Toolbar>

        <TrailBadgeTable ref="trailBadgeTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import TrailBadgeSearchCriteria from "./components/TrailBadgeSearchCriteria";
    import TrailBadgeTable from "./components/TrailBadgeTable";

    export default {
        name: "TrailBadgeListView",
        components: {
            TrailBadgeTable, TrailBadgeSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.trailBadgeTable.search();
            },

            createTrailBadge() {
                this.$router.push({name: "trailBadgeCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
