<template>
    <div>
        <Transition>
            <div :class="['basic-card-list', optionView]" v-if="instruments.length > 0">
                <BasicCard v-for="(instrument, index) in instruments" :key="instrument.id + index"
                           :instrument="instrument" :optionView="optionView"/>
            </div>
        </Transition>
        <div v-if="loading" class="flex justify-content-center">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <Transition>
            <div v-if="instruments.length === 0 && loading === false">Brak wyników</div>
        </Transition>
    </div>
</template>

<script>

    import BasicCard from "./BasicCard";

    export default {
        name: "BasicCardList",
        components: {BasicCard},

        props: {
            instruments: {
                type: Array,
            },
            optionView: {
                type: String,
                default: "cards",
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
    @import "@/assets/theme/variable.less";
    .basic-card-list {
        word-break: break-word;
        &.cards {
            display: grid;
            grid-template-columns: repeat(5, minmax(0,1fr));
            gap: 30px 20px;
            @media only screen and (max-width: 1600px) {
                grid-template-columns: repeat(4, minmax(0,1fr));
            }
            @media only screen and (max-width: 1400px) {
                grid-template-columns: repeat(3, minmax(0,1fr));
            }
            @media only screen and (max-width: @media-breakpoint-down-lg) {
                grid-template-columns: repeat(3, minmax(0,1fr));
            }
            @media only screen and (max-width: @media-breakpoint-down-md) {
                grid-template-columns: repeat(3, minmax(0,1fr));
            }
            @media only screen and (max-width: @media-breakpoint-down-sm) {
                grid-template-columns: repeat(2, minmax(0,1fr));
            }
        }
    }
</style>
