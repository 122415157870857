<template>
    <div class="list-view">
        <h1 class="page-header">
            <i class="pi pi-table" />
            {{ this.$route.query.type === "CUB"? $t('message.headers.cubLaw') : $t('message.headers.scoutLaw') }}
        </h1>
        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj nowy punkt prawa" icon="pi pi-plus" @click="createScoutLaw"/>
            </template>
        </Toolbar>
        <TabView v-model:activeIndex="activeIndex" @tab-change="print($event)">
            <TabPanel header="Prawo harcerskie">
                <ScoutLawTable age-group-type="SCOUT"/>
            </TabPanel>
            <TabPanel header="Prawo zuchowe">
                <ScoutLawTable age-group-type="CUB"/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import ScoutLawTable from "@/views/scoutLaw/components/ScoutLawTable";
    import Toolbar from "primevue/toolbar";
    import TabView from "primevue/tabview";
    import TabPanel from "primevue/tabpanel";

    export default {
        name: "RankSinglescoutLaw",
        components: {
            PrimeButton, ScoutLawTable, Toolbar, TabView, TabPanel,
        },

        props: {
        },
        data() {
            return {
                activeIndex: this.$route.query.type === "CUB" ? 1 : 0,
            };
        },

        computed: {},
        beforeMount() {
        },

        mounted() {
        },

        methods: {
            createScoutLaw() {
                this.$router.push({name: "scoutLawAdd"});
            },
            print(event) {
                switch (event.index) {
                    case 0:
                        this.$router.replace({path: this.$route.path, query: {type: "SCOUT"}});
                        break;
                    case 1:
                        this.$router.replace({path: this.$route.path, query: {type: "CUB"}});
                        break;
                    default:
                        this.$router.replace({path: this.$route.path});
                }
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
