<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            <i class="pi pi-verified"></i>
            {{ this.$route.params.id ?
                'Edytuj wyzwanie lub zadanie indywidualne' : 'Dodaj wyzwanie lub zadanie indywidualne' }}
        </h1>
        <VeeForm v-if="loaded" v-slot="{ isSubmitting, validate, errors }">
            <div class="p-grid challenge-form">
                <div class="col-6">
                    <CustomSelectOneAgeGroup name="ageGroup" label="metodyka"
                                             v-model="challenge.ageGroup" rules="required"
                                             return-type="object"/>
                </div>
                <div class="col-6">
                    <CustomCheckbox name="active" label="aktywny" v-model="challenge.active"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneDevelopmentArea name="developmentAreaId" label="obszar rozwoju"
                                                    v-model="challenge.developmentAreaId" rules="required" />
                </div>
                <div class="col-12">
                    <CustomEditor name="content" label="treść wyzwania" v-model="challenge.content"
                                  rules="required"/>
                </div>
                <div class="col-6" v-if="ageGroupSelected">
                    <CustomMultiselectScoutLaw name="scoutLawIds" :label="lawLabel"
                                               v-model="challenge.scoutLawListIds"
                                               :age-group-type="selectedAgeGroupType"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="sourceUrl" label="link do źródła"
                                     v-model="challenge.sourceUrl" rules="url"/>
                </div>
                <div class="col-6">
                    <Toolbar class="actions">
                        <template #end>
                            <FormErrorMessage :has-errors="hasErrors(errors)"/>
                            <PrimeButton label="Zapisz" @click="validateAndSaveIfValid(validate)"
                                         icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
                <div class="col-12">
                    <MetadataComponent :data="challenge.metadata"/>
                </div>
                <div v-if="!creationMode" class="col-12">
                    <ReviewListComponent  :initial-reviews="challenge.reviews"
                                          :instrument-type="InstrumentType.CHALLENGE"
                                          :instrument-id="challenge.id" />
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateChallengeUsingPOST as createOrUpdateChallenge,
        getChallengeUsingGET as getChallenge,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import GoBack from "@/components/GoBack";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import CustomSelectOneAgeGroup from "@/components/form/CustomSelectOneAgeGroup";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomEditor from "@/components/form/CustomEditor";
    import MetadataComponent from "@/components/data/MetadataComponent";
    import CustomSelectOneDevelopmentArea from "@/components/form/CustomSelectOneDevelopmentArea";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomMultiselectScoutLaw from "@/components/form/CustomMultiselectScoutLaw";
    import CustomInputText from "@/components/form/CustomInputText";
    import InstrumentType from "@/utils/InstrumentTypes";
    import ReviewListComponent from "@/components/data/ReviewListComponent";
    import AgeGroupType from "@/utils/AgeGroupTypes";
    import FormErrorMessage from "@/components/FormErrorMessage";
    import {ScrollUtils} from "@/utils/ScrollUtils";

    export default {
        name: "ChallengeFormView",

        components: {
            FormErrorMessage,
            CustomMultiselectScoutLaw,
            CustomSelectOneAgeGroup,
            CustomSelectOneDevelopmentArea,
            CustomCheckbox,
            CustomEditor,
            ReviewListComponent,
            PrimeButton,
            Toolbar,
            Toast,
            VeeForm,
            MetadataComponent,
            CustomInputText,
            GoBack,
        },

        props: {
            value: {},
        },

        data() {
            return {
                challenge: {
                    id: null,
                    name: null,
                },
                loaded: false,
            };
        },

        computed: {
            InstrumentType() {
                return InstrumentType;
            },
            creationMode() {
                return !this.$route.params.id;
            },
            ageGroupSelected() {
                return this?.challenge?.ageGroup;
            },
            cubAgeGroupSelected() {
                if (!this.ageGroupSelected) {
                    return undefined;
                }
                return this?.challenge?.ageGroup?.cubsRank === true;
            },
            scoutAgeGroupSelected() {
                if (!this.ageGroupSelected) {
                    return undefined;
                }
                return this?.challenge?.ageGroup?.cubsRank === false;
            },
            selectedAgeGroupType() {
                return this.scoutAgeGroupSelected ? AgeGroupType.SCOUT : AgeGroupType.CUB;
            },
            lawLabel() {
                return this.cubAgeGroupSelected ? "prawo zuchowe" : "prawo harcerskie";
            },
        },

        beforeMount() {
            if (this.$route.params.id) {
                getChallenge({id: this.$route.params.id}).then((response) => {
                    this.challenge = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            hasErrors(errorsObj) {
                if (errorsObj) {
                    return Object.keys(errorsObj)?.length > 0;
                }
                return false;
            },
            validateAndSaveIfValid(validate) {
                validate().then((result) => {
                    if (result.valid !== true) {
                        ScrollUtils.scrollToField(Object.keys(result.errors)[0]);
                    } else {
                        this.saveChallenge();
                    }
                }).catch((error) => {
                    window.console.log(error);
                    ToastUtils.showError(this);
                });
            },
            saveChallenge() {
                createOrUpdateChallenge({challengeDto: this.challenge}).then(() => {
                    ToastUtils.showSuccess(this, "", "Zapisano dane");
                    this.$router.push({name: "challengeList"});
                }).catch(
                    () => { ToastUtils.showError(this); },
                );
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
