<template>
    <div v-if="challenge" class="challenge-single-view">
        <GoBack />
        <Toast/>
        <div class="mt-6">
            <h1 v-if="challenge.cubsRank">Zadanie indywidualne</h1>
            <h1 v-else>Wyzwanie</h1>
        </div>
        <div class="grid">
            <div class="md:col-8 col-12">
                <div class="rank-description m-0" v-html="challenge.content" />
            </div>
        </div>
        <div class="md:my-6">
            <div class="grid flex-column md:flex-row gap-5 md:gap-0 justify-content-between
                        align-items-center md:align-items-start">
                <div class="single-view-img-outer md:col-3 col-6 flex-order-0 md:flex-order-1 mt-4 md:mt-0">
                    <img class="m-auto block" v-if="challenge.coverUrl" :src="challenge.coverUrl" alt="logo"/>
                </div>
                <div class="col-12 md:col-7 flex-order-1 md:flex-order-0">
                    <div class="flex gap-3 mb-6">
                        <div class="font-bold">Obszar rozwoju: </div>
                        <div>{{ challenge.developmentAreaName }}</div>
                    </div>
                    <ScoutLawList :scout-law="challenge.scoutLawList" :cubs-challenge="challenge.cubsRank"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {getPublicChallengeDTOUsingGET as getChallenge} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import Toast from "primevue/toast";
    import GoBack from "@/components/GoBack";
    import ScoutLawList from "@/views/scoutLaw/components/ScoutLawList";

    export default {
        name: "RankSingleView",
        components: {ScoutLawList, Toast, GoBack},

        props: {
        },

        computed: {
        },

        data() {
            return {
                challenge: null,
            };
        },
        beforeMount() {
            getChallenge({id: this.$route.params.instrumentId}).then((response) => {
                this.challenge = response.data;
            }).catch(() => {
                ToastUtils.showError(this);
            });
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less">
    @import "../../assets/theme/variable";
    .challenge-single-view {
        .rank-description {
            color: @color-purple-scout;
            font-weight: 700;
            p {
                margin: 0;
            }
        }
    }
</style>
