<template>
    <div class="list-view">
        <h1 class="page-header"><i class="pi pi-th-large"></i> Lista kategorii</h1>

        <div class="search-criteria" @keyup.enter="search">
            <CategorySearchCriteria ref="categorySearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <div class="flex gap-3 flex-wrap">
                        <PrimeButton label="Szukaj" icon="pi pi-search" @click="search"/>
                        <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
            <template #start>
                <PrimeButton label="Dodaj nową kategorię" icon="pi pi-plus" @click="createCategory"/>
            </template>
        </Toolbar>

        <CategoryTable ref="categoryTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>

    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CategorySearchCriteria from "./components/CategorySearchCriteria";
    import CategoryTable from "./components/CategoryTable";

    export default {
        name: "CategoryListView",
        components: {
            CategoryTable, CategorySearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.categoryTable.search();
            },

            createCategory() {
                this.$router.push({name: "categoryCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    systemRole: null,
                    categoryType: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
