<template>
    <router-view/>
</template>

<script>
    export default {
        name: "LayoutEmpty",
    };
</script>

<style scoped>

</style>
