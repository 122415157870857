<template>
    <div class="grid">
        <div class="sm:col-6 md:col-4 col-12">
            <CustomInputText name="name" label="nazwa" v-model="internalValue.name"/>
        </div>
        <div class="sm:col-6 md:col-4 col-12">
            <CustomSelectOneAgeGroup name="ageGroup" label="metodyka" v-model="internalValue.ageGroupId"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneAgeGroup from "@/components/form/CustomSelectOneAgeGroup";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "TrailBadgeSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText, CustomSelectOneAgeGroup},
    };
</script>

<style lang="less" scoped>

</style>
