<template>
    <div>
        <h2 v-if="!cubsChallenge">{{ $t('message.headers.scoutLaw') }}</h2>
        <h2 v-else>{{ $t('message.headers.cubLaw') }}</h2>
        <div class="list-with-checkbox-outer">
            <ul>
                <li v-for="law in scoutLaw" :key="law.id">
                    <div>
                        <span class="list-with-checkbox-name">{{ law.pointNumber + '. ' + law.rule }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ScoutLawList",
        components: {},

        props: {
            scoutLaw: {
                type: Array,
            },
            cubsChallenge: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
        },

    };
</script>

<style lang="less" scoped>
</style>
