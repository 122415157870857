<template>
    <div>
        <h1 class="page-header"><i class="pi pi-file"></i> Lista plików</h1>

        <div class="search-criteria" @keyup.enter="search">
            <ExternalFileSearchCriteria ref="externalFileSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template #start>
                    <PrimeButton label="Szukaj" icon="pi pi-search" @click="search" class="mr-3"/>
                    <PrimeButton label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions">
        </Toolbar>

        <ExternalFileTable ref="externalFileTable" :search-criteria="searchCriteria" />
    </div>
</template>

<script>
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "../../utils/SearchCriteria";
    import ExternalFileSearchCriteria from "./components/ExternalFileSearchCriteria";
    import ExternalFileTable from "./components/ExternalFileTable";

    export default {
        name: "ExternalFileListView",
        components: {
            ExternalFileTable, ExternalFileSearchCriteria, PrimeButton, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$refs.externalFileTable.search();
            },

            createExternalFile() {
                this.$router.push({name: "externalFileCreate"});
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
            },

            getClearSearchCriteria() {
                return {
                    firstName: "",
                    lastName: "",
                    email: "",
                    systemRole: null,
                    page: {
                        limit: 50,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },
        },
    };
</script>

<style scoped>

</style>
