<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            <i class="pi pi-map"></i>
            {{ this.$route.params.id ? 'Edytuj Trop' : 'Dodaj Trop' }}
        </h1>
        <VeeForm v-if="loaded" v-slot="{ isSubmitting, validate, errors }">
            <div class="p-grid trailBadge-form">
                <div class="col-6">
                    <CustomInputText name="name" label="nazwa" v-model="trailBadge.name" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneAgeGroup name="ageGroup" label="metodyka" return-type="object"
                                             v-model="trailBadge.ageGroup" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomCheckbox name="active" label="aktywny" v-model="trailBadge.active"/>
                </div>
                <div class="col-6">
                    <CustomCheckbox name="duty" label="służba" v-model="trailBadge.duty"/>
                </div>
                <div class="col-6" v-if="trailBadge.ageGroup">
                    <CustomSelectOneCategory v-model="trailBadge.categoryId" name="categoryId"
                                             label="kategoria" :category-type="getCategoryType" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomMultiselectLabel label="etykiety" name="label-multiselect"
                                            v-model="this.trailBadge.labelIds"/>
                </div>
                <div class="col-6">
                    <CustomMultiselectSustainableDevelopmentGoal v-model="trailBadge.sustainableDevelopmentGoalIds"
                                                                 name="sdgs"
                                                                 label="cele zrównoważonego rozwoju"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneFile v-if="cubAgeGroupSelected"
                                         v-model="trailBadge.coverImage" label="grafika" name="file"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-model="trailBadge.introductionText" label="wprowadzenie"
                                  name="introductionText"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-if="scoutAgeGroupSelected" v-model="trailBadge.planText"
                                  label="zaplanujcie" name="planText"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-if="scoutAgeGroupSelected" v-model="trailBadge.toDoText"
                                  label="zróbcie" name="toDoText"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-if="scoutAgeGroupSelected" v-model="trailBadge.summaryText"
                                  label="podsumujcie" name="summaryText"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-if="cubAgeGroupSelected" v-model="trailBadge.cubTasks"
                                  label="zadania" name="cubTasks"/>
                </div>
                <div class="col-12">
                    <CustomEditor v-if="cubAgeGroupSelected" v-model="trailBadge.tipsForSupervisors"
                                  label="wskazówki dla kadry" name="tipsForSupervisors"/>
                </div>
                <div class="col-12">
                    <TrailBadgeBibliographicHintsList v-model="trailBadge.bibliographicHintList"/>
                </div>
                <div class="col-12">
                    <CustomInputText name="sourceUrl" label="link do źródła"
                                     v-model="trailBadge.sourceUrl" rules="url"/>
                </div>
                <div class="col-12">
                    <Toolbar class="actions">
                        <template #end>
                            <FormErrorMessage :has-errors="hasErrors(errors)"/>
                            <PrimeButton label="Zapisz" @click="validateAndSaveIfValid(validate)"
                                         icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
                <div class="col-12">
                    <MetadataComponent :data="trailBadge.metadata"/>
                </div>
                <div v-if="!creationMode" class="col-12">
                    <ReviewListComponent  :initial-reviews="trailBadge.reviews"
                                          :instrument-type="InstrumentType.TRAIL_BADGE"
                                          :instrument-id="trailBadge.id" />
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateTrailBadgeUsingPOST as createOrUpdateTrailBadge,
        getTrailBadgeUsingGET as getTrailBadge,
    } from "@/swagger/vue-api-client";
    import PrimeButton from "primevue/button";
    import GoBack from "@/components/GoBack";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomSelectOneAgeGroup from "@/components/form/CustomSelectOneAgeGroup";
    import CustomSelectOneFile from "@/components/form/CustomSelectOneFile";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomSelectOneCategory from "@/components/form/CustomSelectOneCategory";
    import CustomEditor from "@/components/form/CustomEditor";
    import MetadataComponent from "@/components/data/MetadataComponent";
    import {CategoryType} from "@/utils/CategoryTypes";
    import CustomMultiselectLabel from "@/components/form/CustomMultiselectLabel";
    import TrailBadgeBibliographicHintsList
        from "@/views/trailBadge/components/TrailBadgeBibliographicHintsList";
    import InstrumentType from "@/utils/InstrumentTypes";
    import ReviewListComponent from "@/components/data/ReviewListComponent";
    import CustomMultiselectSustainableDevelopmentGoal
        from "@/components/form/CustomMultiselectSustainableDevelopmentGoal";
    import FormErrorMessage from "@/components/FormErrorMessage";
    import {ScrollUtils} from "@/utils/ScrollUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "TrailBadgeFormView",

        components: {
            FormErrorMessage,
            CustomMultiselectLabel,
            CustomEditor,
            PrimeButton,
            Toolbar,
            CustomInputText,
            ReviewListComponent,
            Toast,
            VeeForm,
            CustomSelectOneAgeGroup,
            CustomSelectOneFile,
            CustomCheckbox,
            CustomSelectOneCategory,
            MetadataComponent,
            TrailBadgeBibliographicHintsList,
            CustomMultiselectSustainableDevelopmentGoal,
            GoBack,
        },

        props: {
            value: {},
        },

        data() {
            return {
                trailBadge: {
                    id: null,
                    name: null,
                    ageGroup: null,
                    ageGroupId: null,
                    active: null,
                    categoryId: null,
                    duty: null,
                    file: null,
                    planText: null,
                    introductionText: null,
                    toDoText: null,
                    summaryText: null,
                    labelIds: null,
                    sustainableDevelopmentGoalId: null,
                    bibliographicHintList: [],
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getTrailBadge({id: this.$route.params.id}).then((response) => {
                    this.trailBadge = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },

        computed: {

            InstrumentType() {
                return InstrumentType;
            },
            cubAgeGroupSelected() {
                return this?.trailBadge?.ageGroup?.cubsRank === true;
            },
            scoutAgeGroupSelected() {
                return this?.trailBadge?.ageGroup?.cubsRank === false;
            },
            getCategoryType() {
                return this.scoutAgeGroupSelected
                    ? CategoryType.SCOUT_TRAIL_BADGE : CategoryType.CUB_TRAIL_BADGE;
            },
            creationMode() {
                return !this.$route.params.id;
            },
        },

        methods: {
            hasErrors(errorsObj) {
                if (errorsObj) {
                    return Object.keys(errorsObj)?.length > 0;
                }
                return false;
            },
            validateAndSaveIfValid(validate) {
                validate().then((result) => {
                    if (result.valid !== true) {
                        ScrollUtils.scrollToField(Object.keys(result.errors)[0]);
                    } else {
                        this.saveTrailBadge();
                    }
                }).catch(() => {
                    ToastUtils.showError(this);
                });
            },
            saveTrailBadge() {
                this.trailBadge.ageGroupId = this.trailBadge.ageGroup.id;
                createOrUpdateTrailBadge({trailBadgeEditDto: this.trailBadge}).then((response) => {
                    if (this.$route.name === "trailBadgeCreate") {
                        this.$router.push({name: "trailBadgeEdit", params: {id: response.data.id}});
                    }
                    ToastUtils.showSuccess(this, "Zapisano dane");
                    this.$router.push({name: "trailBadgeList"});
                }).catch((error) => {
                    window.console.log(error);
                    ToastUtils.showError(this);
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .trailBadge-form {
    }
</style>
