<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath"><i class="pi pi-chart-bar"></i>
            {{ this.$route.params.id ? 'Edytuj poziom zaawansowania' : 'Dodaj poziom zaawansowania' }}
        </h1>
        <VeeForm v-if="loaded" @submit="saveAdvancementLevel" v-slot="{ errors }">
            <div class="advancement-level-form p-grid">
                <CustomInputText class="col-6 advancement-level-input"
                                 name="name" label="nazwa" v-model="advancementLevel.name" rules="required"/>
                <Toolbar class="col-6 actions">
                    <template #end>
                        <PrimeButton label="Zapisz" type="submit" icon="pi pi-check" :disabled="isDisabled(errors)"/>
                    </template>
                </Toolbar>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createAdvancementLevelUsingPOST as createAdvancementLevel,
        editAdvancementLevelUsingPUT as editAdvancementLevel,
        getAdvancementLevelUsingGET as getAdvancementLevel,
    } from "@/swagger/vue-api-client";
    import GoBack from "@/components/GoBack";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import Toast from "primevue/toast";
    import {Form as VeeForm} from "vee-validate";
    import {StatusCodes} from "@/utils/StatusCodes";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "AdvancementLevelFormView",

        components: {
            PrimeButton, Toolbar, CustomInputText, Toast, VeeForm, GoBack,
        },
        data() {
            return {
                advancementLevel: {
                    id: null,
                    name: null,
                },
                loaded: false,
            };
        },
        updated() {
            this.advancementLevel.id = this.$route.params.id;
        },
        beforeMount() {
            if (this.$route.params.id) {
                getAdvancementLevel({id: this.$route.params.id}).then((response) => {
                    this.advancementLevel = response.data;
                    this.loaded = true;
                });
            } else {
                this.loaded = true;
            }
        },
        methods: {
            async saveAdvancementLevel() {
                if (this.$route.params.id) {
                    try {
                        await editAdvancementLevel({advancementLevelRequest: this.advancementLevel});
                        this.notifySuccess();
                        this.$router.push({name: "advancementLevelList"});
                    } catch (error) {
                        this.notifyError(error);
                    }
                } else {
                    try {
                        await createAdvancementLevel({advancementLevelRequest: this.advancementLevel});
                        this.notifySuccess();
                        this.$router.push({name: "advancementLevelList"});
                    } catch (error) {
                        this.notifyError(error);
                    }
                }
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            notifyError(error) {
                switch (error.response.status) {
                    case StatusCodes.CONFLICT:
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Poziom zaawansowania o takiej nazwie już istnieje",
                            life: 3000,
                        });
                        break;
                    default:
                        this.$toast.add({
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać danych",
                            life: 3000,
                        });
                }
            },
            notifySuccess() {
                this.$toast.add({
                    severity: "success",
                    summary: "Sukces",
                    detail: "Poziom zaawansowania został zapisany",
                    life: 3000,
                });
            },
        },
    };
</script>

<style lang="less" scoped>
.advancement-level-form {
    width: 80%;
    max-width: 800px;
}
.advancement-level-input {
    padding-right: 2px;
    padding-left: 0;
}
</style>
