<template>
    <div>
        <h2>{{ $t('message.headers.tasks') }}</h2>
        <div class="list-with-checkbox-outer">
            <ul>
                <li v-for="task in tasks" :key="task.id">
                    <div>
                        <span class="list-with-checkbox-name">{{ task.name }}</span>

                        <PrimeButton v-if="task.meritBadgeId && task.meritBadgeMaleName && $route.params.sex === 'male'"
                                     :label="task.meritBadgeMaleName"
                                     @click="goToMeritBadgeView(task.meritBadgeId, task.meritBadgeTaskLevel)"
                                     class="p-button-rounded small-button mr-2"/>
                        <PrimeButton v-if="task.meritBadgeId && task.meritBadgeFemaleName &&
                                         $route.params.sex === 'female'"
                                     :label="task.meritBadgeFemaleName"
                                     @click="goToMeritBadgeView(task.meritBadgeId, task.meritBadgeTaskLevel)"
                                     class="p-button-rounded small-button"/>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    // import axios from "axios";
    import PrimeButton from "primevue/button";

    export default {
        name: "RankSingleTasks",
        components: {PrimeButton},

        props: {
            tasks: {
                type: Array,
            },
        },

        computed: {
        },

        data() {
            return {
            };
        },
        beforeMount() {
        },

        mounted() {
        },

        methods: {
            goToMeritBadgeView(meritBadgeId, meritBadgeTaskLevel) {
                this.$router.push(
                    {name: "meritBadge", params: {instrumentId: meritBadgeId, level: meritBadgeTaskLevel}},
                );
            },
        },

    };
</script>

<style lang="less" scoped>
</style>
