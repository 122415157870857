import {createRouter, createWebHistory} from "vue-router";
import CategoryFormView from "@/views/category/CategoryFormView";
import CategoryListView from "@/views/category/CategoryListView";
import LabelListView from "@/views/category/LabelListView";
import LabelFormView from "@/views/category/LabelFormView";
import AdvancementLevelListView from "@/views/advancementLevel/AdvancementLevelListView";
import advancementLevelFormView from "@/views/advancementLevel/AdvancementLevelFormView";
import DevelopmentAreaListView from "@/views/developmentArea/DevelopmentAreaListView";
import DevelopmentAreaFormView from "@/views/developmentArea/DevelopmentAreaFormView";
import ExternalFileListView from "@/views/externalFile/ExternalFileListView";
import RankListView from "@/views/rank/RankListView";
import ChallengeListView from "@/views/challenge/ChallengeListView";
import ChallengeSingleView from "@/views/challenge/ChallengeSingleView";
import RankSingleView from "@/views/rank/RankSingleView";
import LayoutEmpty from "@/views/layout/LayoutEmpty";
import RankFormView from "@/views/rank/RankFormView";
import RankGroupsView from "@/views/rank/RankGroupsView";
import challengeFormView from "@/views/challenge/ChallengeFormView";
import ChallengePublicListView from "@/views/challenge/ChallengePublicListView";
import MeritBadgePublicListView from "@/views/merit-badge/MeritBadgePublicListView";
import MeritBadgePublicSingleView from "@/views/merit-badge/MeritBadgePublicSingleView";
import SpecialtyFormView from "@/views/specialty/SpecialtyFormView";
import SpecialtyListView from "@/views/specialty/SpecialtyListView";
import MeritBadgeListView from "@/views/meritBadge/MeritBadgeListView";
import MeritBadgeFormView from "@/views/meritBadge/MeritBadgeFormView";
import ScoutLaw from "@/views/scoutLaw/ScoutLaw";
import ScoutLawFormView from "@/views/scoutLaw/ScoutLawFormView";
import SustainableDevelopmentGoalFormView
    from "@/views/sustainableDevelopmentGoal/SustainableDevelopmentGoalFormView";
import SustainableDevelopmentGoalListView
    from "@/views/sustainableDevelopmentGoal/SustainableDevelopmentGoalListView";
import TrailBadgeFormView from "@/views/trailBadge/TrailBadgeFormView";
import TrailBadgeListView from "@/views/trailBadge/TrailBadgeListView";
import ConfigFormView from "@/views/config/ConfigFormView";
import homeView from "@/views/home/homeView";
import TrailBadgePublicSingleView from "@/views/trailBadge/TrailBadgePublicSingleView";
import TrailBadgePublicListView from "@/views/trailBadge/TrailBadgePublicListView";
import LayoutReport from "@/views/layout/LayoutReport";
import ScoutMeritBadgeCardView from "@/views/report/ScoutMeritBadgeCardView";
import CubScoutMeritBadgeCardView from "@/views/report/CubScoutMeritBadgeCardView";
import TrailBadgeCardView from "@/views/report/TrailBadgeCardView";
import LayoutDashboard from "./views/layout/LayoutDashboard";
import UserListView from "./views/user/UserListView";
import UserFormView from "./views/user/UserFormView";
import {SystemRole} from "./utils/SystemRole";
import LayoutSimple from "./views/layout/LayoutSimple";
import ForbiddenView from "./views/ForbiddenView";
import NotFoundView from "./views/NotFoundView";
import ErrorView from "./views/ErrorView";
import PasswordRemindView from "./views/PasswordRemindView";
import ChangePasswordView from "./views/ChangePasswordView";

const routes = [
        {
            path: "/dashboard",
            name: "home",
            component: LayoutDashboard,
            meta: {requiresAuth: true},
            children: [
                {
                    path: "user",
                    component: UserListView,
                    children: [
                        {
                            path: "",
                            name: "userList",
                            component: UserListView,
                        },
                        {
                            path: "create",
                            name: "userCreate",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                        {
                            path: "edit/:id",
                            name: "userEdit",
                            component: UserFormView,
                            meta: {roles: [SystemRole.ADMIN]},
                        },
                    ],
                },
                {
                    path: "files/list",
                    meta: {
                        requiresAuth: true,
                        roles: [SystemRole.ADMIN],
                        title: "<i class='pi pi-file'></i>Lista plików",
                    },
                    component: ExternalFileListView,
                    name: "filesList",

                },
                {
                    path: "config",
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                    component: ConfigFormView,
                    name: "configForm",
                },
                {
                    path: "instrument",
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.MODERATOR]},
                    component: LayoutEmpty,
                    children: [
                        {
                            path: "rank",
                            component: LayoutEmpty,
                            children: [
                                {
                                    path: "list",
                                    name: "rankList",
                                    meta: {title: "<i class='pi pi-bookmark-fill'></i>Lista stopni"},
                                    component: RankListView,
                                },
                                {
                                    path: "create",
                                    name: "rankCreate",
                                    component: RankFormView,
                                    meta: {viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "rankEdit",
                                    component: RankFormView,
                                    meta: {viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "trail-badge",
                            component: LayoutEmpty,
                            meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.MODERATOR]},
                            children: [
                                {
                                    path: "list",
                                    name: "trailBadgeList",
                                    component: TrailBadgeListView,
                                    meta: {title: "<i class='pi pi-map'></i>Lista tropów"},
                                },
                                {
                                    path: "create",
                                    name: "trailBadgeCreate",
                                    component: TrailBadgeFormView,
                                    meta: {viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "trailBadgeEdit",
                                    component: TrailBadgeFormView,
                                    meta: {viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "merit-badge",
                            component: LayoutEmpty,
                            meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.MODERATOR]},
                            children: [
                                {
                                    path: "list",
                                    name: "meritBadgeList",
                                    meta: {title: "<i class='pi pi-shield'></i>Lista sprawności"},
                                    component: MeritBadgeListView,
                                },
                                {
                                    path: "create",
                                    name: "meritBadgeCreate",
                                    component: MeritBadgeFormView,
                                    meta: {viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "meritBadgeEdit",
                                    component: MeritBadgeFormView,
                                    meta: {viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "challenge",
                            component: LayoutEmpty,
                            meta: {requiresAuth: true, roles: [SystemRole.ADMIN, SystemRole.MODERATOR]},
                            children: [
                                {
                                    path: "list",
                                    name: "challengeList",
                                    meta: {title: "<i class='pi pi-verified'></i>Wyzwania"},
                                    component: ChallengeListView,
                                },
                                {
                                    path: "create",
                                    name: "challengeCreate",
                                    component: challengeFormView,
                                    meta: {viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "challengeEdit",
                                    component: challengeFormView,
                                    meta: {viewType: "single"},
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "dictionary",
                    component: LayoutEmpty,
                    meta: {requiresAuth: true, roles: [SystemRole.ADMIN]},
                    children: [
                        {
                            path: "category",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [
                                {
                                    path: "",
                                    name: "categoryList",
                                    component: CategoryListView,
                                    meta: {
                                        roles: [SystemRole.ADMIN],
                                        title: "<i class='pi pi-th-large'></i>Lista kategorii",
                                    },
                                },
                                {
                                    path: "create",
                                    name: "categoryCreate",
                                    component: CategoryFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "categoryEdit",
                                    component: CategoryFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "label",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [
                                {
                                    path: "",
                                    name: "labelList",
                                    component: LabelListView,
                                    meta: {roles: [SystemRole.ADMIN], title: "<i class='pi pi-tag'></i>Lista etykiet"},
                                },
                                {
                                    path: "create",
                                    name: "labelCreate",
                                    component: LabelFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "labelEdit",
                                    component: LabelFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "advancement-level",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [
                                {
                                    path: "",
                                    name: "advancementLevelList",
                                    component: AdvancementLevelListView,
                                    meta: {
                                        roles: [SystemRole.ADMIN],
                                        title: "<i class='pi pi-chart-bar'></i>Lista poziomów zaawansowania",
                                    },
                                },
                                {
                                    path: "create",
                                    name: "advancementLevelCreate",
                                    component: advancementLevelFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "advancementLevelEdit",
                                    component: advancementLevelFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "development-area",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [{
                                path: "",
                                name: "developmentAreaList",
                                component: DevelopmentAreaListView,
                                meta: {
                                    roles: [SystemRole.ADMIN],
                                    title: "<i class='pi pi-chart-pie'></i>Lista obszarów rozwoju",
                                },
                            },
                                {
                                    path: "create",
                                    name: "developmentAreaCreate",
                                    component: DevelopmentAreaFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "developmentAreaEdit",
                                    component: DevelopmentAreaFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "specialty",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [{
                                path: "",
                                name: "specialtyList",
                                component: SpecialtyListView,
                                meta: {
                                    roles: [SystemRole.ADMIN],
                                    title: "<i class='pi pi-star'></i>Lista specjalności",
                                },
                            },
                                {
                                    path: "create",
                                    name: "specialtyCreate",
                                    component: SpecialtyFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "specialtyEdit",
                                    component: SpecialtyFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                        {
                            path: "scout-law",
                            component: ScoutLaw,
                            meta: {
                                roles: [SystemRole.ADMIN],
                                title: "<i class='pi pi-table'></i>Prawo harcerskie",
                            },
                            name: "scoutLaw",
                        },
                        {
                            path: "scout-law/add",
                            name: "scoutLawAdd",
                            component: ScoutLawFormView,
                            meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                        },
                        {
                            path: "sustainable-development-goal",
                            component: LayoutEmpty,
                            meta: {roles: [SystemRole.ADMIN]},
                            children: [{
                                path: "",
                                name: "sustainableDevelopmentGoalList",
                                component: SustainableDevelopmentGoalListView,
                                meta: {roles: [SystemRole.ADMIN], title: "<i class='pi pi-check-square'></i>Lista CZR"},
                            },
                                {
                                    path: "create",
                                    name: "sustainableDevelopmentGoalCreate",
                                    component: SustainableDevelopmentGoalFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                                {
                                    path: "edit/:id",
                                    name: "sustainableDevelopmentGoalEdit",
                                    component: SustainableDevelopmentGoalFormView,
                                    meta: {roles: [SystemRole.ADMIN], viewType: "single"},
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: "/",
            component: LayoutDashboard,
            children: [
                {
                    path: "",
                    name: "homeView",
                    component: homeView,
                },
                {
                    path: "rank/list/:groupName",
                    name: "rankListView",
                    component: RankGroupsView,
                    meta: {title: "Stopnie"},
                },
                {
                    path: "rank/:id/:sex",
                    name: "rankSingleView",
                    component: RankSingleView,
                    meta: {viewType: "single"},
                },
                {
                    path: "challenge/list/:groupName",
                    name: "challengePublicListView",
                    component: ChallengePublicListView,
                    meta: {title: "Wyzwania"},
                },
                {
                    path: "challenge/:instrumentId",
                    name: "challenge",
                    component: ChallengeSingleView,
                    meta: {viewType: "single"},
                },
                {
                    path: "merit-badge/list/:groupName",
                    name: "meritBadgePublicListView",
                    component: MeritBadgePublicListView,
                    meta: {title: "Sprawności"},
                },
                {
                    path: "merit-badge/:instrumentId",
                    name: "meritBadge",
                    component: MeritBadgePublicSingleView,
                    meta: {viewType: "single"},
                },
                {
                    path: "trail-badge/list/:groupName",
                    name: "trailBadgePublicListView",
                    component: TrailBadgePublicListView,
                    meta: {title: "Tropy"},
                },
                {
                    path: "trail-badge/:instrumentId",
                    name: "trailBadge",
                    component: TrailBadgePublicSingleView,
                    meta: {viewType: "single"},
                },
            ],
        },
        {
            path: "/print/WoHXbephpmfuAs6V1NPODeCi4TBTnWa01DRJinq9",
            component: LayoutReport,
            children: [
                {
                    path: "merit-badge/scout/:id/level/:level",
                    name: "MeritBadgeScoutCard",
                    component: ScoutMeritBadgeCardView,
                },
                {
                    path: "merit-badge/cub-scout/:id/level/:level",
                    name: "MeritBadgeCubScoutCard",
                    component: CubScoutMeritBadgeCardView,
                },
                {
                    path: "trail-badge/:id",
                    name: "trailBadgeCard",
                    component: TrailBadgeCardView,
                },
            ],
        },
        {
            name: "layoutSimple",
            component: LayoutSimple,
            path: "/",
            children: [
                {
                    path: "/remind",
                    name: "remindPassword",
                    component: PasswordRemindView,
                },
                {
                    path: "/change-password",
                    name: "changePassword",
                    component: ChangePasswordView,
                },
            ],
        },
        {
            path: "/forbidden",
            name: "forbidden",
            component: ForbiddenView,
        },
        {
            path: "/error",
            name: "error",
            component: ErrorView,
        },
        {
            path: "/:pathMatch(.*)*",
            name: "notFound",
            component: NotFoundView,
        },
    ];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0};
    },
});

export default router;
