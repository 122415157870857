<template>
    <div>
        <div class="flex align-items-end grid">
            <div class="sm:col-6 md:col-4 col-12">
                <CustomInputText name="firstName" label="nazwa" v-model="internalValue.phrase"/>
            </div>
        </div>
        <LabelsList v-model="internalValue.labelIds" class="mb-4" />
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText";
    import LabelsList from "@/components/Labels/LabelsList";
    import BadgeType from "@/utils/BadgeTypes";

    export default {
        name: "homeSearchCriteria",
        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            BadgeType() {
                return BadgeType;
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomInputText,
            LabelsList,
        },
    };
</script>

<style scoped>

</style>
