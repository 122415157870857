<template>
    <div>
        <div class="grid">
            <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                <CustomInputText name="firstName" label="nazwa" v-model="internalValue.phrase"/>
            </div>
            <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                <CustomSelectOneCategory v-model="internalValue.categoryId" name="categoryId"
                                         label="kategoria" :category-type="getCategoryType" />
            </div>
            <template v-if="!isCubsList">
                <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                    <CustomSelectOneSpecialty v-model="internalValue.specialtyId" name="specialtyId"
                                              label="specjalność"/>
                </div>
                <div class="sm:col-6 md:col-4 xl:col-3 col-12">
                    <CustomMultiselectLevel v-model="internalValue.levelIds" name="levelId"
                                            label="poziom zaawansowania"/>
                </div>
            </template>
        </div>
        <LabelsList v-model="internalValue.labelIds" class="mb-4" :badge-type="BadgeType.MERIT_BADGE"/>
    </div>
</template>

<script>
    import CustomSelectOneSpecialty from "@/components/form/CustomSelectOneSpecialty";
    import CustomSelectOneCategory from "@/components/form/CustomSelectOneCategory";
    import CustomMultiselectLevel from "@/components/form/CustomMultiselectLevel";
    import {CategoryType} from "@/utils/CategoryTypes";
    import LabelsList from "@/components/Labels/LabelsList";
    import BadgeType from "@/utils/BadgeTypes";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "MeritBadgePublicSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            BadgeType() {
                return BadgeType;
            },
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
            isCubsList() {
                if (this.$route.params.groupName !== "cub-scouts") {
                    return false;
                }
                return true;
            },
            getCategoryType() {
                return this.$route.params.groupName !== "cub-scouts"
                    ? CategoryType.SCOUT_MERIT_BADGE : CategoryType.CUB_MERIT_BADGE;
            },
        },

        components: {
            CustomInputText,
            CustomSelectOneCategory,
            CustomSelectOneSpecialty,
            CustomMultiselectLevel,
            LabelsList,
        },

        methods: {
        },
    };
</script>

<style scoped>

</style>
