<template>
    <div>
        <Toast />
        <GoBack />
        <h1 class="page-header mt-5" :key="$route.fullPath">
            <i class="pi pi-bookmark-fill"></i>
            {{ this.$route.params.id ? 'Edytuj stopień' : 'Dodaj stopień' }}
        </h1>
        <VeeForm v-if="loaded" v-slot="{ isSubmitting, validate, errors }">
            <div class="p-grid">
                <div class="col-6">
                    <CustomSelectOneAgeGroup name="ageGroup" label="metodyka"
                                             v-model="rank.ageGroupId" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomSelectOneFile name="coverImage" label="grafika" v-model="rank.coverImage" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomCheckbox name="active" label="aktywny" v-model="rank.active"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="name" label="nazwa w wersji męskiej" v-model="rank.name" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="femaleName" label="nazwa w wersji żeńskiej"
                                     v-model="rank.femaleName" rules="required"/>
                </div>
                <div class="col-6">
                    <CustomInputNumber name="rankNo" label="poziom stopnia"
                                       v-model="rank.rankNo" rules="required|min_value:1"/>
                </div>
                <div class="col-12">
                    <CustomEditor name="description" label="opis w wersji męskiej"
                                  v-model="rank.description" rules="required" />
                </div>
                <div class="col-12">
                    <CustomEditor name="femaleDescription" label="opis w wersji żeńskiej"
                                  v-model="rank.femaleDescription" />
                </div>
                <div class="col-6">
                    <CustomInputText name="age" label="wiek" v-model="rank.age"
                                     placeholder="np. 12-13 lat"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="numberOfRequiredMeritBadges" label="liczba wymaganych sprawności"
                                     v-model="rank.numberOfRequiredMeritBadges"
                                     placeholder="np. 4 dwugwiazdkowe"/>
                </div>
                <div class="col-6" v-if="!rank.cubsRank">
                    <CustomInputText name="numberOfRequiredMeritBadges" label="czas trwania próby"
                                     v-model="rank.attemptDuration" placeholder="np. 6-12 miesięcy"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="numberOfActivityBadges" label="liczba tropów"
                                     v-model="rank.numberOfTrailBadges" placeholder="np. 2"/>
                </div>
                <div class="col-6">
                    <CustomInputText name="numberOfActivityBadges" label="liczba sprawności tworząca stopień"
                                     v-model="rank.numberOfChallenges" placeholder="np. 1-3"/>
                </div>
                <div class="col-12">
                    <CustomEditor name="textForPupils" label="tekst dla podopiecznych"
                                  v-model="rank.textForPupils" />
                </div>
                <div class="col-12">
                    <CustomEditor name="textForSupervisor" label="tekst dla wychowawców"
                                  v-model="rank.textForSupervisor" />
                </div>
                <div class="col-12">
                    <RankTaskEditList v-model="rank.tasks"/>
                </div>
                <div class="col-12">
                    <h4 class="header mt-0 mb-3">Źródło</h4>
                    <CustomInputText name="sourceUrl" label="link do źródła"
                                     v-model="rank.sourceUrl" rules="url"/>
                </div>
                <div class="col-12">
                    <div class="save-button-outer pt-6 mt-3">
                        <Toolbar class="actions">
                            <template #end>
                                <FormErrorMessage :has-errors="hasErrors(errors)"/>
                                <PrimeButton label="Zapisz" @click="validateAndSaveIfValid(validate)"
                                             icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
                <div class="col-12">
                    <RankPrintFiles v-if="filesLoaded" @files-changed="onFilesChange"
                                    :files="files"
                                    :rank-id="rank.id"/>
                </div>
                <div v-if="!creationMode" class="col-12">
                    <ReviewListComponent :initial-reviews="rank.reviews"
                                         :instrument-type="InstrumentType.RANK"
                                         :instrument-id="rank.id" />
                </div>
                <div class="col-12">
                    <MetadataComponent :data="rank.metadata"/>
                </div>
            </div>
        </VeeForm>
    </div>
</template>

<script>
    import {
        createOrUpdateRankUsingPOST as createOrUpdateRank,
        getRankPrintFileListByRankIdUsingGET as getFiles,
        getRankUsingGET as getRank,
    } from "@/swagger/vue-api-client";
    import Toast from "primevue/toast";
    import GoBack from "@/components/GoBack";
    import PrimeButton from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form as VeeForm} from "vee-validate";
    import MetadataComponent from "@/components/data/MetadataComponent";
    import CustomEditor from "@/components/form/CustomEditor";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomSelectOneFile from "@/components/form/CustomSelectOneFile";
    import CustomSelectOneAgeGroup from "@/components/form/CustomSelectOneAgeGroup";
    import RankTaskEditList from "@/views/rank/components/RankTaskEditList";
    import InstrumentType from "@/utils/InstrumentTypes";
    import ReviewListComponent from "@/components/data/ReviewListComponent";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import RankPrintFiles from "@/views/rank/RankPrintFiles";
    import {ToastUtils} from "@/utils/ToastUtils";
    import FormErrorMessage from "@/components/FormErrorMessage";
    import {ScrollUtils} from "@/utils/ScrollUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "RankFormView",

        components: {
            FormErrorMessage,
            CustomInputNumber,
            RankTaskEditList,
            MetadataComponent,
            CustomSelectOneAgeGroup,
            CustomSelectOneFile,
            CustomCheckbox,
            CustomEditor,
            PrimeButton,
            Toolbar,
            CustomInputText,
            VeeForm,
            ReviewListComponent,
            RankPrintFiles,
            GoBack,
            Toast,
        },

        props: {
            value: {},
        },

        data() {
            return {
                rank: {
                    id: null,
                    name: null,
                    tasks: [],
                },
                files: null,
                loaded: false,
                filesLoaded: false,
                fileDialogVisible: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                this.getData();
                this.getFiles();
            } else {
                this.loaded = true;
            }
        },

        computed: {
            InstrumentType() {
                return InstrumentType;
            },
            creationMode() {
                return !this.$route.params.id;
            },
        },

        methods: {
            hasErrors(errorsObj) {
                if (errorsObj) {
                    return Object.keys(errorsObj)?.length > 0;
                }
                return false;
            },
            validateAndSaveIfValid(validate) {
                validate().then((result) => {
                    if (result.valid !== true) {
                        ScrollUtils.scrollToField(Object.keys(result.errors)[0]);
                    } else {
                        this.saveRank();
                    }
                }).catch(() => {
                    ToastUtils.showError(this);
                });
            },
            saveRank() {
                createOrUpdateRank({rankEditDto: this.rank}).then((response) => {
                    this.rank = response.data;
                    ToastUtils.showSuccess(this, "Zapisano dane");
                    this.$router.push({name: "rankList"});
                }).catch(() => {
                    ToastUtils.showError(this, "Nie udało się zapisać danych");
                });
            },
            getData() {
                getRank({id: this.$route.params.id}).then((response) => {
                    this.rank = response.data;
                    this.loaded = true;
                });
            },
            getFiles() {
                getFiles({rankId: this.$route.params.id}).then((response) => {
                    this.files = response.data;
                    this.filesLoaded = true;
                });
            },
            onFilesChange() {
                this.getFiles();
            },
        },
    };
</script>

<style lang="less" scoped>
@import "@/assets/theme/variable.less";
    .rank-form {
        width: 80%;
        max-width: 800px;
    }
    h4 {
        color: @color-black-trans-70
    }
    .save-button-outer {
        border-top: 1px solid #eee;
    }
</style>
